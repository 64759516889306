import React from 'react';
import { useSelector } from 'react-redux';
import config from 'config';

import { neptuneUrls } from '@neptune/shared/common-domain';
import { EmptyView } from '@neptune/shared/common-ui';
import { IngestUsageIntegrationExample } from '@neptune/onboarding-ui';
import { useApiToken } from 'common/hooks/useApiToken';
import { getCurrentProjectIdentifier } from '@neptune/current-project-business-logic';
import { integrations } from '@neptune/onboarding-domain';
import emptyStateIcon from './assets/empty_state-runs.svg';
import { SelectIntegrationExample } from './SelectIntegrationExample';
import {
  getOrganizationNameFromRouteParams,
  getProjectNameFromRouteParams,
} from 'state/selectors-global';

export const NoResultsWithIntegrations: React.FC = React.memo(function NoResultsWithIntegrations() {
  const [integration, setIntegration] = React.useState(integrations[0]);
  const projectIdentifier = useSelector(getCurrentProjectIdentifier);
  const organizationName = useSelector(getOrganizationNameFromRouteParams);
  const projectName = useSelector(getProjectNameFromRouteParams);
  const { token: apiToken } = useApiToken();

  const title = 'Create a first model training run and log some metadata to it!';
  const subtitle = 'Runs are where you log metadata like metrics, parameters, and charts.';

  if (config.withIngestApi) {
    return (
      <EmptyView
        variant="legacy"
        icon={<img src={emptyStateIcon} alt="" />}
        title={title}
        subtitle={subtitle}
        example={
          <IngestUsageIntegrationExample
            organizationName={organizationName}
            projectName={projectName}
          />
        }
        data-role="empty-leaderboard"
        twoColumnsWidth={600}
        buttons={[
          {
            caption: 'Read the docs',
            href: neptuneUrls.ingestUsageQuickStartDocs,
            external: true,
          },
        ]}
      />
    );
  }

  const example = (
    <SelectIntegrationExample
      apiToken={apiToken}
      projectIdentifier={projectIdentifier}
      integration={integration}
      handleChange={setIntegration}
    />
  );

  return (
    <EmptyView
      variant="legacy"
      icon={<img src={emptyStateIcon} alt="" />}
      title={title}
      subtitle={subtitle}
      example={example}
      data-role="empty-leaderboard"
      buttons={[
        {
          caption: 'Get started with Hello Neptune',
          href: neptuneUrls.quickStartDoc,
          external: true,
        },
        {
          caption: 'Integrate with your code',
          href: neptuneUrls.integrationPythonDoc,
          external: true,
          variant: 'secondary',
        },
      ]}
    />
  );
});

import { createSelector } from 'reselect';

import { AppState } from 'state/types';
import { hexToRgba } from 'common/color';

import getParentState from '../selectors';
import { ColorMapState } from './types';

export function getColorMapState(state: AppState): ColorMapState {
  return getParentState(state).colorMap;
}

export const getRgbColorMap: (state: AppState) => Record<string, string> = createSelector(
  getColorMapState,
  (colorMap) => {
    return Object.keys(colorMap).reduce((acc: Record<string, string>, curr) => {
      const value = hexToRgba(colorMap[curr]);

      if (value !== undefined) {
        acc[curr] = value;
      }

      return acc;
    }, {});
  },
);

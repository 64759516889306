import React from 'react';
import { isString } from 'lodash';
import config from 'config';
import { bemBlock, ColorPicker, Layout, Text } from '@neptune/shared/venus-ui';
import { getEmojiBasedOnTime } from '@neptune/shared/avatars-util';
import { AvatarSource } from '@neptune/shared/core-users-domain';
import ProjectKeyGenerator from 'components/projects/project-key-generator/ProjectKeyGenerator';
import { CopyToClipboard, InputWithError, Textarea } from '@neptune/shared/common-ui';
import { makeProjectIdentifier } from 'common/project';
import { OrganizationType, PrivacyAvailability } from '@neptune/shared/core-organizations-domain';
import { updateInputPreservingCaretPosition } from '@neptune/shared/common-util';
import { ProjectBeingEdited } from 'state/ui/modals/project/actions';
import { ProjectVisibilitySection } from './ProjectVisibilitySection';
import { AvatarPicker } from './avatar-picker/AvatarPicker';
import { ProjectNameNotice } from './ProjectNameNotice';
import withNameChecked from './withNameChecked';
import { ProjectNameChangeWarning } from './ProjectNameChangeWarning';
import './ProjectForm.less';

const ProjectNameInput = withNameChecked(InputWithError);

export interface ProjectFormProps {
  mode: 'add' | 'edit';
  project: ProjectBeingEdited;
  organizationName?: string;
  organizationId?: string;
  organizationType: OrganizationType;
  availableProjectPrivacySettings: PrivacyAvailability[];
  projectNameError?: string;
  initialProjectName?: string;
  onProjectChange: (projectProperty: string, value: string) => void;
  onColorChange: (colorClassName: string) => void;
  onAvatarChange: (avatarUrl: string) => void;
  onProjectKeyChange: (key: string) => void;
  onNameValidationChange: (value: { error: string }) => void;
  onProjectKeyValidationChange: (value: { isProjectKeyValid: boolean }) => void;
}

const block = bemBlock('project-form');

export const ProjectForm: React.FC<ProjectFormProps> = ({
  mode,
  project,
  organizationName,
  organizationId,
  organizationType,
  availableProjectPrivacySettings,
  projectNameError,
  onProjectChange,
  onColorChange,
  onAvatarChange,
  onProjectKeyChange,
  onNameValidationChange,
  onProjectKeyValidationChange,
  initialProjectName,
}) => {
  const projectAvatar =
    project.avatarSource === AvatarSource.unicode
      ? project.avatarUrl
      : getEmojiBasedOnTime(project.timeOfCreation);

  const isNameNoticeVisible = isString(project?.name) && project.name.length > 0;

  const projectIdentifier = makeProjectIdentifier(
    organizationName || 'undefined',
    project.name || 'undefined',
  );

  const isProjectNameDirty = !!(
    initialProjectName &&
    project.name &&
    initialProjectName !== project.name &&
    !projectNameError
  );

  const handleProjectChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      onProjectChange(event.target.name, event.target.value),
    [onProjectChange],
  );
  const handleProjectNameChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      updateInputPreservingCaretPosition(event.target, (value: string) =>
        value.replace(/\s|_/g, '-'),
      );
      handleProjectChange(event);
    },
    [handleProjectChange],
  );

  return (
    <Layout.Column className={block()} spacedChildren="md" data-role="project-form">
      <Layout.Column spacedChildren="sm">
        <Layout.Column spacedChildren="xs">
          <Text fontWeight="semibold">Name</Text>
          <ProjectNameInput
            organizationName={organizationName}
            projectId={project.id}
            data-role="project-form-name"
            name="name"
            type="text"
            maxLength={50}
            error={projectNameError}
            value={project.name || ''}
            onValidationChange={onNameValidationChange}
            onChange={handleProjectNameChange}
            inputClassName={block('input')}
          />
          {mode === 'edit' && <ProjectNameChangeWarning isVisible={isProjectNameDirty} />}
        </Layout.Column>
        {mode === 'edit' && (
          <Layout.Row span="auto" alignItems="center" spacedChildren="sm">
            <Text fontWeight="semibold">Project</Text>
            <Text> = </Text>
            <Text> {projectIdentifier} </Text>
            <CopyToClipboard text={projectIdentifier} />
          </Layout.Row>
        )}
        {!config.withIngestApi && (
          <ProjectNameNotice
            isVisible={isNameNoticeVisible}
            projectIdentifier={projectIdentifier}
          />
        )}
      </Layout.Column>

      <Layout.Column spacedChildren="xs">
        <Text fontWeight="semibold">Key</Text>
        <ProjectKeyGenerator
          projectKey={project.projectKey}
          projectName={project.name}
          organizationId={organizationId}
          mode={mode}
          handleProjectKeyChange={onProjectKeyChange}
          onValidationChange={onProjectKeyValidationChange}
          inputClassName={block('input')}
        />
      </Layout.Column>
      <Layout.Column spacedChildren="xs">
        <Layout.Row spacedChildren="md">
          <Layout.Column span="shrink" spacedChildren="xs">
            <Text fontWeight="semibold">Color</Text>
            <Layout.Row span="grow">
              <ColorPicker activeColor={project.displayClass} onColorChange={onColorChange} />
            </Layout.Row>
          </Layout.Column>
          <Layout.Column span="shrink" spacedChildren="xs">
            <Text fontWeight="semibold">Avatar</Text>
            <Layout.Row span="grow">
              <AvatarPicker avatar={projectAvatar} onAvatarChange={onAvatarChange} />
            </Layout.Row>
          </Layout.Column>
        </Layout.Row>
      </Layout.Column>
      <Layout.Column spacedChildren="xs">
        <Text fontWeight="semibold">Privacy</Text>
        <ProjectVisibilitySection
          visibility={project.visibility}
          organizationType={organizationType}
          availableProjectPrivacySettings={availableProjectPrivacySettings}
          onPrivacyChange={handleProjectChange}
        />
      </Layout.Column>
      <Layout.Column spacedChildren="xs">
        <Text fontWeight="semibold">Description</Text>
        <Textarea
          className={block('textarea')}
          name="description"
          value={project.description}
          onChange={handleProjectChange}
        />
      </Layout.Column>
    </Layout.Column>
  );
};

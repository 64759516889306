import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LayoutType } from '@neptune/shared/common-domain';
import { AppState } from 'state/types';

import { setExpandableLayout } from './redux/actions';
import { getExpandableLayout } from './redux/selectors';

type UseExpandableLayoutReturn = [LayoutType, (isExpandedLayout: boolean) => void];

export const useExpandableLayout = (id: string): UseExpandableLayoutReturn => {
  const dispatch = useDispatch();
  const layout = useSelector(
    (state: AppState) => getExpandableLayout(state, id) ?? LayoutType.Mosaic,
  );

  const setLayout = React.useCallback(
    (expandedLayout: boolean) => {
      dispatch(setExpandableLayout(id, expandedLayout ? LayoutType.Expanded : LayoutType.Mosaic));
    },
    [dispatch, id],
  );

  return [layout, setLayout];
};

import { AnyAction } from 'redux';

import { TextWrapping } from '@neptune/shared/common-domain';
import { NThunkAction } from 'state/types';
// todo this dependency should be changed in the future to depend on currentProject explicitly, not through redux
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { getCurrentProjectIdentifier } from '@neptune/current-project-business-logic';

import { getTextWrappingState } from './selectors';
import { storeState } from '../storage-utils';

export enum TextWrappingActionTypes {
  Set = 'SetTextWrapping',
}

export const setTextWrapping = (
  id: string,
  wrapping: TextWrapping,
): NThunkAction<void, AnyAction> => {
  return (dispatch, getState) => {
    const projectIdentifier = getCurrentProjectIdentifier(getState());

    dispatch({
      type: TextWrappingActionTypes.Set,
      payload: {
        projectIdentifier,
        id,
        wrapping,
      },
    });

    const wrappingState = getTextWrappingState(getState());
    storeState(wrappingState);
  };
};

export type TextWrappingActions = {
  type: TextWrappingActionTypes.Set;
  payload: {
    projectIdentifier: string;
    id: string;
    wrapping: TextWrapping;
  };
};

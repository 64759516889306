// Generated from NQLLexer.g4 by ANTLR 4.8
// jshint ignore: start
import antlr4 from 'antlr4/index';



var serializedATN = ["\u0003\u608b\ua72a\u8133\ub9ed\u417c\u3be7\u7786\u5964",
    "\u0002\u0016\u0094\b\u0001\b\u0001\b\u0001\u0004\u0002\t\u0002\u0004",
    "\u0003\t\u0003\u0004\u0004\t\u0004\u0004\u0005\t\u0005\u0004\u0006\t",
    "\u0006\u0004\u0007\t\u0007\u0004\b\t\b\u0004\t\t\t\u0004\n\t\n\u0004",
    "\u000b\t\u000b\u0004\f\t\f\u0004\r\t\r\u0004\u000e\t\u000e\u0004\u000f",
    "\t\u000f\u0004\u0010\t\u0010\u0004\u0011\t\u0011\u0004\u0012\t\u0012",
    "\u0004\u0013\t\u0013\u0004\u0014\t\u0014\u0004\u0015\t\u0015\u0004\u0016",
    "\t\u0016\u0004\u0017\t\u0017\u0003\u0002\u0003\u0002\u0003\u0002\u0003",
    "\u0002\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0004\u0003\u0004\u0003",
    "\u0004\u0003\u0004\u0003\u0005\u0003\u0005\u0003\u0005\u0003\u0005\u0003",
    "\u0005\u0003\u0005\u0003\u0005\u0003\u0005\u0003\u0005\u0003\u0006\u0003",
    "\u0006\u0003\u0006\u0003\u0006\u0003\u0006\u0003\u0006\u0003\u0006\u0003",
    "\u0007\u0003\u0007\u0003\b\u0003\b\u0003\b\u0003\t\u0003\t\u0003\n\u0003",
    "\n\u0003\n\u0003\u000b\u0003\u000b\u0003\u000b\u0005\u000bZ\n\u000b",
    "\u0003\f\u0003\f\u0003\f\u0003\r\u0003\r\u0003\u000e\u0003\u000e\u0003",
    "\u000f\u0003\u000f\u0003\u0010\u0003\u0010\u0003\u0010\u0003\u0010\u0003",
    "\u0010\u0003\u0011\u0003\u0011\u0003\u0011\u0003\u0011\u0003\u0011\u0003",
    "\u0012\u0006\u0012p\n\u0012\r\u0012\u000e\u0012q\u0003\u0013\u0006\u0013",
    "u\n\u0013\r\u0013\u000e\u0013v\u0003\u0013\u0003\u0013\u0003\u0014\u0003",
    "\u0014\u0003\u0014\u0003\u0014\u0003\u0015\u0003\u0015\u0003\u0015\u0003",
    "\u0015\u0003\u0015\u0005\u0015\u0084\n\u0015\u0003\u0015\u0003\u0015",
    "\u0003\u0016\u0003\u0016\u0003\u0016\u0003\u0016\u0003\u0017\u0003\u0017",
    "\u0003\u0017\u0003\u0017\u0003\u0017\u0005\u0017\u0091\n\u0017\u0003",
    "\u0017\u0003\u0017\u0002\u0002\u0018\u0005\u0003\u0007\u0004\t\u0005",
    "\u000b\u0006\r\u0007\u000f\b\u0011\t\u0013\n\u0015\u000b\u0017\f\u0019",
    "\r\u001b\u000e\u001d\u000f\u001f\u0010!\u0015#\u0016%\u0011\'\u0012",
    ")\u0013+\u0002-\u0014/\u0002\u0005\u0002\u0003\u0004\u0011\u0004\u0002",
    "CCcc\u0004\u0002PPpp\u0004\u0002FFff\u0004\u0002QQqq\u0004\u0002TTt",
    "t\u0004\u0002VVvv\u0004\u0002EEee\u0004\u0002KKkk\u0004\u0002UUuu\u0004",
    "\u0002GGgg\u0004\u0002ZZzz\u0006\u0002/;C\\aac|\u0005\u0002\u000b\f",
    "\u000e\u000f\"\"\u0004\u0002$$^^\u0004\u0002^^bb\u0002\u0098\u0002\u0005",
    "\u0003\u0002\u0002\u0002\u0002\u0007\u0003\u0002\u0002\u0002\u0002\t",
    "\u0003\u0002\u0002\u0002\u0002\u000b\u0003\u0002\u0002\u0002\u0002\r",
    "\u0003\u0002\u0002\u0002\u0002\u000f\u0003\u0002\u0002\u0002\u0002\u0011",
    "\u0003\u0002\u0002\u0002\u0002\u0013\u0003\u0002\u0002\u0002\u0002\u0015",
    "\u0003\u0002\u0002\u0002\u0002\u0017\u0003\u0002\u0002\u0002\u0002\u0019",
    "\u0003\u0002\u0002\u0002\u0002\u001b\u0003\u0002\u0002\u0002\u0002\u001d",
    "\u0003\u0002\u0002\u0002\u0002\u001f\u0003\u0002\u0002\u0002\u0002!",
    "\u0003\u0002\u0002\u0002\u0002#\u0003\u0002\u0002\u0002\u0002%\u0003",
    "\u0002\u0002\u0002\u0002\'\u0003\u0002\u0002\u0002\u0003)\u0003\u0002",
    "\u0002\u0002\u0003+\u0003\u0002\u0002\u0002\u0004-\u0003\u0002\u0002",
    "\u0002\u0004/\u0003\u0002\u0002\u0002\u00051\u0003\u0002\u0002\u0002",
    "\u00075\u0003\u0002\u0002\u0002\t8\u0003\u0002\u0002\u0002\u000b<\u0003",
    "\u0002\u0002\u0002\rE\u0003\u0002\u0002\u0002\u000fL\u0003\u0002\u0002",
    "\u0002\u0011N\u0003\u0002\u0002\u0002\u0013Q\u0003\u0002\u0002\u0002",
    "\u0015S\u0003\u0002\u0002\u0002\u0017Y\u0003\u0002\u0002\u0002\u0019",
    "[\u0003\u0002\u0002\u0002\u001b^\u0003\u0002\u0002\u0002\u001d`\u0003",
    "\u0002\u0002\u0002\u001fb\u0003\u0002\u0002\u0002!d\u0003\u0002\u0002",
    "\u0002#i\u0003\u0002\u0002\u0002%o\u0003\u0002\u0002\u0002\'t\u0003",
    "\u0002\u0002\u0002)z\u0003\u0002\u0002\u0002+\u0083\u0003\u0002\u0002",
    "\u0002-\u0087\u0003\u0002\u0002\u0002/\u0090\u0003\u0002\u0002\u0002",
    "12\t\u0002\u0002\u000223\t\u0003\u0002\u000234\t\u0004\u0002\u00024",
    "\u0006\u0003\u0002\u0002\u000256\t\u0005\u0002\u000267\t\u0006\u0002",
    "\u00027\b\u0003\u0002\u0002\u000289\t\u0003\u0002\u00029:\t\u0005\u0002",
    "\u0002:;\t\u0007\u0002\u0002;\n\u0003\u0002\u0002\u0002<=\t\b\u0002",
    "\u0002=>\t\u0005\u0002\u0002>?\t\u0003\u0002\u0002?@\t\u0007\u0002\u0002",
    "@A\t\u0002\u0002\u0002AB\t\t\u0002\u0002BC\t\u0003\u0002\u0002CD\t\n",
    "\u0002\u0002D\f\u0003\u0002\u0002\u0002EF\t\u000b\u0002\u0002FG\t\f",
    "\u0002\u0002GH\t\t\u0002\u0002HI\t\n\u0002\u0002IJ\t\u0007\u0002\u0002",
    "JK\t\n\u0002\u0002K\u000e\u0003\u0002\u0002\u0002LM\u0007@\u0002\u0002",
    "M\u0010\u0003\u0002\u0002\u0002NO\u0007@\u0002\u0002OP\u0007?\u0002",
    "\u0002P\u0012\u0003\u0002\u0002\u0002QR\u0007>\u0002\u0002R\u0014\u0003",
    "\u0002\u0002\u0002ST\u0007>\u0002\u0002TU\u0007?\u0002\u0002U\u0016",
    "\u0003\u0002\u0002\u0002VZ\u0007?\u0002\u0002WX\u0007?\u0002\u0002X",
    "Z\u0007?\u0002\u0002YV\u0003\u0002\u0002\u0002YW\u0003\u0002\u0002\u0002",
    "Z\u0018\u0003\u0002\u0002\u0002[\\\u0007#\u0002\u0002\\]\u0007?\u0002",
    "\u0002]\u001a\u0003\u0002\u0002\u0002^_\u0007<\u0002\u0002_\u001c\u0003",
    "\u0002\u0002\u0002`a\u0007*\u0002\u0002a\u001e\u0003\u0002\u0002\u0002",
    "bc\u0007+\u0002\u0002c \u0003\u0002\u0002\u0002de\u0007$\u0002\u0002",
    "ef\u0003\u0002\u0002\u0002fg\b\u0010\u0002\u0002gh\b\u0010\u0003\u0002",
    "h\"\u0003\u0002\u0002\u0002ij\u0007b\u0002\u0002jk\u0003\u0002\u0002",
    "\u0002kl\b\u0011\u0002\u0002lm\b\u0011\u0004\u0002m$\u0003\u0002\u0002",
    "\u0002np\t\r\u0002\u0002on\u0003\u0002\u0002\u0002pq\u0003\u0002\u0002",
    "\u0002qo\u0003\u0002\u0002\u0002qr\u0003\u0002\u0002\u0002r&\u0003\u0002",
    "\u0002\u0002su\t\u000e\u0002\u0002ts\u0003\u0002\u0002\u0002uv\u0003",
    "\u0002\u0002\u0002vt\u0003\u0002\u0002\u0002vw\u0003\u0002\u0002\u0002",
    "wx\u0003\u0002\u0002\u0002xy\b\u0013\u0005\u0002y(\u0003\u0002\u0002",
    "\u0002z{\u0007$\u0002\u0002{|\u0003\u0002\u0002\u0002|}\b\u0014\u0006",
    "\u0002}*\u0003\u0002\u0002\u0002~\u0084\n\u000f\u0002\u0002\u007f\u0080",
    "\u0007^\u0002\u0002\u0080\u0084\u0007^\u0002\u0002\u0081\u0082\u0007",
    "^\u0002\u0002\u0082\u0084\u0007$\u0002\u0002\u0083~\u0003\u0002\u0002",
    "\u0002\u0083\u007f\u0003\u0002\u0002\u0002\u0083\u0081\u0003\u0002\u0002",
    "\u0002\u0084\u0085\u0003\u0002\u0002\u0002\u0085\u0086\b\u0015\u0002",
    "\u0002\u0086,\u0003\u0002\u0002\u0002\u0087\u0088\u0007b\u0002\u0002",
    "\u0088\u0089\u0003\u0002\u0002\u0002\u0089\u008a\b\u0016\u0006\u0002",
    "\u008a.\u0003\u0002\u0002\u0002\u008b\u0091\n\u0010\u0002\u0002\u008c",
    "\u008d\u0007^\u0002\u0002\u008d\u0091\u0007^\u0002\u0002\u008e\u008f",
    "\u0007^\u0002\u0002\u008f\u0091\u0007b\u0002\u0002\u0090\u008b\u0003",
    "\u0002\u0002\u0002\u0090\u008c\u0003\u0002\u0002\u0002\u0090\u008e\u0003",
    "\u0002\u0002\u0002\u0091\u0092\u0003\u0002\u0002\u0002\u0092\u0093\b",
    "\u0017\u0002\u0002\u00930\u0003\u0002\u0002\u0002\n\u0002\u0003\u0004",
    "Yqv\u0083\u0090\u0007\u0005\u0002\u0002\u0007\u0003\u0002\u0007\u0004",
    "\u0002\b\u0002\u0002\u0006\u0002\u0002"].join("");


var atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

var decisionsToDFA = atn.decisionToState.map( function(ds, index) { return new antlr4.dfa.DFA(ds, index); });

function NQLLexer(input) {
	antlr4.Lexer.call(this, input);
    this._interp = new antlr4.atn.LexerATNSimulator(this, atn, decisionsToDFA, new antlr4.PredictionContextCache());
    return this;
}

NQLLexer.prototype = Object.create(antlr4.Lexer.prototype);
NQLLexer.prototype.constructor = NQLLexer;

Object.defineProperty(NQLLexer.prototype, "atn", {
        get : function() {
                return atn;
        }
});

NQLLexer.EOF = antlr4.Token.EOF;
NQLLexer.AND = 1;
NQLLexer.OR = 2;
NQLLexer.NOT = 3;
NQLLexer.CONTAINS = 4;
NQLLexer.EXISTS = 5;
NQLLexer.GT = 6;
NQLLexer.GE = 7;
NQLLexer.LT = 8;
NQLLexer.LE = 9;
NQLLexer.EQ = 10;
NQLLexer.NE = 11;
NQLLexer.COLON = 12;
NQLLexer.LPAREN = 13;
NQLLexer.RPAREN = 14;
NQLLexer.SIMPLE_STRING = 15;
NQLLexer.WS = 16;
NQLLexer.DOUBLE_QUOTED_STRING = 17;
NQLLexer.BACK_QUOTED_STRING = 18;
NQLLexer.BEGIN_DOUBLE_QUOTE = 19;
NQLLexer.BEGIN_BACK_QUOTE = 20;

NQLLexer.DOUBLE_QUOTED_STRING_MODE = 1;
NQLLexer.BACK_QUOTED_STRING_MODE = 2;

NQLLexer.prototype.channelNames = [ "DEFAULT_TOKEN_CHANNEL", "HIDDEN" ];

NQLLexer.prototype.modeNames = [ "DEFAULT_MODE", "DOUBLE_QUOTED_STRING_MODE", 
                                 "BACK_QUOTED_STRING_MODE" ];

NQLLexer.prototype.literalNames = [ null, null, null, null, null, null, 
                                    "'>'", "'>='", "'<'", "'<='", null, 
                                    "'!='", "':'", "'('", "')'" ];

NQLLexer.prototype.symbolicNames = [ null, "AND", "OR", "NOT", "CONTAINS", 
                                     "EXISTS", "GT", "GE", "LT", "LE", "EQ", 
                                     "NE", "COLON", "LPAREN", "RPAREN", 
                                     "SIMPLE_STRING", "WS", "DOUBLE_QUOTED_STRING", 
                                     "BACK_QUOTED_STRING", "BEGIN_DOUBLE_QUOTE", 
                                     "BEGIN_BACK_QUOTE" ];

NQLLexer.prototype.ruleNames = [ "AND", "OR", "NOT", "CONTAINS", "EXISTS", 
                                 "GT", "GE", "LT", "LE", "EQ", "NE", "COLON", 
                                 "LPAREN", "RPAREN", "BEGIN_DOUBLE_QUOTE", 
                                 "BEGIN_BACK_QUOTE", "SIMPLE_STRING", "WS", 
                                 "DOUBLE_QUOTED_STRING", "DOUBLE_QUOTED_CHAR", 
                                 "BACK_QUOTED_STRING", "BACK_QUOTED_CHAR" ];

NQLLexer.prototype.grammarFileName = "NQLLexer.g4";


export { NQLLexer };


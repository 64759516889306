import { makeTreeFromArray } from '@neptune/shared/common-util';
import { getEntityShortId } from '@neptune/shared/entity-domain';
import { makeEntityIdentifier } from '@neptune/shared/entity-util';
import { getCurrentRouteParams } from '@neptune/shared/routing-business-logic';
import { AttributeDefinition } from 'domain/experiment/attribute';
import { createSelector } from 'reselect';
import { CurrentEntityState } from './reducer';

const getCurrentEntityState = (state: any): CurrentEntityState => state.currentEntity;

const getCurrentBasicEntityInternal = (state: any) =>
  getCurrentEntityState(state).basicEntity.entity;
/** @deprecated todo: move out of redux */
const getCurrentEntityAttributeDefinitionsData = (state: any) =>
  getCurrentEntityState(state).attributeDefinitions;

export const getCurrentBasicEntityFetchStatus = createSelector(
  getCurrentEntityState,
  (state: CurrentEntityState) => state.basicEntity.fetchStatus,
);

export const getCurrentBasicEntityFetchError = createSelector(
  getCurrentEntityState,
  (state: CurrentEntityState) => state.basicEntity.error,
);

export const getCurrentBasicEntity = createSelector(
  [getCurrentBasicEntityInternal, getCurrentRouteParams],
  (entity, params) => {
    if (!entity || !params.organizationName || !params.projectName || !params.shortId) {
      return undefined;
    }

    const entityId = makeEntityIdentifier(
      entity.organizationName,
      entity.projectName,
      getEntityShortId(entity),
    );
    const expectedEntityId = makeEntityIdentifier(
      params.organizationName,
      params.projectName,
      params.shortId,
    );

    if (entityId !== expectedEntityId) {
      return undefined;
    }

    return entity;
  },
);

/** @deprecated todo: move out of redux */
export const getCurrentEntityAttributeDefinitionsStatus = createSelector(
  getCurrentEntityState,
  (state: CurrentEntityState) => state.attributeDefinitions.fetchStatus,
);

/** @deprecated todo: move out of redux */
export const getCurrentEntityAttributeDefinitionsError = createSelector(
  getCurrentEntityState,
  (state: CurrentEntityState) => state.attributeDefinitions.error,
);

/** @deprecated todo: move out of redux */
export const getCurrentEntityAttributeDefinitions = createSelector(
  [getCurrentEntityAttributeDefinitionsData, getCurrentRouteParams],
  (data, params) => {
    if (!data || !params.organizationName || !params.projectName || !params.shortId) {
      return undefined;
    }

    const expectedEntityId = makeEntityIdentifier(
      params.organizationName,
      params.projectName,
      params.shortId,
    );

    if (data.forId !== expectedEntityId) {
      return undefined;
    }

    return data.attributeDefinitions;
  },
);

const getName = (entry: AttributeDefinition) => entry.name;

/** @deprecated todo: move out of redux */
export const getEntityAttributeTree = createSelector(
  [getCurrentEntityAttributeDefinitions],
  (attributes) => (attributes ? makeTreeFromArray(attributes, getName) : undefined),
);

export const getCurrentEntityShortId = (state: any) => {
  const entity = getCurrentBasicEntity(state);
  return entity && getEntityShortId(entity);
};

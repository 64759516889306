import { ProjectWithRole } from '@neptune/shared/core-project-domain';
import { WithPermissions } from '@neptune/shared/project-domain';
import { entryPermissionsEnhance } from './use-permission-enhanced-entries';

/** @deprecated use usePermissionEnhancedEntry or usePermissionEnhancedEntries */
export function createEntryPermissionsEnhancer<T extends object>(
  project: ProjectWithRole,
): (entry: T) => WithPermissions<T> {
  return (entry: T) => entryPermissionsEnhance(project, entry);
}

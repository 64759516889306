export const featureFlags = [
  'withViewsPhase1',
  'withViewsPhase2',

  'withAdminPanel',

  // active projects pricing flags
  'withActiveProjectsPricing',
  'withArchivedProjects',
  'withLockedLegacyPricing',

  'withScatterPlotWidget',
  'withReports',

  'withXAxisMetric',
  'withCustomYAxis',
  'withRefactoredCharts',
  'withRunGroupsV4',

  'withUnificationV1',

  'withExperimentForksV1', // to track removal https://neptune-labs.atlassian.net/browse/NPT-14412
  'withExperimentForksV2', // to track removal https://neptune-labs.atlassian.net/browse/NPT-14451
  'withExperimentForksLeafOnlyToggle', // to track removal https://neptune-labs.atlassian.net/browse/NPT-15151
  'withChartDynamicUniqueXPoints',
  'withEmbedInNotionFeature',
  'withConfigureChartsModal',
  'withNewRelativeTime',
  'withExperimentAutoFollow',
  'withSectionsInReports',
  'withSectionsInDashboards',
] as const;
export type FeatureFlag = (typeof featureFlags)[number];

export type FeatureFlagConfig = `${FeatureFlag}Config`;

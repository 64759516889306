import React from 'react';
import { bemBlock, Layout, Icon, Header, Text } from '@neptune/shared/venus-ui';
import { ProjectWithRole } from '@neptune/shared/core-project-domain';
import { ProjectPrivacyIndicator } from '@neptune/shared/project-ui';

import './LegacyProjectHeader.less';

type ProjectHeaderProps = {
  membersList?: React.ReactElement;
  menu?: React.ReactElement;
  tabs?: React.ReactElement;
  project: ProjectWithRole;
};

const block = bemBlock('legacy-project-header');

export const LegacyProjectHeader = React.forwardRef<HTMLElement, ProjectHeaderProps>(
  ({ membersList, menu, project, tabs }, ref) => {
    return (
      <Header
        className={block()}
        span="shrink"
        variant="small"
        height={64}
        justifyContent="center"
        data-role="project-header"
      >
        <Layout.Row alignItems="center" spacedChildren="xl">
          <Layout.Row alignItems="center" span="shrink" spacedChildren="md">
            <Layout.Column className={block()} span="shrink">
              <Layout.Row withGutter="md" spacedChildren="xs">
                <ProjectPrivacyIndicator privacy={project.visibility} size="xs" />
                <Text
                  className={block('name')}
                  size="xs"
                  children={project.name}
                  wordBreak="keep-all"
                  data-role="project-header-name"
                />
                {/* @TODO dont use disabled color fix when icon uses new palette  */}
                <Icon glyph="arrow-right" color="disabled" />
              </Layout.Row>
              <Layout.Row>{tabs}</Layout.Row>
            </Layout.Column>
            {/* TODO: Toggling favourite projects will be introduced in later phase of new-ui */}
            {/* <FavoriteIcon isFavorite={isFavorite} onClick={toggleFavorite} />*/}
          </Layout.Row>
          <Layout.Fill />
          <Layout.Element elementRef={ref} id="project-menu-portal" />
          <Layout.Fill />
          <Layout.Row spacedChildren="md" alignItems="center" span="shrink">
            {membersList}
          </Layout.Row>
          {menu}
        </Layout.Row>
      </Header>
    );
  },
);

// Libs
import { createSelector } from 'reselect';

// App
import { AppState } from 'state/types';
import { getRunIdentificationPair, isRunIdentifierPair } from '@neptune/shared/entity-domain';
import { getLeaderboardState } from '@neptune/shared/leaderboard-business-logic';
import { getCurrentRouteParams } from '@neptune/shared/routing-business-logic';
import { getCurrentProjectProjectKey } from '@neptune/current-project-business-logic';
import { LeaderboardId } from '@neptune/shared/entity-leaderboard-domain';
import { getExperimentAutoFollowEnabled } from 'common/featureFlag';
import getParentState from '../selectors';
import { CompareState } from './types';
import { extractCachedCompareState } from './utils';

export function getCompareState(state: AppState): CompareState {
  return getParentState(state).compare;
}

const getAutoModeCompareKeysForCurrentProject = (
  state: AppState,
  id: LeaderboardId.Reports | LeaderboardId.Runs,
): [string[], string[]] | undefined =>
  getCompareAutoModeForCurrentProject(state) ? getAutoModeCompareKeys(state, id) : undefined;

const getCompareCurrentRouteParams = (state: AppState) => getCurrentRouteParams(state).compare;

const EMPTY_RUN_IDENTIFICATION_KEYS: [string[], string[]] = [[], []];
export const getCompareKeysForCurrentProject: (
  state: AppState,
  id: LeaderboardId.Reports | LeaderboardId.Runs,
) => [string[], string[]] = createSelector(
  getCompareAutoModeForCurrentProject,
  getCompareCurrentRouteParams,
  getCurrentProjectProjectKey,
  getAutoModeCompareKeysForCurrentProject,
  (
    autoMode: boolean,
    packedCompareState: string | undefined,
    projectKey: string | undefined,
    autoModeIds: [string[], string[]] | undefined,
  ) => {
    if (autoMode) {
      return autoModeIds || EMPTY_RUN_IDENTIFICATION_KEYS;
    }

    if (projectKey != null && packedCompareState != null) {
      return extractCachedCompareState(packedCompareState, projectKey);
    }

    return EMPTY_RUN_IDENTIFICATION_KEYS;
  },
);

export function getCompareAutoModeForCurrentProject(state: AppState): boolean {
  return getCompareCurrentRouteParams(state) === 'auto';
}

const COMPARE_AUTO_MODE_LIMIT = 5;

export const getAutoModeCompareKeys: (
  state: AppState,
  id: LeaderboardId.Reports | LeaderboardId.Runs,
) => [string[], string[]] = createSelector(getLeaderboardState, (leaderboard) => {
  const runIdentificationPairs = leaderboard.entries
    .slice(0, COMPARE_AUTO_MODE_LIMIT)
    .map(getRunIdentificationPair)
    .filter(isRunIdentifierPair);

  const runIds = [];
  const experimentNames = [];

  for (const pair of runIdentificationPairs) {
    if (getExperimentAutoFollowEnabled() && pair.experimentName) {
      experimentNames.unshift(pair.experimentName);
    } else {
      runIds.unshift(pair.runId);
    }
  }

  return [experimentNames, runIds];
});

export default getCompareState;

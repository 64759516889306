import {
  LeaderboardViewColumnDTO,
  LeaderboardViewColumnDTOAggregationModeEnum,
  AttributeTypeDTO,
  LeaderboardViewColumnDTODisplayModeEnum,
} from '@neptune/shared/core-apis-leaderboard-domain';
import { AggregationMode, ColumnType, DisplayMode, LeaderboardColumn } from './column-model';
import { isFloatColumn, isFloatSeriesColumn } from './column-model-utils';
import { invertMapTyped } from 'common/tsHelpers';
import { leaderboardColumnFactory } from './column-factory';

const columnTypeMapping: Partial<Record<AttributeTypeDTO, ColumnType>> = {
  [AttributeTypeDTO.Artifact]: 'artifact',
  [AttributeTypeDTO.Bool]: 'bool',
  [AttributeTypeDTO.Datetime]: 'datetime',
  [AttributeTypeDTO.ExperimentState]: 'experimentState',
  [AttributeTypeDTO.File]: 'file',
  [AttributeTypeDTO.FileSet]: 'fileSet',
  [AttributeTypeDTO.Float]: 'float',
  [AttributeTypeDTO.FloatSeries]: 'floatSeries',
  [AttributeTypeDTO.GitRef]: 'gitRef',
  [AttributeTypeDTO.Int]: 'int',
  [AttributeTypeDTO.NotebookRef]: 'notebookRef',
  [AttributeTypeDTO.String]: 'string',
  [AttributeTypeDTO.StringSeries]: 'stringSeries',
  [AttributeTypeDTO.StringSet]: 'stringSet',
};
const invertedColumnTypeMapping = invertMapTyped(columnTypeMapping);

const aggregationModeMapping: Partial<
  Record<LeaderboardViewColumnDTOAggregationModeEnum, AggregationMode>
> = {
  [LeaderboardViewColumnDTOAggregationModeEnum.Average]: 'average',
  [LeaderboardViewColumnDTOAggregationModeEnum.Last]: 'last',
  [LeaderboardViewColumnDTOAggregationModeEnum.Max]: 'max',
  [LeaderboardViewColumnDTOAggregationModeEnum.Min]: 'min',
  [LeaderboardViewColumnDTOAggregationModeEnum.Variance]: 'variance',
};
const invertedAggregationModeMapping = invertMapTyped(aggregationModeMapping);

const displayModeMapping: Partial<Record<LeaderboardViewColumnDTODisplayModeEnum, DisplayMode>> = {
  [LeaderboardViewColumnDTODisplayModeEnum.Auto]: 'auto',
  [LeaderboardViewColumnDTODisplayModeEnum.Decimal]: 'decimal',
  [LeaderboardViewColumnDTODisplayModeEnum.Scientific]: 'scientific',
};
const invertedDisplayModeMapping = invertMapTyped(displayModeMapping);

export abstract class ColumnModelConverter {
  static columnFromApiToDomain(source: LeaderboardViewColumnDTO): LeaderboardColumn {
    const columnType = ColumnModelConverter.columnTypeFromApiToDomain(source.columnType);
    return leaderboardColumnFactory({ ...source, columnType });
  }

  static columnFromDomainToApi(source: LeaderboardColumn): LeaderboardViewColumnDTO | undefined {
    const columnType = ColumnModelConverter.columnTypeFromDomainToApi(source.columnType);

    if (!columnType) {
      // not supported column
      return undefined;
    }

    const target: LeaderboardViewColumnDTO = {
      id: source.id,
      columnType,
      width: source.width,
      pinned: source.pinned,
      displayName: source.displayName,
      color: source.color,
    };

    if (isFloatColumn(source) || isFloatSeriesColumn(source)) {
      target.displayMode = ColumnModelConverter.displayModeFromDomainToApi(source.displayMode);

      if (isFloatSeriesColumn(source)) {
        target.aggregationMode = ColumnModelConverter.aggregationModeFromDomainToApi(
          source.aggregationMode,
        );
      }
    }

    return target;
  }

  static columnTypeFromApiToDomain(source: AttributeTypeDTO): ColumnType {
    return columnTypeMapping[source] || 'notSupported';
  }

  static columnTypeFromDomainToApi(source: ColumnType): AttributeTypeDTO | undefined {
    return invertedColumnTypeMapping[source];
  }

  static aggregationModeFromApiToDomain(
    source: LeaderboardViewColumnDTOAggregationModeEnum,
  ): AggregationMode | undefined {
    return aggregationModeMapping[source];
  }

  static aggregationModeFromDomainToApi(
    source: AggregationMode,
  ): LeaderboardViewColumnDTOAggregationModeEnum | undefined {
    return invertedAggregationModeMapping[source];
  }

  static displayModeFromApiToDomain(
    source: LeaderboardViewColumnDTODisplayModeEnum,
  ): DisplayMode | undefined {
    return displayModeMapping[source];
  }

  static displayModeFromDomainToApi(
    source: DisplayMode,
  ): LeaderboardViewColumnDTODisplayModeEnum | undefined {
    return invertedDisplayModeMapping[source];
  }
}

import React from 'react';

import { QueryFilterSearch } from '@neptune/shared/search-domain';
import { Text } from '@neptune/shared/venus-ui';

import { PropertyRow } from 'components/layouts/rows';
import { KnownAttributes, StringSetAttribute } from 'domain/experiment/attribute';

import { EditStringSetAttribute } from './inputs/EditStringSetAttribute';

type StringSetRendererProps = {
  attribute: StringSetAttribute;
  'data-name': string;
  canModify: boolean;
  colorsGetter: (tag: string) => { color: string; backgroundColor: string };
  label: string;
  labelWidth?: string;
  tagsDataSource: QueryFilterSearch<string, string>;
  onAdd: (values: string[]) => Promise<void>;
  onRemove: (values: string[]) => Promise<void>;
  noValuesText: string;
  addValuePlaceholder: string;
  stringSetValues: string[];
};

export const StringSetRenderer = ({
  'data-name': dataName,
  labelWidth = '100px',
  attribute,
  label,
  canModify,
  colorsGetter,
  tagsDataSource,
  onAdd,
  onRemove,
  noValuesText,
  addValuePlaceholder,
  stringSetValues,
}: StringSetRendererProps) => {
  return (
    <PropertyRow labelWidth={labelWidth} label={label} data-name={dataName}>
      {stringSetValues.length > 0 || canModify ? (
        <EditStringSetAttribute
          stringSetValues={stringSetValues}
          attribute={attribute}
          colorsGetter={colorsGetter}
          isEditable={canModify}
          tagsDataSource={tagsDataSource}
          wrap="wrap"
          onAdd={onAdd}
          onRemove={onRemove}
          addTagPlaceholder={addValuePlaceholder}
          withColor={attribute.attributeName === KnownAttributes.GroupTags}
        />
      ) : (
        <Text color="text-alt" size="sm">
          {noValuesText}
        </Text>
      )}
    </PropertyRow>
  );
};

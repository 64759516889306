import {
  leaderboardInitialState,
  LeaderboardsState,
  LeaderboardState,
} from './leaderboards-reducer';
import { BaseLeaderboardEntry } from '@neptune/shared/leaderboard-domain';
import { fetchStatus as FetchStatus, isFetchStatusNone } from 'state/fetch-status';

export const getLeaderboardsState = (state: any): LeaderboardsState => {
  return state.leaderboards;
};

export const getLeaderboardState = <T extends BaseLeaderboardEntry>(
  state: any,
  id: string,
): LeaderboardState<T> => {
  return (getLeaderboardsState(state)[id] || leaderboardInitialState) as LeaderboardState<T>;
};

export const areThereAnyLeaderboardEntries = (state: any, id: string): boolean => {
  return getLeaderboardState(state, id).entries.length > 0;
};

export const areThereAnyPendingLeaderboardRequests = (state: any, id: string): boolean => {
  return getLeaderboardState(state, id).fetchStatus === FetchStatus.PENDING;
};

export const getLeaderboardEntries = <T extends BaseLeaderboardEntry>(
  state: any,
  id: string,
): T[] | undefined => {
  const subState = getLeaderboardState<T>(state, id);
  return isFetchStatusNone(subState.fetchStatus) ? undefined : subState.entries;
};

export const getLeaderboardMatchingItemCount = <T extends BaseLeaderboardEntry>(
  state: any,
  id: string,
): number | undefined => {
  const subState = getLeaderboardState<T>(state, id);
  return isFetchStatusNone(subState.fetchStatus) ? undefined : subState.matchingItemCount;
};

import { isEqual, maxBy, sortBy } from 'lodash';
import {
  Dashboard,
  WidgetLayouts,
  getDefaultWidgetHeight,
  getWidgetDefaultDimensions,
  GRID_LAYOUT_COLUMNS,
  WidgetDefaultDimensionsTable,
  defaultWidgetHeights,
} from 'domain/dashboard';
import {
  isChartWidget,
  isImageWidget,
  isWidgetAttributeBased,
  Widget,
  WidgetLayout,
  WidgetType,
} from 'domain/widget';
import { ExperimentsColorManager, HexColor } from 'common/experimentsColorPalette';
import { naturalStringComparator } from 'common/tools';
import { Entity, getEntityAttribute, makeEntityIdentifier } from '@neptune/shared/entity-domain';
import {
  getIdFromAttributes,
  getRunIdentificationKeyFromAttributes,
  KnownAttributes,
} from 'domain/experiment/attribute';
import { getExperimentAutoFollowEnabled } from './featureFlag';

export function findNewWidgetLayout(
  widget: Widget,
  layouts: WidgetLayouts,
  defaultDimensionsTable?: WidgetDefaultDimensionsTable,
): WidgetLayout {
  const { w: width, h: height } = getWidgetDefaultDimensions(widget, defaultDimensionsTable);
  const rowMap = layouts.reduce((result: Record<number, WidgetLayout[]>, current) => {
    if (!result[current.y]) {
      result[current.y] = [];
    }

    result[current.y].push(current);

    return result;
  }, {});

  const rowIds = Object.keys(rowMap)
    .map(Number)
    .sort((a, b) => a - b);
  let i = rowIds.shift();

  while (i != null) {
    const row = rowMap[i];
    const lastInRow = maxBy(row, 'x');

    // there's enough room horizontally
    if (lastInRow && GRID_LAYOUT_COLUMNS - (lastInRow.x + lastInRow.w) >= width) {
      const tallestInRow = maxBy(row, 'h');

      // add it at the end of the current row
      if (
        // there's enough room vertically
        (tallestInRow && height <= tallestInRow.h) ||
        // we're in the last row so the height doesn't matter
        rowIds.length === 0
      ) {
        return {
          id: widget.id,
          x: lastInRow.x + lastInRow.w,
          y: i,
          w: width,
          h: height,
        };
      }
      // add it at the start of the next row available
    } else if (rowIds.length === 0) {
      const tallestInRow = maxBy(row, 'h');

      return {
        id: widget.id,
        x: 0,
        y: i + (tallestInRow?.h || 0),
        w: width,
        h: height,
      };
    }

    i = rowIds.shift();
  }

  // empty dashboard
  return {
    id: widget.id,
    x: 0,
    y: 0,
    w: width,
    h: height,
  };
}

export function generateSingleColumnLayouts(
  sourceLayouts: WidgetLayout[],
  widgetMap: Partial<Record<string, Pick<Widget, 'type'>>>,
  layoutColumns = 1,
  // Allow overriding for tests.
  defaultHeights: Partial<Record<WidgetType, number>> = defaultWidgetHeights,
): WidgetLayout[] {
  // Avoid edge case in code below.
  if (!sourceLayouts.length) {
    return [];
  }

  const sorted = sortBy(sourceLayouts, 'y', 'x');

  const result: WidgetLayout[] = [];
  let nextY = 0;

  for (const layout of sorted) {
    const h = getDefaultWidgetHeight(widgetMap[layout.id], defaultHeights);

    result.push({
      id: layout.id,
      x: 0,
      y: nextY,
      w: layoutColumns,
      h,
    });

    nextY += h;
  }

  return result;
}

export function areLayoutsEqual(first: WidgetLayouts, second: WidgetLayouts): boolean {
  return isEqual(sortBy(first, 'id'), sortBy(second, 'id'));
}

export function makeCompareColorManager(colorMap: Record<string, string>) {
  const colorPaletteManager = new ExperimentsColorManager((id) => id.split('/')[0]);
  Object.entries(colorMap).forEach(([id, color]) => {
    colorPaletteManager.setColor(id, color);
  });
  return colorPaletteManager;
}

const DEFAULT_COLOR = '#8D9195';

export function convertColorMapKeysToEntityIds(
  colorMap: Record<string, string>,
  getColorForTag: (tag: string) => { backgroundColor: HexColor },
  entities: Entity[],
  enabledRunsMatch: Set<string>,
  autoFollowFeatureEnabled = getExperimentAutoFollowEnabled(),
): Record<string, string> {
  const entitiesById = entities.reduce((result: Record<string, Entity>, entity) => {
    const runIdentificationKey = autoFollowFeatureEnabled
      ? getRunIdentificationKeyFromAttributes(entity.attributes)
      : getIdFromAttributes(entity.attributes);

    if (runIdentificationKey) {
      result[runIdentificationKey] = entity;
    }

    return result;
  }, {});

  return Object.entries(colorMap).reduce((result: Record<string, string>, [id, color]) => {
    const entity = entitiesById[id];

    if (!entity) {
      return result;
    }

    const groupTags =
      getEntityAttribute(entity, KnownAttributes.GroupTags, 'stringSet')?.values ?? [];
    const enabledRunsMatchOnGroup = groupTags.filter((tag) => enabledRunsMatch.has(tag));

    if (enabledRunsMatchOnGroup.length > 0 && groupTags.length === 1) {
      result[makeEntityIdentifier(entity.id, entity.type)] = getColorForTag(
        enabledRunsMatchOnGroup[0],
      ).backgroundColor;
      return result;
    }

    if (enabledRunsMatchOnGroup.length > 0 && groupTags.length > 1) {
      result[makeEntityIdentifier(entity.id, entity.type)] = DEFAULT_COLOR;
      return result;
    }

    result[makeEntityIdentifier(entity.id, entity.type)] = color;
    return result;
  }, {});
}

export function generateComparableDashboard(
  dashboard: Dashboard,
  widgetDefaultHeightOverride?: Partial<Record<WidgetType, number>>,
): Dashboard | undefined {
  const widgets = generateComparableWidgets(dashboard.widgets);

  if (!widgets.length) {
    return;
  }

  return {
    ...dashboard,
    autoGenerated: true,
    widgets,
    gridLayouts: calculateCompareWidgetLayouts(
      widgets,
      dashboard.gridLayouts,
      widgetDefaultHeightOverride,
    ),
  };
}

function generateComparableWidgets(widgets: Widget[]): Widget[] {
  return widgets.reduce((result: Widget[], widget) => {
    if (isChartWidget(widget) && isWidgetAttributeBased(widget)) {
      // single-attribute widget can be added as is
      if (widget.attributes.length === 1) {
        result.push(widget);
        // split multi-attribute widget into separate widgets
      } else if (widget.attributes.length > 1) {
        const { name, ...widgetProps } = widget;

        widget.attributes.forEach((attribute, i) => {
          // don't add duplicates
          if (!result.some(({ attributes }) => isEqual(attributes?.[0], attribute))) {
            result.push({
              ...widgetProps,
              id: `${widget.id}--${i}`,
              attributes: [attribute],
            });
          }
        });
      }
    }

    if (isImageWidget(widget)) {
      result.push({
        ...widget,
        type: 'imageComparison',
      });
    }

    return result;
  }, []);
}

function calculateCompareWidgetLayouts(
  widgets: Widget[],
  layouts: WidgetLayouts,
  widgetDefaultHeightOverride?: Partial<Record<WidgetType, number>>,
): WidgetLayouts {
  return getCompareLayouts(widgets, layouts, widgetDefaultHeightOverride);
}

function getCompareLayouts(
  widgets: Widget[],
  layouts: WidgetLayouts,
  widgetDefaultHeightOverride?: Partial<Record<WidgetType, number>>,
): WidgetLayout[] {
  // primarily sort widgets by their position in the dashboard (left -> right, top -> bottom)
  const sortedLayouts = sortBy(layouts, 'x', 'y');
  const layoutsOrder = sortedLayouts.map(({ id }) => id);

  return widgets
    .sort((a, b) => {
      const uidA = a.id.split('--')[0];
      const uidB = b.id.split('--')[0];
      const indexA = layoutsOrder.indexOf(uidA);
      const indexB = layoutsOrder.indexOf(uidB);

      return (
        indexA - indexB ||
        // in case of widgets generated from widgets with multiple attributes, sort them by attribute names
        naturalStringComparator(a.attributes?.[0].name, b.attributes?.[0].name)
      );
    })
    .reduce((result: WidgetLayout[], widget) => {
      const uid = widget.id.split('--')[0];
      const layout = sortedLayouts.find(({ id }) => uid === id);
      const lowest = maxBy(result, 'y') || { h: 0, y: 0 };

      result.push({
        id: widget.id,
        x: 0,
        y: lowest.y + lowest.h,
        w: GRID_LAYOUT_COLUMNS,
        h: Math.max(layout?.h ?? 0, getDefaultWidgetHeight(widget, widgetDefaultHeightOverride)),
      });

      return result;
    }, []);
}

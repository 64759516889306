import urlParse from 'url-parse';
import { createSessionStorageAccessor } from 'common/storage-session';
import config from 'config';
import { FeatureFlags } from '@neptune/config-core-domain';

import { FeatureFlag, FeatureFlagConfig, featureFlags } from './featureFlag-types';

let featureFlagsInitialized = false;

export type FeatureFlagState = Record<FeatureFlag, boolean | undefined> &
  Record<FeatureFlagConfig, any>;

const sessionStorage = createSessionStorageAccessor<FeatureFlagState>();

function isFeatureFlag(text: any): text is FeatureFlag {
  return featureFlags.includes(text);
}

export function handleFeatureFlags() {
  const urlParams = urlParse(window.location.search, true);
  const setFeatureFlag = urlParams.query['setFeatureFlag'];
  const resetFeatureFlag = urlParams.query['resetFeatureFlag'];

  setFeatureFlag
    ?.split('~')
    .filter(isFeatureFlag)
    .forEach((flag) => {
      sessionStorage.setItem(flag, true);

      const featureFlagConfig = urlParams.query['featureFlagConfig'];

      if (featureFlagConfig !== undefined) {
        sessionStorage.setItem(`${flag}Config` as const, featureFlagConfig);
      }
    });

  resetFeatureFlag
    ?.split('~')
    .filter(isFeatureFlag)
    .forEach((flag) => {
      sessionStorage.setItem(flag, false);
    });

  featureFlagsInitialized = true;
}

const getViewPhase1EnabledRaw = createFlagGetter('withViewsPhase1');
const getViewPhase2EnabledRaw = createFlagGetter('withViewsPhase2');

export const getViewsPhase1Enabled = () => {
  if (!getViewPhase1EnabledRaw() && getViewPhase2EnabledRaw()) {
    throw new Error('Incompatible flags used: !withViewsPhase1 && withViewsPhase2');
  }

  return getViewPhase1EnabledRaw();
};

export const getViewsPhase2Enabled = () => {
  if (!getViewPhase1EnabledRaw() && getViewPhase2EnabledRaw()) {
    throw new Error('Incompatible flags used: !withViewsPhase1 && withViewsPhase2');
  }

  return getViewPhase2EnabledRaw();
};

export const getAdminPanelEnabled = createFlagGetter('withAdminPanel');

export const getActiveProjectsPricingEnabled = createFlagGetter('withActiveProjectsPricing');
export const getArchivedProjectsEnabled = createFlagGetter('withArchivedProjects');
export const getLockedLegacyPricingEnabled = createFlagGetter('withLockedLegacyPricing');

export const getScatterPlotWidget = createFlagGetter('withScatterPlotWidget');
export const getReports = createFlagGetter('withReports');

export const getXAxisMetricEnabled = createFlagGetter('withXAxisMetric');
export const getCustomYAxisEnabled = createFlagGetter('withCustomYAxis');
export const getRefactoredChartsEnabled = createFlagGetter('withRefactoredCharts');

export const getRunGroupsV4Enabled = createFlagGetter('withRunGroupsV4');
export const getUnificationV1Enabled = createFlagGetter('withUnificationV1');
export const getChartDynamicUniqueXPoints = createFlagGetter('withChartDynamicUniqueXPoints');

// todo: merge getExperimentForksEnabledV1 with getExperimentForksLeafOnlyToggleEnabled and name it getExperimentsV0 enabled
// todo: rename getExperimentForksEnabledV2 to getAttributesHistoryEnabled
export const getExperimentForksEnabledV1 = createFlagGetter('withExperimentForksV1');
export const getExperimentForksEnabledV2 = createFlagGetter('withExperimentForksV2');
export const getExperimentForksLeafOnlyToggleEnabled = createFlagGetter(
  'withExperimentForksLeafOnlyToggle',
);

export const getConfigureChartsModalEnabled = createFlagGetter('withConfigureChartsModal');

export const getEmbedInNotionEnabled = createFlagGetter('withEmbedInNotionFeature');

export const getNewRelativeTimeEnabled = createFlagGetter('withNewRelativeTime');

export const getExperimentAutoFollowEnabled = createFlagGetter('withExperimentAutoFollow');

export const getSectionsInReports = createFlagGetter('withSectionsInReports');
export const getSectionsInDashboards = createFlagGetter('withSectionsInDashboards');

export function getFeatureFlagConfig(key: FeatureFlag) {
  const value = sessionStorage.getItem(`${key}Config` as const);

  try {
    return JSON.parse(value);
  } catch (e) {}
}

function createFlagGetter(key: FeatureFlag & keyof FeatureFlags): () => boolean {
  if (!featureFlagsInitialized) {
    handleFeatureFlags();
  }

  return () => sessionStorage.getItem(key) ?? config[key] ?? false;
}

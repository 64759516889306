import { ColorMapState } from './types';

type StoreCompareColorsParams = {
  colorMap: ColorMapState;
  id: string;
  projectIdentifier: string;
};

export function storeCompareColors({ colorMap, id, projectIdentifier }: StoreCompareColorsParams) {
  const key = buildStorageKey(projectIdentifier, id);

  setItem(key, colorMap);
}

type GetStoredCompareColorMapParams = {
  id: string;
  projectIdentifier: string;
};

export function getStoredCompareColorMap({
  id,
  projectIdentifier,
}: GetStoredCompareColorMapParams): ColorMapState {
  const key = buildStorageKey(projectIdentifier, id);

  return getItem(key) || {};
}

type ProjectIdentifier = string;
type CompareIdsShortcut = string;

function buildStorageKey(projectIdentifier: ProjectIdentifier, compareIds: CompareIdsShortcut) {
  return `neptune-colors--${projectIdentifier}--${compareIds}`;
}

export function getItem(storageKey: string): ColorMapState {
  try {
    const serializedState = window.sessionStorage.getItem(storageKey);

    if (serializedState === null) {
      return {};
    }

    return JSON.parse(serializedState);
  } catch (err) {
    return {};
  }
}

export function setItem(storageKey: string, value: ColorMapState) {
  try {
    const serializedState = JSON.stringify(value);
    window.sessionStorage.setItem(storageKey, serializedState);
  } catch (err) {
    // nop
  }
}

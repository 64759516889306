import React from 'react';
import { Icon, Link } from '@neptune/shared/venus-ui';
import type { NotebookRefAttribute } from 'domain/experiment/attribute';
import NotebookLink from 'components/links/notebook-link/NotebookLink';
import { PropertyRow } from 'components/layouts/rows';
import { createPageIdUrlParam } from 'common/pages';

type NotebookRendererProps = {
  attribute?: NotebookRefAttribute;
  labelWidth?: string;
  label?: string;
};

export const NotebookRenderer: React.FC<NotebookRendererProps> = ({
  attribute,
  labelWidth = '100px',
  label,
}) => {
  if (!attribute) {
    return null;
  }

  const { notebookId, notebookName } = attribute;

  return (
    <PropertyRow
      value={
        // @ts-ignore TODO: remove ts-ignore after rewriting NotebookLink components to TS
        <NotebookLink
          component={Link}
          notebookId={notebookId}
          notebookName={notebookName}
          title={createPageIdUrlParam({ id: notebookId, title: notebookName })}
        >
          <Icon glyph="file-code" />
          &nbsp;
          {createPageIdUrlParam({ id: notebookId, title: notebookName })}
        </NotebookLink>
      }
      labelWidth={labelWidth}
      label={label}
    />
  );
};

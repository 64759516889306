import { makeEnterpriseRoute } from '@neptune/shared/enterprise-context-business-logic';
import { AppRoute } from '@neptune/shared/routing-domain';
import { getReports } from 'common/featureFlag';

const reportsRoute: AppRoute = {
  name: 'project.reports',
  path: '/reports',
};

const reportRoute: AppRoute = {
  name: 'project.report',
  path: '/reports/:reportId',
};

const reportVersionRoute: AppRoute = {
  name: 'project.report-version',
  path: '/reports/:reportId/version/:versionId',
};

const reportDraftRoute: AppRoute = {
  name: 'project.report-draft',
  path: '/reports/:reportId/draft',
};

export const reportsRoutes = getReports()
  ? [
      reportsRoute,
      makeEnterpriseRoute(reportsRoute),
      reportRoute,
      makeEnterpriseRoute(reportRoute),
      reportVersionRoute,
      makeEnterpriseRoute(reportVersionRoute),
      reportDraftRoute,
      makeEnterpriseRoute(reportDraftRoute),
    ]
  : [];

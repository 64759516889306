import { LayoutType } from '@neptune/shared/common-domain';
import { AppState } from 'state/types';
// todo this dependency should be changed in the future to depend on currentProject explicitly, not through redux
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { getCurrentProjectIdentifier } from '@neptune/current-project-business-logic';

import { ExpandableLayoutState } from '../storage-utils';

export const getExpandableLayoutState = (state: any): ExpandableLayoutState => {
  return state.ui.expandableLayout;
};

export const getExpandableLayout = (state: AppState, id: string): LayoutType => {
  const projectIdentifier = getCurrentProjectIdentifier(state);

  return getExpandableLayoutState(state)?.[projectIdentifier]?.[id] || LayoutType.Mosaic;
};

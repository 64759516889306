import React from 'react';
import { QueryFilterSearch } from '@neptune/shared/search-domain';
import { Layout, LayoutRowProps } from '@neptune/shared/venus-ui';
import { TagsList, AddTagWithAutocompletion } from '@neptune/shared/tags-ui';
import { StringSetAttribute } from 'domain/experiment/attribute';

type EditStringSetAttributeProps = {
  attribute: StringSetAttribute;
  stringSetValues: string[];
  colorsGetter: (tag: string) => { color: string; backgroundColor: string };
  isEditable: boolean;
  tagsDataSource: QueryFilterSearch<string, string>;
  onRemove: (tags: string[]) => Promise<void>;
  onAdd: (tags: string[]) => Promise<void>;
  addTagPlaceholder: string;
  withColor?: boolean;
} & LayoutRowProps<object>;

export const EditStringSetAttribute: React.FC<EditStringSetAttributeProps> = ({
  attribute,
  colorsGetter,
  stringSetValues,
  isEditable,
  tagsDataSource,
  onRemove,
  onAdd,
  addTagPlaceholder,
  withColor,
  ...restProps
}) => {
  const handleAddTag = async (tag: string) => {
    const isTagUnique = !stringSetValues.includes(tag);

    if (tag.trim().length > 0 && isTagUnique) {
      await onAdd([tag]);
    }
  };

  const handleTagsDataSource = React.useCallback(
    (input: string) => {
      return tagsDataSource(input, attribute.attributeName);
    },
    [tagsDataSource, attribute.attributeName],
  );

  return (
    <Layout.Row wrap="wrap">
      <TagsList
        tagsList={stringSetValues}
        colorsGetter={colorsGetter}
        isEditable={isEditable}
        onTagAdd={handleAddTag}
        onTagRemove={(tag: string) => onRemove([tag])}
        withColor={withColor}
        {...restProps}
      />
      {isEditable && (
        <AddTagWithAutocompletion
          dataSource={handleTagsDataSource}
          placeholder={addTagPlaceholder}
          onTagAdd={handleAddTag}
        />
      )}
    </Layout.Row>
  );
};

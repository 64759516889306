import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import useKeyboardShortcut from 'use-keyboard-shortcut';
import {
  createCurrentRouteParamSelector,
  getCurrentRouteName,
  getCurrentRouteParams,
} from './selectors';
import { navigateTo } from './actions';

const distractionFreeSelector = createCurrentRouteParamSelector('distraction-free');
export const getDistractionFree = createSelector(distractionFreeSelector, (value) => !!value);

export const getCurrentRouteParamsWithoutDistractionFree = createSelector(
  getCurrentRouteParams,
  ({ 'distraction-free': valueToDiscard, ...rest }) => rest,
);

const SPACE = ' ';

export const useDistractionFree = () => {
  const isDistractionFree = useSelector(getDistractionFree);
  const dispatch = useDispatch();
  const routeName = useSelector(getCurrentRouteName);
  const routeParams = useSelector(getCurrentRouteParams);
  const routeParamsWithoutDistractionFree = useSelector(
    getCurrentRouteParamsWithoutDistractionFree,
  );

  const routeParamsWithDistractionFree = { ...routeParams, 'distraction-free': '1' };

  useKeyboardShortcut(
    [SPACE],
    () => {
      if (isDistractionFree) {
        dispatch(navigateTo(routeName, routeParamsWithoutDistractionFree));
      } else {
        dispatch(navigateTo(routeName, routeParamsWithDistractionFree));
      }
    },
    { repeatOnHold: false },
  );

  return {
    isDistractionFree,
    routeParams: isDistractionFree
      ? routeParamsWithoutDistractionFree
      : routeParamsWithDistractionFree,
    routeName,
  };
};

import { AttributeBasedWidget, NamespaceBasedWidget, Widget } from './core/widget';

export function inferWidgetName(widget: Widget): string {
  if (widget.name) {
    return widget.name;
  }

  return (
    (isWidgetAttributeBased(widget) && widget.attributes[0]?.name) ||
    (isWidgetNamespaceBased(widget) && widget.namespaces[0]) ||
    'Unnamed'
  );
}

export function isWidgetAttributeBased(widget: Widget): widget is AttributeBasedWidget {
  return !isWidgetNamespaceBased(widget);
}

export function isWidgetNamespaceBased(widget: Widget): widget is NamespaceBasedWidget {
  return !!widget.namespaces?.length;
}

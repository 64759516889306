import React from 'react';
import { LeaderboardContext } from '@neptune/shared/entity-leaderboard-domain';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import { getLeaderboardState } from './redux';

export const useGroups = () => {
  const { id: ID } = React.useContext(LeaderboardContext);
  const groups = useSelector((state: AppState) => getLeaderboardState(state, ID).groups);
  return { groups };
};

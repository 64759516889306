import { AttributeType } from 'domain/experiment/attribute';

export const suitableLeaderboardAttributeTypes: AttributeType[] = [
  'artifact',
  'bool',
  'datetime',
  'experimentState',
  // 'file',
  // 'fileSet',
  'float',
  'floatSeries',
  'gitRef',
  // 'imageSeries',
  'int',
  'notebookRef',
  'string',
  'stringSeries',
  'stringSet',
  // 'notSupported',
];

import React from 'react';
import { QueryFilterSearch } from '@neptune/shared/search-domain';
import { bemBlock, Layout, Modal } from '@neptune/shared/venus-ui';
import { getTextVariantByValue } from '@neptune/shared/common-util';
import { fetchStatus as FetchStatus } from 'state/fetch-status';
import LoadingIndicator from 'components/loading-indicator/LoadingIndicator';

import { AddTagWithAutocompletion } from './AddTagWithAutocompletion';
import { TagRow } from './TagRow';

import './ManageTagsModal.less';
import { KnownAttributes } from 'domain/experiment/attribute';

const block = bemBlock('manage-tags-modal');

type ManageTagsModalProps = {
  fetchStatus: FetchStatus;
  colorsGetter: (tag: string) => { color: string; backgroundColor: string };
  entries: any[];
  error?: string;
  dataSource: QueryFilterSearch<string>;
  tagsCount: Record<string, number>;
  tagsToAdd: string[];
  tagsToRemove: string[];
  addTag: (tag: string) => void;
  resetTag: (tag: string) => void;
  onToggle: (tag: string, isChecked: boolean) => void;
  onSubmit: () => void;
  onClose: () => void;
  attributeName: string;
};

export const ManageTagsModal: React.FC<ManageTagsModalProps> = ({
  fetchStatus,
  colorsGetter,
  entries,
  error,
  dataSource,
  tagsCount,
  tagsToAdd,
  tagsToRemove,
  resetTag,
  addTag,
  onToggle,
  onClose,
  onSubmit,
  attributeName,
}) => {
  const newTags = tagsToAdd.filter((tag) => !(tagsCount[tag] > 0)).reverse();
  const tagsCountEntries = Object.entries(tagsCount);

  const totalChanges = newTags.length + tagsToAdd.length + tagsToRemove.length;

  return (
    <div className={block()}>
      <LoadingIndicator className={block('loading')} fetchStatus={fetchStatus} centered />
      <Modal
        isOpen={entries.length > 0}
        onClose={onClose}
        data={{
          role: 'manage-tags-modal',
        }}
      >
        <Modal.Header
          title={`Manage ${getAttributeLabelPlural(attributeName)}`}
          onClose={onClose}
        />
        <Modal.Body className={block('body')}>
          <Layout.Column className={block('wrapper')} spacedChildren="md">
            {(newTags.length > 0 || tagsCountEntries.length > 0) && (
              <Layout.Column overflow="auto" spacedChildren="md">
                <React.Fragment>
                  {newTags.length > 0 && (
                    <React.Fragment>
                      <TagActionStatus
                        count={newTags.length}
                        plural={`new ${getAttributeLabelPlural(attributeName)} added`}
                        singular={`new ${getAttributeLabelSingular(attributeName)} added`}
                      />
                      {newTags.map((tag) => {
                        return (
                          <TagRow
                            key={tag}
                            applyCount={entries.length}
                            allCount={entries.length}
                            actionText="Remove"
                            initialCount={0}
                            tag={tag}
                            disabled
                            onChange={onToggle}
                            onAction={resetTag}
                            colors={
                              attributeName === KnownAttributes.GroupTags
                                ? colorsGetter(tag)
                                : undefined
                            }
                          />
                        );
                      })}
                      <Layout.Separator />
                    </React.Fragment>
                  )}
                  {tagsCountEntries.length > 0 && (
                    <React.Fragment>
                      <TagActionStatus
                        count={tagsCountEntries.length}
                        plural={`${getAttributeLabelPlural(attributeName)} used in selected runs`}
                        singular={`${getAttributeLabelSingular(
                          attributeName,
                        )} used in selected runs`}
                      />
                      {tagsCountEntries.map(([tag, count]) => {
                        let countAfterApply = count;

                        if (tagsToAdd.includes(tag)) {
                          countAfterApply = entries.length;
                        }

                        if (tagsToRemove.includes(tag)) {
                          countAfterApply = 0;
                        }

                        return (
                          <TagRow
                            key={tag}
                            allCount={entries.length}
                            applyCount={countAfterApply}
                            initialCount={tagsCount[tag]}
                            actionText="Reset"
                            tag={tag}
                            onChange={onToggle}
                            onAction={resetTag}
                            colors={
                              attributeName === KnownAttributes.GroupTags
                                ? colorsGetter(tag)
                                : undefined
                            }
                          />
                        );
                      })}
                      <Layout.Separator />
                    </React.Fragment>
                  )}
                </React.Fragment>
              </Layout.Column>
            )}

            <Layout.Column span="auto" spacedChildren="md" data-role="add-tag-section">
              <h2 className={block('title')}>
                Add new {getAttributeLabelSingular(attributeName)} to all selected runs
              </h2>
              <AddTagWithAutocompletion dataSource={dataSource} onTagAdd={addTag} />
              {error && <div className={block('error')}> {error} </div>}
            </Layout.Column>
          </Layout.Column>
        </Modal.Body>
        <Modal.Footer
          primaryButtonLabel="Apply"
          primaryButtonRole="confirm-button"
          primaryButtonDisabled={totalChanges === 0}
          onPrimaryButtonClick={onSubmit}
          onSecondaryButtonClick={onClose}
        />
      </Modal>
    </div>
  );
};

const TagActionStatus: React.FC<{
  count: number;
  plural: string;
  singular: string;
}> = ({ count, plural, singular }) => {
  const suffix = getTextVariantByValue(count, plural, singular, '');
  return (
    <h2 className={block('title')}>
      <span className={block('emphasis')}>{count}</span> {suffix}
    </h2>
  );
};

function getAttributeLabelPlural(attributeName: string) {
  switch (attributeName) {
    case KnownAttributes.Tags:
      return 'tags';
    case KnownAttributes.GroupTags:
      return 'group tags';
    default:
      return attributeName;
  }
}

function getAttributeLabelSingular(attributeName: string) {
  switch (attributeName) {
    case KnownAttributes.Tags:
      return 'tag';
    case KnownAttributes.GroupTags:
      return 'group tag';
    default:
      return attributeName;
  }
}

import React from 'react';
import type { FloatAttribute } from 'domain/experiment/attribute';
import { PropertyRow } from 'components/layouts/rows';
import { getReadableFileSize } from '@neptune/shared/common-util';

type SizeRendererProps = {
  attribute?: FloatAttribute;
  labelWidth?: string;
  label?: string;
  'data-name'?: string;
};

export const SizeRenderer: React.FC<SizeRendererProps> = ({
  attribute,
  labelWidth = '100px',
  label,
  'data-name': dataName,
}) => {
  if (!attribute) {
    return null;
  }

  const size = attribute.value;

  return (
    <PropertyRow
      value={getReadableFileSize(size)}
      labelWidth={labelWidth}
      label={label}
      data-name={dataName}
    />
  );
};

import React from 'react';

import { ServiceAccountRenderer } from '@neptune/shared/service-accounts-ui';
import { isServiceAccount } from '@neptune/shared/common-business-logic';

import { PersonRenderer } from './PersonRenderer';

type UserRendererContainerProps = {
  name?: string;
};

export const UserRenderer: React.FC<UserRendererContainerProps> = ({ name }) => {
  if (!name) {
    return null;
  }

  if (isServiceAccount(name)) {
    return <ServiceAccountRenderer name={name} />;
  }

  return <PersonRenderer name={name} />;
};

import { makeEnterpriseRoute } from 'router/helpers';

const usageStatisticsRoute = {
  name: 'usage-statistics',
  path: '/:organizationName/-/usage-statistics',
  onlyLoggedUser: true,
};

export const usageStatisticsRoutes = [
  usageStatisticsRoute,
  {
    ...makeEnterpriseRoute(usageStatisticsRoute),
    path: '/o/:organizationName/-/usage-statistics',
  },
];

// App
import { NotebookRefCriterion, PartialSearchCriterion } from '../search-query-model';
import { criterionHasAttributeAndOperator } from '../search-query-model-core-utils';

// Module
import { CriterionHelpers } from './types';

export function isNotebookRefCriterion(
  criterion: PartialSearchCriterion,
): criterion is NotebookRefCriterion {
  return criterionHasAttributeAndOperator(criterion) && criterion.type === 'notebookRef';
}

export const stringCriterionHelpers: CriterionHelpers<NotebookRefCriterion> = {
  getReadableValue(criterion) {
    return `"${criterion.value}"`;
  },
  getShortReadableValue(criterion) {
    return criterion.value;
  },
  isComplete(criterion) {
    return criterion.value != null;
  },
};

import { FILTERING_QUERY_PARAMS } from 'router/config';
import { makeEnterpriseRoute } from 'router/helpers';
import { storage } from 'common/storage';

const notebooksRoute = {
  name: 'project.notebooks',
  path: '/notebooks?:notebookId',
  onActivate: () => () => storage.local.setItem('lastProjectTab', 'notebooks'),
};

const metadataRoute = {
  name: 'project.metadata',
  path: '/metadata',
  onActivate: () => () => storage.local.setItem('lastProjectTab', 'metadata'),
};

const trashRoute = {
  name: 'project.trash',
  path: `/trash?${FILTERING_QUERY_PARAMS}`,
  onActivate: () => () => storage.local.setItem('lastProjectTab', 'trash'),
};

const compareNotebooksRoute = {
  name: 'project.compare-notebooks',
  path: '/compare-notebooks?:sourceNotebookId&:sourceCheckpointId&:targetNotebookId&:targetCheckpointId',
};

export const projectDashboardRoutes = [
  compareNotebooksRoute,
  makeEnterpriseRoute(compareNotebooksRoute),
  trashRoute,
  makeEnterpriseRoute(trashRoute),
  notebooksRoute,
  makeEnterpriseRoute(notebooksRoute),
  metadataRoute,
  makeEnterpriseRoute(metadataRoute),
];

export default projectDashboardRoutes;

// Libs
import React from 'react';
import PropTypes from 'prop-types';

// App
import { NormalizedLink } from '@neptune/shared/routing-ui';
import RouteProvider from 'components/providers/RouteProvider';
import { createPageIdUrlParam } from 'common/pages';

const propTypes = {
  notebookName: PropTypes.string,
  notebookId: PropTypes.string.isRequired,
  versionId: PropTypes.string,
};

const NotebookLink = ({
  notebookName,
  notebookId,
  versionId,
  ...props
}) => {
  const newRouteName = !!versionId
    ? 'project.notebook.version'
    : 'project.notebook';
  return (
    <RouteProvider>
      {(routeName, { organizationName, projectName }) => (
        <NormalizedLink
          {...props}
          routeName={newRouteName}
          routeParams={{
            organizationName,
            projectName,
            notebookId: createPageIdUrlParam({ id: notebookId, title: notebookName }),
            ...(!!versionId ? { versionId } : {}),
          }}
        />
      )}
    </RouteProvider>
  );
};

NotebookLink.propTypes = propTypes;

export default NotebookLink;

import React from 'react';
import { useSelector } from 'react-redux';

import { LegacyProjectHeader, ProjectHeader } from '@neptune/project-ui';
import { getCurrentProject } from '@neptune/current-project-business-logic';
import { getUnificationV1Enabled } from 'common/featureFlag';
import { ProjectUsersContext } from 'common/hoc/withProjectUsers';
import { AvatarsList } from '@neptune/user-management-common-feature';
import { Layout, OverlayView } from '@neptune/shared/venus-ui';
import { useProjectSectionName } from '@neptune/project-business-logic';

import { CreateNewRunContainer } from './CreateNewRunContainer';
import { useOverlaySubviews } from './useOverlaySubviews';
import { ProjectDropdownMenuContainer } from './ProjectDropdownMenuContainer';
import { ProjectTabDropdownContainer } from './ProjectTabDropdownContainer';
import { ProjectStorageBannersContainer } from './ProjectStorageBannersContainer';

export const ProjectHeaderContainer = React.forwardRef<HTMLElement>((props, ref) => {
  const project = useSelector(getCurrentProject);
  const { fetchUsers, users } = React.useContext(ProjectUsersContext);
  const {
    closeCurrentSubview,
    CurrentSubviewComponent,
    currentSubviewLabel,
    openSubview,
    subviewEntries,
  } = useOverlaySubviews(project);
  const projectSectionName = useProjectSectionName();

  React.useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!project) {
    return null;
  }

  const membersList = (
    <AvatarsList
      avatarsSize="sm"
      members={users ?? []}
      visibleAvatars={5}
      onClick={() => openSubview('people')}
    />
  );

  return (
    <>
      <ProjectStorageBannersContainer project={project} />
      {getUnificationV1Enabled() ? (
        <ProjectHeader
          createNewRun={<CreateNewRunContainer />}
          membersList={membersList}
          menu={
            <ProjectDropdownMenuContainer
              openSubview={openSubview}
              subviewEntries={subviewEntries}
              project={project}
            />
          }
          projectSectionName={projectSectionName}
          ref={ref}
        />
      ) : (
        <LegacyProjectHeader
          membersList={membersList}
          menu={
            <ProjectDropdownMenuContainer
              openSubview={openSubview}
              subviewEntries={subviewEntries}
              project={project}
            />
          }
          tabs={<ProjectTabDropdownContainer />}
          project={project}
          ref={ref}
        />
      )}
      <OverlayView
        title={currentSubviewLabel}
        children={
          CurrentSubviewComponent ? (
            <Layout.Element withPadding="xxxl">
              <CurrentSubviewComponent project={project} />
            </Layout.Element>
          ) : null
        }
        onClose={closeCurrentSubview}
        isVisible={!!CurrentSubviewComponent}
      />
    </>
  );
});

import { AttributeDefinition, StringAttribute } from 'domain/experiment/attribute';
import { Entity } from '@neptune/shared/entity-domain';

// TODO remove this once getAttributeHistory uses leaderboardClient
const FAKE_ENTITY: Entity = {
  id: '1',
  attributes: [],
  organizationId: '1',
  organizationName: 'org1',
  projectId: '1',
  projectName: 'project1',
  type: 'run',
  experimentId: '1',
};

export type GetAttributeParams = {
  projectIdentifier: string;
  experimentId: string;
  attributeDefinition: AttributeDefinition;
};

export type AttributeHistoryEntry = {
  step: string;
  entity: Entity;
};

// TODO inline with the leaderboardClient once the API is ready
export const getAttributeHistory = async (
  params: GetAttributeParams,
): Promise<AttributeHistoryEntry[]> => {
  const entity: Entity = {
    ...FAKE_ENTITY,
    experimentId: params.experimentId,
    attributes: [
      {
        attributeName: params.attributeDefinition.name,
        attributeType: params.attributeDefinition.type,
        value: 'hello!',
      } as StringAttribute,
    ],
  };
  return [{ step: 'step', entity }];
};

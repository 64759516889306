import {
  deserializeAsOptString,
  ISelectors,
  serializeToString,
  ViewNavigation,
} from '@neptune/shared/routing-business-logic';

export type DetailsRouteParams = {
  // todo rename to tab
  // which tab is active
  detailsTab?: string; // or listed exact values
  // if a tab is a dashboard, which dashboard is active
  dashboardId?: string;
  // which Entity is active
  shortId?: string;
  // todo there is a conflict between all metadata/source code
  // [all metadata | source code] - path to a namespace
  path?: string;
  // todo there is a conflict between all metadata/source code
  // a[all metadata | source code] - attribute name
  attribute?: string;
  // [all metadata | source code] name of the selected file from fileSet
  file?: string;
  // [all metadata | source code] path to selected file in a fileSet
  filePath?: string;
  // used by floatSeries preview to choose a tab
  previewTab?: string; // 'chart' | 'valueList'
};

export class RunDetailsNavigation
  extends ViewNavigation<DetailsRouteParams>
  implements ISelectors<DetailsRouteParams>
{
  selectors = {
    detailsTab: this.createParamSelector('detailsTab'),
    dashboardId: this.createParamSelector('dashboardId'),
    shortId: this.createParamSelector('shortId'),
    path: this.createParamSelector('path'),
    attribute: this.createParamSelector('attribute'),
    file: this.createParamSelector('file'),
    filePath: this.createParamSelector('filePath'),
    previewTab: this.createParamSelector('previewTab'),
  };

  readonly deserializers = {
    detailsTab: deserializeAsOptString,
    dashboardId: deserializeAsOptString,
    shortId: deserializeAsOptString,
    path: deserializeAsOptString,
    attribute: deserializeAsOptString,
    file: deserializeAsOptString,
    filePath: deserializeAsOptString,
    previewTab: deserializeAsOptString,
  };

  readonly serializers = {
    detailsTab: serializeToString,
    dashboardId: serializeToString,
    shortId: serializeToString,
    path: serializeToString,
    attribute: serializeToString,
    file: serializeToString,
    filePath: serializeToString,
    previewTab: serializeToString,
  };

  constructor(
    protected readonly routeName: string,
    protected readonly paramPrefix: string,
  ) {
    super();
  }
}

import React from 'react';

import { useAsyncValue } from '@neptune/shared/common-util';
import { bemBlock, LayoutColumn } from '@neptune/shared/venus-ui';

import { SearchInput } from '@neptune/shared/common-ui';

import { FilterSearchItem } from './FilterSearchItem';
import { FilterSearchResults } from './FilterSearchResults';
import { FilterSearchResultsWithAdditionalSearchByName } from './FilterSearchResultsWithAdditionalSearchByName';
import { FilterSearchResultsText } from './FilterSearchResultsText';
import { FilterSearchLayoutProps, FilterSearchProps } from './interfaces';
import './FilterSearch.less';

const block = bemBlock('filter-search');

const FilterSearchPlainLayout: React.FC<Omit<FilterSearchLayoutProps, 'query'>> = ({
  input,
  message,
  searchResults,
}) => (
  <>
    {input}
    {message}
    {searchResults}
  </>
);

export const FilterSearch = <T,>({
  className,
  dataSource,
  'data-role': dataRole,
  debounceTimeout,
  initialValue,
  RenderItem = FilterSearchItem,
  onKeyDown,
  onKeyUp,
  onSelect,
  placeholder = '',
  showCounters = true,
  variant = 'medium',
  withIcon,
  onSearchByNameSelected,
  renderLayout,
  HelpInfo,
}: FilterSearchProps<T>) => {
  const [query, setQuery] = React.useState('');

  const resolver = React.useCallback(() => dataSource(query), [dataSource, query]);

  const { value, error, loading } = useAsyncValue({ resolver });

  const results = value?.entries ?? [];

  React.useEffect(() => {
    setQuery(initialValue || '');
  }, [initialValue]);

  const input = (
    <SearchInput
      value={query}
      onChange={setQuery}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      debounceTimeout={dataSource.isStatic ? 0 : debounceTimeout}
      variant={variant}
      placeholder={placeholder}
      className={block('input')}
      withIcon={withIcon}
      HelpInfo={HelpInfo}
      autoFocus
    />
  );

  const message = (
    <FilterSearchResultsText
      emptyQuery={query.length === 0}
      currentCount={results.length}
      error={error}
      loading={loading}
      collectionCount={value?.collectionCount}
      showCounters={showCounters}
      disabledNoResults={!!onSearchByNameSelected}
    />
  );

  const searchResults = onSearchByNameSelected ? (
    <FilterSearchResultsWithAdditionalSearchByName
      query={query}
      results={results}
      loading={loading}
      error={error}
      onSelect={onSelect}
      onSearchByNameSelected={onSearchByNameSelected}
      RenderItem={RenderItem}
      className={block('results')}
    />
  ) : (
    <FilterSearchResults
      query={query}
      results={results}
      loading={loading}
      error={error}
      onSelect={onSelect}
      RenderItem={RenderItem}
      className={block('results')}
    />
  );

  return (
    <LayoutColumn className={block({ extra: className })} data-role={dataRole} spacedChildren="sm">
      {renderLayout === undefined ? (
        <FilterSearchPlainLayout input={input} message={message} searchResults={searchResults} />
      ) : (
        renderLayout({ input, message, searchResults, query })
      )}
    </LayoutColumn>
  );
};

import React from 'react';
import { OwnerEntry, OwnerType } from '@neptune/object-table-search-domain';
import { useAsyncValue, UseAsyncValueReturns } from '@neptune/shared/common-util';

type UsePrepareOwnerProps = {
  projectIdentifier: string;
  serviceAccountResolver: (projectIdentifier: string) => Promise<string[]>;
  usersResolver: (projectIdentifier: string) => Promise<string[]>;
};

export function usePrepareOwners({
  projectIdentifier,
  serviceAccountResolver,
  usersResolver,
}: UsePrepareOwnerProps): UseAsyncValueReturns<OwnerEntry[]> {
  const ownersResolver = React.useCallback(async (): Promise<OwnerEntry[]> => {
    const [users, serviceAccounts] = await fetchOwners(
      projectIdentifier,
      serviceAccountResolver,
      usersResolver,
    );
    return [
      ...users.map((value) => ({ value, type: OwnerType.User })),
      ...serviceAccounts.map((value) => ({ value, type: OwnerType.ServiceAccount })),
    ];
  }, [projectIdentifier, serviceAccountResolver, usersResolver]);
  return useAsyncValue({ resolver: ownersResolver });
}

export function fetchOwners(
  projectIdentifier: string,
  serviceAccountResolver: (projectIdentifier: string) => Promise<string[]>,
  usersResolver: (projectIdentifier: string) => Promise<string[]>,
): Promise<[string[], string[]]> {
  return Promise.all([
    usersResolver(projectIdentifier).catch(() => [] as string[]),
    serviceAccountResolver(projectIdentifier).catch(() => [] as string[]),
  ]);
}

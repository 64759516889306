import React from 'react';
import { Emblem, Layout, bemBlock } from '@neptune/shared/venus-ui';

type TagProps = {
  className?: string;
  colors?: { color: string; backgroundColor: string };
  tag: string;
  selectable?: boolean;
  selected?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  onRemove?: (tag: string) => void;
  title?: string;
};

const block = bemBlock('tag');

export const Tag: React.FC<TagProps> = ({
  className,
  colors,
  tag,
  selectable = false,
  selected,
  onClick,
  onRemove,
  title,
}) => {
  const handleRemove = onRemove
    ? (event: any) => {
        onRemove(tag);
        event.stopPropagation();
      }
    : undefined;

  return (
    <Layout.Element data-role="tag" data-tag={tag} title={title}>
      <Emblem
        color={colors?.backgroundColor}
        className={block({ extra: className })}
        active={selected}
        onClick={selectable && onClick ? onClick : undefined}
        onEndDetailClick={handleRemove}
        endDetailGlyph={handleRemove === undefined ? undefined : 'cancel'}
        variant="light"
        text={tag}
      />
    </Layout.Element>
  );
};

import { isNotUndefined } from 'common/tsHelpers';
import { TableViewParamsDTO } from 'generated/leaderboard-client';
import { GroupOptionsModelConverter } from './group-options/group-options-model-converter';
import { SortOptionsModelConverter } from './sort-options/sort-options-model-converter';
import { ColumnModelConverter } from './column/column-model-converters';
import { STANDARD_SORTING_OPTIONS } from './sort-options/sort-options-default';
import { TableViewParams } from './table-view-params-model';

export class TableViewParamsConverter {
  static fromApiToDomain(tableViewParams: TableViewParamsDTO): TableViewParams {
    const groupOptions = tableViewParams.groupOptions
      ? GroupOptionsModelConverter.fromApiToDomain(tableViewParams.groupOptions)
      : null;
    return {
      query: tableViewParams.query,
      experimentsOnly: tableViewParams.experimentsOnly,
      sortOptions:
        SortOptionsModelConverter.fromApiToLegacy(tableViewParams.sortOptions) ||
        STANDARD_SORTING_OPTIONS,
      columnList: tableViewParams.columnList.columns.map(
        ColumnModelConverter.columnFromApiToDomain,
      ),
      ...(groupOptions ? { groupOptions } : {}),
    };
  }

  static fromDomainToApi(tableViewParams: TableViewParams): TableViewParamsDTO {
    return {
      query: tableViewParams.query,
      experimentsOnly: tableViewParams.experimentsOnly,
      sortOptions: SortOptionsModelConverter.fromLegacyToApi(tableViewParams.sortOptions),
      columnList: {
        columns: tableViewParams.columnList
          .map(ColumnModelConverter.columnFromDomainToApi)
          .filter(isNotUndefined),
      },
      groupOptions: tableViewParams.groupOptions
        ? GroupOptionsModelConverter.fromDomainToApi(tableViewParams.groupOptions)
        : undefined,
    };
  }
}

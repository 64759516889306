// Libs
import React from 'react';
import { useSelector } from 'react-redux';

// App
import { listUsers, UserEntry } from '@neptune/shared/core-users-domain';
import { getCurrentProjectIdentifier } from '@neptune/current-project-business-logic';

type ProjectUsersContextValue = {
  fetchUsers(): void;
  isLoading: boolean;
  users: UserEntry[];
};

export const ProjectUsersContext = React.createContext<ProjectUsersContextValue>({
  fetchUsers: () => {},
  isLoading: false,
  users: [],
});

export const withProjectUsers = <P extends object>(
  ComposedComponent: React.ComponentType<P>,
): React.FC<P> => {
  return (props) => {
    const projectIdentifier = useSelector(getCurrentProjectIdentifier);
    const [users, setUsers] = React.useState<UserEntry[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);

    const fetchUsers = async () => {
      setIsLoading(true);

      try {
        const entries = await listUsers({ projectIdentifier });
        setUsers(entries);
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <ProjectUsersContext.Provider
        value={{
          fetchUsers,
          isLoading,
          users,
        }}
      >
        <ComposedComponent {...props} />
      </ProjectUsersContext.Provider>
    );
  };
};

// App
import {
  LeaderboardSortParamsDTO,
  LeaderboardSortParamsDTOSortDirectionEnum,
  LeaderboardSortParamsDTOSortFieldAggregationModeEnum,
  AttributeTypeDTO,
} from '@neptune/shared/core-apis-leaderboard-domain';
import { invertMapTyped } from 'common/tsHelpers';

// Module
import {
  LeaderboardSortingParams,
  SortDirection,
  SortOptions as LegacySortOptions,
  SortOptions,
} from './sort-options-model';
import { AggregationMode, ColumnType } from '../column/column-model';

const sortFieldTypeMapping: Partial<Record<AttributeTypeDTO, ColumnType>> = {
  [AttributeTypeDTO.Artifact]: 'artifact',
  [AttributeTypeDTO.Bool]: 'bool',
  [AttributeTypeDTO.Datetime]: 'datetime',
  [AttributeTypeDTO.ExperimentState]: 'experimentState',
  [AttributeTypeDTO.File]: 'file',
  [AttributeTypeDTO.FileSet]: 'fileSet',
  [AttributeTypeDTO.Float]: 'float',
  [AttributeTypeDTO.FloatSeries]: 'floatSeries',
  [AttributeTypeDTO.GitRef]: 'gitRef',
  [AttributeTypeDTO.Int]: 'int',
  [AttributeTypeDTO.NotebookRef]: 'notebookRef',
  [AttributeTypeDTO.String]: 'string',
  [AttributeTypeDTO.StringSeries]: 'stringSeries',
  [AttributeTypeDTO.StringSet]: 'stringSet',
};

const invertedColumnTypeMapping = invertMapTyped(sortFieldTypeMapping);

const sortFieldAggregationModeMapping: Record<
  LeaderboardSortParamsDTOSortFieldAggregationModeEnum,
  AggregationMode
> = {
  [LeaderboardSortParamsDTOSortFieldAggregationModeEnum.Auto]: 'auto',
  [LeaderboardSortParamsDTOSortFieldAggregationModeEnum.Average]: 'average',
  [LeaderboardSortParamsDTOSortFieldAggregationModeEnum.Last]: 'last',
  [LeaderboardSortParamsDTOSortFieldAggregationModeEnum.Max]: 'max',
  [LeaderboardSortParamsDTOSortFieldAggregationModeEnum.Min]: 'min',
  [LeaderboardSortParamsDTOSortFieldAggregationModeEnum.Variance]: 'variance',
};
const invertedSortFieldAggregationModeMapping = invertMapTyped(sortFieldAggregationModeMapping);

const sortDirectionMapping: Record<LeaderboardSortParamsDTOSortDirectionEnum, SortDirection> = {
  [LeaderboardSortParamsDTOSortDirectionEnum.Ascending]: 'ascending',
  [LeaderboardSortParamsDTOSortDirectionEnum.Descending]: 'descending',
};
const invertedSortDirectionMapping = invertMapTyped(sortDirectionMapping);

export abstract class SortOptionsModelConverter {
  static fromApiToLegacy(options: LeaderboardSortParamsDTO): LegacySortOptions | undefined {
    const { sortFieldAggregationMode, sortBy, sortDirection, sortFieldType } = options;

    if (!sortFieldAggregationMode || !sortBy || !sortDirection || !sortFieldType) {
      return;
    }

    return sortBy.reduce<Required<LegacySortOptions> | undefined>((acc, curr, i) => {
      if (!sortBy[i] || !sortDirection[i] || !sortFieldType[i] || !sortFieldAggregationMode[i]) {
        return acc;
      }

      const newSortDirection = this.sortDirectionFromApiToDomain(sortDirection[i]);
      const newSortFieldType = this.fieldTypeFromApiToDomain(sortFieldType[i]);
      const newSortFieldAggregationMode = this.aggregationModeFromApiToDomain(
        sortFieldAggregationMode[i],
      );

      if (!newSortDirection || !newSortFieldType || !newSortFieldAggregationMode) {
        return acc;
      }

      if (!acc) {
        acc = {
          sortBy: [],
          sortDirection: [],
          sortFieldType: [],
          sortFieldAggregationMode: [],
        };
      }

      acc.sortBy.push(sortBy[i]);
      acc.sortFieldType.push(newSortFieldType);
      acc.sortFieldAggregationMode.push(newSortFieldAggregationMode);
      acc.sortDirection.push(newSortDirection);

      return acc;
    }, undefined);
  }

  static fromLegacyToDomain(options: LegacySortOptions): LeaderboardSortingParams | undefined {
    if (!options.sortDirection || !options.sortBy || !options.sortFieldType) {
      return;
    }

    return {
      dir: options.sortDirection[0],
      sortBy: {
        name: options.sortBy[0],
        type: options.sortFieldType[0],
        aggregationMode: options.sortFieldAggregationMode?.[0],
      },
    };
  }

  static fromDomainToLegacy({ dir, sortBy }: LeaderboardSortingParams): LegacySortOptions {
    return {
      sortBy: [sortBy.name],
      sortFieldAggregationMode: sortBy.aggregationMode && [sortBy.aggregationMode],
      sortFieldType: [sortBy.type],
      sortDirection: [dir],
    };
  }

  static fromDomainToApi(options: LeaderboardSortingParams): LeaderboardSortParamsDTO {
    return this.fromLegacyToApi(this.fromDomainToLegacy(options));
  }

  static fromLegacyToApi(sortOptions: SortOptions): LeaderboardSortParamsDTO {
    const sortBy: string[] = [];
    const sortFieldType: AttributeTypeDTO[] = [];
    const sortFieldAggregationMode: LeaderboardSortParamsDTOSortFieldAggregationModeEnum[] = [];
    const sortDirection: LeaderboardSortParamsDTOSortDirectionEnum[] = [];

    sortOptions.sortFieldType?.forEach((type, i) => {
      const fieldType = this.fieldTypeFromDomainToApi(type);
      const sortByField = sortOptions.sortBy?.[i];
      const aggregationMode = this.aggregationModeFromDomainToApi(
        sortOptions.sortFieldAggregationMode?.[i],
      );
      const sortDir = this.sortDirectionFromApiToDomain(sortOptions.sortDirection?.[i]);

      if (!fieldType || !sortByField || !aggregationMode || !sortDir) {
        return;
      }

      sortFieldType.push(fieldType);
      sortBy.push(sortByField);
      sortFieldAggregationMode.push(aggregationMode);
      sortDirection.push(sortDir);
    });

    return {
      sortBy,
      sortFieldType,
      sortFieldAggregationMode,
      sortDirection,
    };
  }

  static fieldTypeFromDomainToApi(type: ColumnType) {
    return invertedColumnTypeMapping[type];
  }

  static fieldTypeFromApiToDomain(type: AttributeTypeDTO) {
    return sortFieldTypeMapping[type];
  }

  static aggregationModeFromApiToDomain(
    mode: LeaderboardSortParamsDTOSortFieldAggregationModeEnum,
  ) {
    return sortFieldAggregationModeMapping[mode];
  }

  static aggregationModeFromDomainToApi(mode?: AggregationMode) {
    if (!mode) {
      return;
    }

    return invertedSortFieldAggregationModeMapping[mode];
  }

  static sortDirectionFromApiToDomain(direction?: SortDirection) {
    if (!direction) {
      return;
    }

    return invertedSortDirectionMapping[direction];
  }
}

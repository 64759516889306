import React from 'react';
import { capitalize } from 'lodash';
import { ITetherConstraint } from 'tether';
import config from 'config';

import {
  Dropdown,
  DropdownProps,
  DropdownToggleProps,
  Layout,
  SimpleTooltip,
  Text,
} from '@neptune/shared/venus-ui';
import { DotsHButton } from '@neptune/shared/common-ui';
import { EntityType } from '@neptune/shared/entity-domain';

export type DetailsDropdownMenuProps = {
  colorInput?: React.ReactNode;
  entityType: EntityType;
  onShowDetails: () => void;
  onStop?: () => void;
  onRestore?: () => void;
  onAbort?: () => void;
  onManageTags?: () => void;
  onRemove?: () => void;
} & Pick<DropdownProps, 'constraints' | 'onCollapse' | 'onExpand' | 'className'>;

const entityTypeToTextMap: Record<EntityType, string> = {
  run: 'run',
  project: 'project',
  model: 'model',
  modelVersion: 'model version',
  report: 'report',
};

const DROPDOWN_ANIMATION: React.ComponentProps<typeof Dropdown>['menuAnimation'] = {
  direction: 'top-bottom',
};
const DROPDOWN_CONSTRAINTS: ITetherConstraint[] = [
  { to: 'scrollParent', attachment: 'together', pin: true },
];

export const DetailsDropdownMenu: React.FC<DetailsDropdownMenuProps> = ({
  colorInput,
  entityType,
  constraints,
  onShowDetails,
  onStop,
  onRestore,
  onAbort,
  onManageTags,
  onRemove,
  onExpand,
  onCollapse,
}) => {
  return (
    <Dropdown
      toggle={<Toggle />}
      attachment="top left"
      targetAttachment="bottom left"
      menuAnimation={DROPDOWN_ANIMATION}
      constraints={constraints ?? DROPDOWN_CONSTRAINTS}
      onExpand={onExpand}
      onCollapse={onCollapse}
    >
      {({ collapse }) => (
        <Dropdown.Menu data-role="details-dropdown-menu">
          <Dropdown.Item component="label" icon="color" data-item={`change-${entityType}-color`}>
            <Text>Change {entityTypeToTextMap[entityType]} color</Text>
            <Layout.Fill />
            {colorInput}
          </Dropdown.Item>
          <Dropdown.Item
            label={`${capitalize(entityTypeToTextMap[entityType])} information`}
            icon="info-circle"
            data-item={`show-${entityType}-details`}
            onClick={() => {
              onShowDetails();
              collapse();
            }}
          />
          {onStop && (
            <SimpleTooltip
              content={
                config.disableRemoteRunActions
                  ? "Remote stop isn't available in your installation yet"
                  : undefined
              }
            >
              <Dropdown.Item
                label="Remote stop"
                icon="pause-circle"
                data-item="remote-stop"
                disabled={config.disableRemoteRunActions}
                onClick={() => {
                  onStop();
                  collapse();
                }}
              />
            </SimpleTooltip>
          )}
          {onRestore && (
            <Dropdown.Item
              label="Restore"
              icon="undo"
              data-item="restore"
              onClick={() => {
                onRestore();
                collapse();
              }}
            />
          )}
          {onAbort && (
            <SimpleTooltip
              content={
                config.disableRemoteRunActions
                  ? "Remote abort isn't available in your installation yet"
                  : undefined
              }
            >
              <Dropdown.Item
                label="Remote abort"
                icon="crossed-circle"
                data-item="remote-abort"
                disabled={config.disableRemoteRunActions}
                onClick={() => {
                  onAbort();
                  collapse();
                }}
              />
            </SimpleTooltip>
          )}
          {onManageTags && (
            <Dropdown.Item
              label="Manage tags"
              icon="tag"
              data-item="manage-tags"
              onClick={() => {
                onManageTags();
                collapse();
              }}
            />
          )}
          {onRemove && (
            <SimpleTooltip
              content={
                config.withDisabledTrashing
                  ? "Trashing isn't available in your installation yet"
                  : undefined
              }
            >
              <Dropdown.Item
                label="Move to trash"
                icon="trash"
                data-item="trash"
                disabled={config.withDisabledTrashing}
                onClick={() => {
                  onRemove();
                  collapse();
                }}
              />
            </SimpleTooltip>
          )}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

const Toggle = ({ onToggle, pressed }: Pick<DropdownToggleProps, 'onToggle' | 'pressed'>) => {
  return <DotsHButton onToggle={onToggle} pressed={pressed} data-role="details-menu-button" />;
};

import React from 'react';
import { uniqWith } from 'lodash';
import { SearchQuery } from '@neptune/search-query-domain';
import { useSelector } from 'react-redux';
import { getCurrentProjectIdentifier } from '@neptune/current-project-business-logic';
import { loadState, persistState, LocalStorageState } from 'common/localStorage';

const RECENT_SEARCHES_LIMIT = 10;

type RecentSearchKeys<T extends string | SearchQuery> = T extends SearchQuery
  ? 'recentSearchQueries'
  : 'recentMemos';

type UseRecentSearchesInputParams<T extends string | SearchQuery> = {
  key: RecentSearchKeys<T>;
  namespace: string;
  getInitialState: (lsState: LocalStorageState, projectIdentifier: string) => T[] | undefined;
};

type UseRecentSearchesReturnParams<T extends string | SearchQuery> = {
  searches: T[];
  saveSearch: (search: T, validateUniqueness?: (firstText: T, secondText: T) => boolean) => void;
};

export const useRecentSearches = <T extends string | SearchQuery>({
  getInitialState,
  namespace,
  key,
}: UseRecentSearchesInputParams<T>): UseRecentSearchesReturnParams<T> => {
  const projectIdentifier = useSelector(getCurrentProjectIdentifier);
  const [searches, setSearches] = React.useState<T[]>(() => {
    const lsState = loadState();

    return getInitialState(lsState, projectIdentifier) ?? [];
  });

  const updateLSState = (updatedSearches: T[]) => {
    const prevLSState = loadState();

    persistState({
      [key]: {
        ...prevLSState?.[key],
        [namespace]: {
          ...prevLSState?.[key]?.[namespace],
          [projectIdentifier]: updatedSearches,
        },
      },
    });
  };

  const saveSearch = (
    search: T,
    validateUniqueness: (firstText: T, secondText: T) => boolean = validateStringUniqueness,
  ) => {
    const newSearches = [search, ...searches];
    const uniqueSearches = uniqWith(newSearches, validateUniqueness);

    let finalSearches = uniqueSearches;

    if (finalSearches.length > RECENT_SEARCHES_LIMIT) {
      finalSearches = uniqueSearches.slice(0, RECENT_SEARCHES_LIMIT);
    }

    setSearches(finalSearches);
    updateLSState(finalSearches);
  };

  return {
    searches,
    saveSearch,
  };
};

function validateStringUniqueness<T extends string | SearchQuery>(firstText: T, secondText: T) {
  if (typeof firstText === 'string' && typeof secondText === 'string') {
    return firstText.trim() === secondText.trim();
  }

  return false;
}

import React from 'react';
import { StringSetAttribute } from 'domain/experiment/attribute';
import { QueryFilterSearch } from '@neptune/shared/search-domain';
import { EntityChanges } from '@neptune/shared/entity-domain';
import { StringSetRenderer } from './StringSetRenderer';
import { naturalStringComparator } from '@neptune/shared/common-util';

type GroupTagsRendererProps = {
  tagsDataSource: QueryFilterSearch<string, string>;
  canModify: boolean;
  colorsGetter: (tag: string) => { color: string; backgroundColor: string };
  attribute: StringSetAttribute | undefined;
  onEntityChange: (entityUpdates: Partial<EntityChanges>) => Promise<void>;
};

export const GroupTagsRenderer = ({
  tagsDataSource,
  canModify,
  colorsGetter,
  attribute,
  onEntityChange,
}: GroupTagsRendererProps) => {
  const [stringSetValues, setStringSetValues] = React.useState<string[]>(attribute?.values ?? []);

  if (attribute === undefined) {
    return null;
  }

  const handleAddStringSetValues = async (values: string[]) => {
    setStringSetValues((prevStringSetValues) =>
      [...prevStringSetValues, ...values].sort(naturalStringComparator),
    );

    await onEntityChange({ valuesToAdd: values, path: attribute.attributeName });
  };

  const handleRemoveStringSetValues = async (values: string[]) => {
    setStringSetValues((prevStringSetValues) =>
      prevStringSetValues.filter((value) => !values.includes(value)),
    );

    await onEntityChange({ valuesToRemove: values, path: attribute.attributeName });
  };

  return (
    <StringSetRenderer
      attribute={attribute}
      stringSetValues={stringSetValues}
      addValuePlaceholder="Add group tag"
      colorsGetter={colorsGetter}
      noValuesText="No group tags"
      label="Group tags"
      onAdd={handleAddStringSetValues}
      onRemove={handleRemoveStringSetValues}
      tagsDataSource={tagsDataSource}
      canModify={canModify}
      data-name="entity-group-tags"
    />
  );
};

// Generated from NQLParser.g4 by ANTLR 4.8
// jshint ignore: start
import antlr4 from 'antlr4/index';

var grammarFileName = "NQLParser.g4";


var serializedATN = ["\u0003\u608b\ua72a\u8133\ub9ed\u417c\u3be7\u7786\u5964",
    "\u0003\u0016w\u0004\u0002\t\u0002\u0004\u0003\t\u0003\u0004\u0004\t",
    "\u0004\u0004\u0005\t\u0005\u0004\u0006\t\u0006\u0004\u0007\t\u0007\u0004",
    "\b\t\b\u0004\t\t\t\u0004\n\t\n\u0003\u0002\u0003\u0002\u0003\u0002\u0003",
    "\u0002\u0005\u0002\u0019\n\u0002\u0003\u0003\u0003\u0003\u0003\u0003",
    "\u0003\u0003\u0003\u0003\u0003\u0003\u0007\u0003!\n\u0003\f\u0003\u000e",
    "\u0003$\u000b\u0003\u0003\u0004\u0003\u0004\u0003\u0004\u0003\u0004",
    "\u0003\u0004\u0003\u0004\u0007\u0004,\n\u0004\f\u0004\u000e\u0004/\u000b",
    "\u0004\u0003\u0005\u0003\u0005\u0003\u0005\u0003\u0005\u0003\u0005\u0003",
    "\u0005\u0003\u0005\u0003\u0005\u0003\u0005\u0003\u0005\u0003\u0005\u0003",
    "\u0005\u0005\u0005=\n\u0005\u0003\u0006\u0003\u0006\u0003\u0006\u0003",
    "\u0006\u0003\u0006\u0003\u0006\u0003\u0006\u0003\u0006\u0003\u0006\u0003",
    "\u0006\u0003\u0006\u0003\u0006\u0003\u0006\u0003\u0006\u0003\u0006\u0003",
    "\u0006\u0003\u0006\u0003\u0006\u0003\u0006\u0003\u0006\u0003\u0006\u0003",
    "\u0006\u0003\u0006\u0003\u0006\u0003\u0006\u0003\u0006\u0003\u0006\u0003",
    "\u0006\u0003\u0006\u0005\u0006\\\n\u0006\u0003\u0007\u0003\u0007\u0005",
    "\u0007`\n\u0007\u0003\b\u0003\b\u0005\bd\n\b\u0003\t\u0003\t\u0003\t",
    "\u0003\t\u0003\t\u0003\t\u0003\t\u0003\t\u0003\t\u0003\t\u0003\t\u0005",
    "\tq\n\t\u0003\n\u0003\n\u0005\nu\n\n\u0003\n\u0002\u0004\u0004\u0006",
    "\u000b\u0002\u0004\u0006\b\n\f\u000e\u0010\u0012\u0002\u0002\u0002~",
    "\u0002\u0018\u0003\u0002\u0002\u0002\u0004\u001a\u0003\u0002\u0002\u0002",
    "\u0006%\u0003\u0002\u0002\u0002\b<\u0003\u0002\u0002\u0002\n[\u0003",
    "\u0002\u0002\u0002\f_\u0003\u0002\u0002\u0002\u000ec\u0003\u0002\u0002",
    "\u0002\u0010p\u0003\u0002\u0002\u0002\u0012t\u0003\u0002\u0002\u0002",
    "\u0014\u0015\u0005\u0004\u0003\u0002\u0015\u0016\u0007\u0002\u0002\u0003",
    "\u0016\u0019\u0003\u0002\u0002\u0002\u0017\u0019\u0007\u0002\u0002\u0003",
    "\u0018\u0014\u0003\u0002\u0002\u0002\u0018\u0017\u0003\u0002\u0002\u0002",
    "\u0019\u0003\u0003\u0002\u0002\u0002\u001a\u001b\b\u0003\u0001\u0002",
    "\u001b\u001c\u0005\u0006\u0004\u0002\u001c\"\u0003\u0002\u0002\u0002",
    "\u001d\u001e\f\u0004\u0002\u0002\u001e\u001f\u0007\u0004\u0002\u0002",
    "\u001f!\u0005\u0006\u0004\u0002 \u001d\u0003\u0002\u0002\u0002!$\u0003",
    "\u0002\u0002\u0002\" \u0003\u0002\u0002\u0002\"#\u0003\u0002\u0002\u0002",
    "#\u0005\u0003\u0002\u0002\u0002$\"\u0003\u0002\u0002\u0002%&\b\u0004",
    "\u0001\u0002&\'\u0005\b\u0005\u0002\'-\u0003\u0002\u0002\u0002()\f\u0004",
    "\u0002\u0002)*\u0007\u0003\u0002\u0002*,\u0005\b\u0005\u0002+(\u0003",
    "\u0002\u0002\u0002,/\u0003\u0002\u0002\u0002-+\u0003\u0002\u0002\u0002",
    "-.\u0003\u0002\u0002\u0002.\u0007\u0003\u0002\u0002\u0002/-\u0003\u0002",
    "\u0002\u000201\u0007\u0005\u0002\u00021=\u0005\b\u0005\u00022=\u0005",
    "\n\u0006\u00023=\u0005\u000e\b\u000245\u0005\u0010\t\u000256\u0005\f",
    "\u0007\u000267\u0007\u0007\u0002\u00027=\u0003\u0002\u0002\u000289\u0007",
    "\u000f\u0002\u00029:\u0005\u0004\u0003\u0002:;\u0007\u0010\u0002\u0002",
    ";=\u0003\u0002\u0002\u0002<0\u0003\u0002\u0002\u0002<2\u0003\u0002\u0002",
    "\u0002<3\u0003\u0002\u0002\u0002<4\u0003\u0002\u0002\u0002<8\u0003\u0002",
    "\u0002\u0002=\t\u0003\u0002\u0002\u0002>?\u0005\u0010\t\u0002?@\u0007",
    "\b\u0002\u0002@A\u0005\u000e\b\u0002A\\\u0003\u0002\u0002\u0002BC\u0005",
    "\u0010\t\u0002CD\u0007\t\u0002\u0002DE\u0005\u000e\b\u0002E\\\u0003",
    "\u0002\u0002\u0002FG\u0005\u0010\t\u0002GH\u0007\n\u0002\u0002HI\u0005",
    "\u000e\b\u0002I\\\u0003\u0002\u0002\u0002JK\u0005\u0010\t\u0002KL\u0007",
    "\u000b\u0002\u0002LM\u0005\u000e\b\u0002M\\\u0003\u0002\u0002\u0002",
    "NO\u0005\u0010\t\u0002OP\u0007\f\u0002\u0002PQ\u0005\u000e\b\u0002Q",
    "\\\u0003\u0002\u0002\u0002RS\u0005\u0010\t\u0002ST\u0007\r\u0002\u0002",
    "TU\u0005\u000e\b\u0002U\\\u0003\u0002\u0002\u0002VW\u0005\u0010\t\u0002",
    "WX\u0005\f\u0007\u0002XY\u0007\u0006\u0002\u0002YZ\u0005\u000e\b\u0002",
    "Z\\\u0003\u0002\u0002\u0002[>\u0003\u0002\u0002\u0002[B\u0003\u0002",
    "\u0002\u0002[F\u0003\u0002\u0002\u0002[J\u0003\u0002\u0002\u0002[N\u0003",
    "\u0002\u0002\u0002[R\u0003\u0002\u0002\u0002[V\u0003\u0002\u0002\u0002",
    "\\\u000b\u0003\u0002\u0002\u0002]`\u0007\u0005\u0002\u0002^`\u0003\u0002",
    "\u0002\u0002_]\u0003\u0002\u0002\u0002_^\u0003\u0002\u0002\u0002`\r",
    "\u0003\u0002\u0002\u0002ad\u0007\u0011\u0002\u0002bd\u0007\u0013\u0002",
    "\u0002ca\u0003\u0002\u0002\u0002cb\u0003\u0002\u0002\u0002d\u000f\u0003",
    "\u0002\u0002\u0002ef\u0005\u0012\n\u0002fg\u0007\u000e\u0002\u0002g",
    "h\u0007\u0011\u0002\u0002hq\u0003\u0002\u0002\u0002ij\u0007\u0011\u0002",
    "\u0002jk\u0007\u000f\u0002\u0002kl\u0005\u0012\n\u0002lm\u0007\u000e",
    "\u0002\u0002mn\u0007\u0011\u0002\u0002no\u0007\u0010\u0002\u0002oq\u0003",
    "\u0002\u0002\u0002pe\u0003\u0002\u0002\u0002pi\u0003\u0002\u0002\u0002",
    "q\u0011\u0003\u0002\u0002\u0002ru\u0007\u0011\u0002\u0002su\u0007\u0014",
    "\u0002\u0002tr\u0003\u0002\u0002\u0002ts\u0003\u0002\u0002\u0002u\u0013",
    "\u0003\u0002\u0002\u0002\u000b\u0018\"-<[_cpt"].join("");


var atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

var decisionsToDFA = atn.decisionToState.map( function(ds, index) { return new antlr4.dfa.DFA(ds, index); });

var sharedContextCache = new antlr4.PredictionContextCache();

var literalNames = [ null, null, null, null, null, null, "'>'", "'>='", 
                     "'<'", "'<='", null, "'!='", "':'", "'('", "')'" ];

var symbolicNames = [ null, "AND", "OR", "NOT", "CONTAINS", "EXISTS", "GT", 
                      "GE", "LT", "LE", "EQ", "NE", "COLON", "LPAREN", "RPAREN", 
                      "SIMPLE_STRING", "WS", "DOUBLE_QUOTED_STRING", "BACK_QUOTED_STRING", 
                      "BEGIN_DOUBLE_QUOTE", "BEGIN_BACK_QUOTE" ];

var ruleNames =  [ "parse", "logicalExprP1", "logicalExprP2", "logicalExprP3", 
                   "relationExpr", "maybeNot", "string", "identifier", "stringIdentifier" ];

function NQLParser (input) {
	antlr4.Parser.call(this, input);
    this._interp = new antlr4.atn.ParserATNSimulator(this, atn, decisionsToDFA, sharedContextCache);
    this.ruleNames = ruleNames;
    this.literalNames = literalNames;
    this.symbolicNames = symbolicNames;
    return this;
}

NQLParser.prototype = Object.create(antlr4.Parser.prototype);
NQLParser.prototype.constructor = NQLParser;

Object.defineProperty(NQLParser.prototype, "atn", {
	get : function() {
		return atn;
	}
});

NQLParser.EOF = antlr4.Token.EOF;
NQLParser.AND = 1;
NQLParser.OR = 2;
NQLParser.NOT = 3;
NQLParser.CONTAINS = 4;
NQLParser.EXISTS = 5;
NQLParser.GT = 6;
NQLParser.GE = 7;
NQLParser.LT = 8;
NQLParser.LE = 9;
NQLParser.EQ = 10;
NQLParser.NE = 11;
NQLParser.COLON = 12;
NQLParser.LPAREN = 13;
NQLParser.RPAREN = 14;
NQLParser.SIMPLE_STRING = 15;
NQLParser.WS = 16;
NQLParser.DOUBLE_QUOTED_STRING = 17;
NQLParser.BACK_QUOTED_STRING = 18;
NQLParser.BEGIN_DOUBLE_QUOTE = 19;
NQLParser.BEGIN_BACK_QUOTE = 20;

NQLParser.RULE_parse = 0;
NQLParser.RULE_logicalExprP1 = 1;
NQLParser.RULE_logicalExprP2 = 2;
NQLParser.RULE_logicalExprP3 = 3;
NQLParser.RULE_relationExpr = 4;
NQLParser.RULE_maybeNot = 5;
NQLParser.RULE_string = 6;
NQLParser.RULE_identifier = 7;
NQLParser.RULE_stringIdentifier = 8;


function ParseContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = NQLParser.RULE_parse;
    return this;
}

ParseContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ParseContext.prototype.constructor = ParseContext;



ParseContext.prototype.copyFrom = function(ctx) {
    antlr4.ParserRuleContext.prototype.copyFrom.call(this, ctx);
};


function QueryContext(parser, ctx) {
	ParseContext.call(this, parser);
    this.expr = null; // LogicalExprP1Context;
    ParseContext.prototype.copyFrom.call(this, ctx);
    return this;
}

QueryContext.prototype = Object.create(ParseContext.prototype);
QueryContext.prototype.constructor = QueryContext;

NQLParser.QueryContext = QueryContext;

QueryContext.prototype.EOF = function() {
    return this.getToken(NQLParser.EOF, 0);
};

QueryContext.prototype.logicalExprP1 = function() {
    return this.getTypedRuleContext(LogicalExprP1Context,0);
};

function EmptyContext(parser, ctx) {
	ParseContext.call(this, parser);
    ParseContext.prototype.copyFrom.call(this, ctx);
    return this;
}

EmptyContext.prototype = Object.create(ParseContext.prototype);
EmptyContext.prototype.constructor = EmptyContext;

NQLParser.EmptyContext = EmptyContext;

EmptyContext.prototype.EOF = function() {
    return this.getToken(NQLParser.EOF, 0);
};


NQLParser.ParseContext = ParseContext;

NQLParser.prototype.parse = function() {

    var localctx = new ParseContext(this, this._ctx, this.state);
    this.enterRule(localctx, 0, NQLParser.RULE_parse);
    try {
        this.state = 22;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case NQLParser.NOT:
        case NQLParser.LPAREN:
        case NQLParser.SIMPLE_STRING:
        case NQLParser.DOUBLE_QUOTED_STRING:
        case NQLParser.BACK_QUOTED_STRING:
            localctx = new QueryContext(this, localctx);
            this.enterOuterAlt(localctx, 1);
            this.state = 18;
            localctx.expr = this.logicalExprP1(0);
            this.state = 19;
            this.match(NQLParser.EOF);
            break;
        case NQLParser.EOF:
            localctx = new EmptyContext(this, localctx);
            this.enterOuterAlt(localctx, 2);
            this.state = 21;
            this.match(NQLParser.EOF);
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function LogicalExprP1Context(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = NQLParser.RULE_logicalExprP1;
    return this;
}

LogicalExprP1Context.prototype = Object.create(antlr4.ParserRuleContext.prototype);
LogicalExprP1Context.prototype.constructor = LogicalExprP1Context;



LogicalExprP1Context.prototype.copyFrom = function(ctx) {
    antlr4.ParserRuleContext.prototype.copyFrom.call(this, ctx);
};

function SingleLogicalExprP1Context(parser, ctx) {
	LogicalExprP1Context.call(this, parser);
    this.expr = null; // LogicalExprP2Context;
    LogicalExprP1Context.prototype.copyFrom.call(this, ctx);
    return this;
}

SingleLogicalExprP1Context.prototype = Object.create(LogicalExprP1Context.prototype);
SingleLogicalExprP1Context.prototype.constructor = SingleLogicalExprP1Context;

NQLParser.SingleLogicalExprP1Context = SingleLogicalExprP1Context;

SingleLogicalExprP1Context.prototype.logicalExprP2 = function() {
    return this.getTypedRuleContext(LogicalExprP2Context,0);
};

function OrLogicalExprP1Context(parser, ctx) {
	LogicalExprP1Context.call(this, parser);
    this.left = null; // LogicalExprP1Context;
    this.right = null; // LogicalExprP2Context;
    LogicalExprP1Context.prototype.copyFrom.call(this, ctx);
    return this;
}

OrLogicalExprP1Context.prototype = Object.create(LogicalExprP1Context.prototype);
OrLogicalExprP1Context.prototype.constructor = OrLogicalExprP1Context;

NQLParser.OrLogicalExprP1Context = OrLogicalExprP1Context;

OrLogicalExprP1Context.prototype.OR = function() {
    return this.getToken(NQLParser.OR, 0);
};

OrLogicalExprP1Context.prototype.logicalExprP1 = function() {
    return this.getTypedRuleContext(LogicalExprP1Context,0);
};

OrLogicalExprP1Context.prototype.logicalExprP2 = function() {
    return this.getTypedRuleContext(LogicalExprP2Context,0);
};


NQLParser.prototype.logicalExprP1 = function(_p) {
	if(_p===undefined) {
	    _p = 0;
	}
    var _parentctx = this._ctx;
    var _parentState = this.state;
    var localctx = new LogicalExprP1Context(this, this._ctx, _parentState);
    var _prevctx = localctx;
    var _startState = 2;
    this.enterRecursionRule(localctx, 2, NQLParser.RULE_logicalExprP1, _p);
    try {
        this.enterOuterAlt(localctx, 1);
        localctx = new SingleLogicalExprP1Context(this, localctx);
        this._ctx = localctx;
        _prevctx = localctx;

        this.state = 25;
        localctx.expr = this.logicalExprP2(0);
        this._ctx.stop = this._input.LT(-1);
        this.state = 32;
        this._errHandler.sync(this);
        var _alt = this._interp.adaptivePredict(this._input,1,this._ctx)
        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
            if(_alt===1) {
                if(this._parseListeners!==null) {
                    this.triggerExitRuleEvent();
                }
                _prevctx = localctx;
                localctx = new OrLogicalExprP1Context(this, new LogicalExprP1Context(this, _parentctx, _parentState));
                localctx.left = _prevctx;
                this.pushNewRecursionContext(localctx, _startState, NQLParser.RULE_logicalExprP1);
                this.state = 27;
                if (!( this.precpred(this._ctx, 2))) {
                    throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 2)");
                }
                this.state = 28;
                this.match(NQLParser.OR);
                this.state = 29;
                localctx.right = this.logicalExprP2(0); 
            }
            this.state = 34;
            this._errHandler.sync(this);
            _alt = this._interp.adaptivePredict(this._input,1,this._ctx);
        }

    } catch( error) {
        if(error instanceof antlr4.error.RecognitionException) {
	        localctx.exception = error;
	        this._errHandler.reportError(this, error);
	        this._errHandler.recover(this, error);
	    } else {
	    	throw error;
	    }
    } finally {
        this.unrollRecursionContexts(_parentctx)
    }
    return localctx;
};


function LogicalExprP2Context(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = NQLParser.RULE_logicalExprP2;
    return this;
}

LogicalExprP2Context.prototype = Object.create(antlr4.ParserRuleContext.prototype);
LogicalExprP2Context.prototype.constructor = LogicalExprP2Context;



LogicalExprP2Context.prototype.copyFrom = function(ctx) {
    antlr4.ParserRuleContext.prototype.copyFrom.call(this, ctx);
};

function SingleLogicalExprP2Context(parser, ctx) {
	LogicalExprP2Context.call(this, parser);
    this.expr = null; // LogicalExprP3Context;
    LogicalExprP2Context.prototype.copyFrom.call(this, ctx);
    return this;
}

SingleLogicalExprP2Context.prototype = Object.create(LogicalExprP2Context.prototype);
SingleLogicalExprP2Context.prototype.constructor = SingleLogicalExprP2Context;

NQLParser.SingleLogicalExprP2Context = SingleLogicalExprP2Context;

SingleLogicalExprP2Context.prototype.logicalExprP3 = function() {
    return this.getTypedRuleContext(LogicalExprP3Context,0);
};

function AndLogicalExprP2Context(parser, ctx) {
	LogicalExprP2Context.call(this, parser);
    this.left = null; // LogicalExprP2Context;
    this.right = null; // LogicalExprP3Context;
    LogicalExprP2Context.prototype.copyFrom.call(this, ctx);
    return this;
}

AndLogicalExprP2Context.prototype = Object.create(LogicalExprP2Context.prototype);
AndLogicalExprP2Context.prototype.constructor = AndLogicalExprP2Context;

NQLParser.AndLogicalExprP2Context = AndLogicalExprP2Context;

AndLogicalExprP2Context.prototype.AND = function() {
    return this.getToken(NQLParser.AND, 0);
};

AndLogicalExprP2Context.prototype.logicalExprP2 = function() {
    return this.getTypedRuleContext(LogicalExprP2Context,0);
};

AndLogicalExprP2Context.prototype.logicalExprP3 = function() {
    return this.getTypedRuleContext(LogicalExprP3Context,0);
};


NQLParser.prototype.logicalExprP2 = function(_p) {
	if(_p===undefined) {
	    _p = 0;
	}
    var _parentctx = this._ctx;
    var _parentState = this.state;
    var localctx = new LogicalExprP2Context(this, this._ctx, _parentState);
    var _prevctx = localctx;
    var _startState = 4;
    this.enterRecursionRule(localctx, 4, NQLParser.RULE_logicalExprP2, _p);
    try {
        this.enterOuterAlt(localctx, 1);
        localctx = new SingleLogicalExprP2Context(this, localctx);
        this._ctx = localctx;
        _prevctx = localctx;

        this.state = 36;
        localctx.expr = this.logicalExprP3();
        this._ctx.stop = this._input.LT(-1);
        this.state = 43;
        this._errHandler.sync(this);
        var _alt = this._interp.adaptivePredict(this._input,2,this._ctx)
        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
            if(_alt===1) {
                if(this._parseListeners!==null) {
                    this.triggerExitRuleEvent();
                }
                _prevctx = localctx;
                localctx = new AndLogicalExprP2Context(this, new LogicalExprP2Context(this, _parentctx, _parentState));
                localctx.left = _prevctx;
                this.pushNewRecursionContext(localctx, _startState, NQLParser.RULE_logicalExprP2);
                this.state = 38;
                if (!( this.precpred(this._ctx, 2))) {
                    throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 2)");
                }
                this.state = 39;
                this.match(NQLParser.AND);
                this.state = 40;
                localctx.right = this.logicalExprP3(); 
            }
            this.state = 45;
            this._errHandler.sync(this);
            _alt = this._interp.adaptivePredict(this._input,2,this._ctx);
        }

    } catch( error) {
        if(error instanceof antlr4.error.RecognitionException) {
	        localctx.exception = error;
	        this._errHandler.reportError(this, error);
	        this._errHandler.recover(this, error);
	    } else {
	    	throw error;
	    }
    } finally {
        this.unrollRecursionContexts(_parentctx)
    }
    return localctx;
};


function LogicalExprP3Context(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = NQLParser.RULE_logicalExprP3;
    return this;
}

LogicalExprP3Context.prototype = Object.create(antlr4.ParserRuleContext.prototype);
LogicalExprP3Context.prototype.constructor = LogicalExprP3Context;



LogicalExprP3Context.prototype.copyFrom = function(ctx) {
    antlr4.ParserRuleContext.prototype.copyFrom.call(this, ctx);
};


function ParenthesesLogicalExprP3Context(parser, ctx) {
	LogicalExprP3Context.call(this, parser);
    this.expr = null; // LogicalExprP1Context;
    LogicalExprP3Context.prototype.copyFrom.call(this, ctx);
    return this;
}

ParenthesesLogicalExprP3Context.prototype = Object.create(LogicalExprP3Context.prototype);
ParenthesesLogicalExprP3Context.prototype.constructor = ParenthesesLogicalExprP3Context;

NQLParser.ParenthesesLogicalExprP3Context = ParenthesesLogicalExprP3Context;

ParenthesesLogicalExprP3Context.prototype.LPAREN = function() {
    return this.getToken(NQLParser.LPAREN, 0);
};

ParenthesesLogicalExprP3Context.prototype.RPAREN = function() {
    return this.getToken(NQLParser.RPAREN, 0);
};

ParenthesesLogicalExprP3Context.prototype.logicalExprP1 = function() {
    return this.getTypedRuleContext(LogicalExprP1Context,0);
};

function SearchTermLogicalExprP3Context(parser, ctx) {
	LogicalExprP3Context.call(this, parser);
    this.searchTerm = null; // StringContext;
    LogicalExprP3Context.prototype.copyFrom.call(this, ctx);
    return this;
}

SearchTermLogicalExprP3Context.prototype = Object.create(LogicalExprP3Context.prototype);
SearchTermLogicalExprP3Context.prototype.constructor = SearchTermLogicalExprP3Context;

NQLParser.SearchTermLogicalExprP3Context = SearchTermLogicalExprP3Context;

SearchTermLogicalExprP3Context.prototype.string = function() {
    return this.getTypedRuleContext(StringContext,0);
};

function RelationLogicalExprP3Context(parser, ctx) {
	LogicalExprP3Context.call(this, parser);
    this.expr = null; // RelationExprContext;
    LogicalExprP3Context.prototype.copyFrom.call(this, ctx);
    return this;
}

RelationLogicalExprP3Context.prototype = Object.create(LogicalExprP3Context.prototype);
RelationLogicalExprP3Context.prototype.constructor = RelationLogicalExprP3Context;

NQLParser.RelationLogicalExprP3Context = RelationLogicalExprP3Context;

RelationLogicalExprP3Context.prototype.relationExpr = function() {
    return this.getTypedRuleContext(RelationExprContext,0);
};

function ExistsLogicalExprP3Context(parser, ctx) {
	LogicalExprP3Context.call(this, parser);
    this.id = null; // IdentifierContext;
    LogicalExprP3Context.prototype.copyFrom.call(this, ctx);
    return this;
}

ExistsLogicalExprP3Context.prototype = Object.create(LogicalExprP3Context.prototype);
ExistsLogicalExprP3Context.prototype.constructor = ExistsLogicalExprP3Context;

NQLParser.ExistsLogicalExprP3Context = ExistsLogicalExprP3Context;

ExistsLogicalExprP3Context.prototype.maybeNot = function() {
    return this.getTypedRuleContext(MaybeNotContext,0);
};

ExistsLogicalExprP3Context.prototype.EXISTS = function() {
    return this.getToken(NQLParser.EXISTS, 0);
};

ExistsLogicalExprP3Context.prototype.identifier = function() {
    return this.getTypedRuleContext(IdentifierContext,0);
};

function NotLogicalExprP3Context(parser, ctx) {
	LogicalExprP3Context.call(this, parser);
    this.expr = null; // LogicalExprP3Context;
    LogicalExprP3Context.prototype.copyFrom.call(this, ctx);
    return this;
}

NotLogicalExprP3Context.prototype = Object.create(LogicalExprP3Context.prototype);
NotLogicalExprP3Context.prototype.constructor = NotLogicalExprP3Context;

NQLParser.NotLogicalExprP3Context = NotLogicalExprP3Context;

NotLogicalExprP3Context.prototype.NOT = function() {
    return this.getToken(NQLParser.NOT, 0);
};

NotLogicalExprP3Context.prototype.logicalExprP3 = function() {
    return this.getTypedRuleContext(LogicalExprP3Context,0);
};


NQLParser.LogicalExprP3Context = LogicalExprP3Context;

NQLParser.prototype.logicalExprP3 = function() {

    var localctx = new LogicalExprP3Context(this, this._ctx, this.state);
    this.enterRule(localctx, 6, NQLParser.RULE_logicalExprP3);
    try {
        this.state = 58;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,3,this._ctx);
        switch(la_) {
        case 1:
            localctx = new NotLogicalExprP3Context(this, localctx);
            this.enterOuterAlt(localctx, 1);
            this.state = 46;
            this.match(NQLParser.NOT);
            this.state = 47;
            localctx.expr = this.logicalExprP3();
            break;

        case 2:
            localctx = new RelationLogicalExprP3Context(this, localctx);
            this.enterOuterAlt(localctx, 2);
            this.state = 48;
            localctx.expr = this.relationExpr();
            break;

        case 3:
            localctx = new SearchTermLogicalExprP3Context(this, localctx);
            this.enterOuterAlt(localctx, 3);
            this.state = 49;
            localctx.searchTerm = this.string();
            break;

        case 4:
            localctx = new ExistsLogicalExprP3Context(this, localctx);
            this.enterOuterAlt(localctx, 4);
            this.state = 50;
            localctx.id = this.identifier();
            this.state = 51;
            this.maybeNot();
            this.state = 52;
            this.match(NQLParser.EXISTS);
            break;

        case 5:
            localctx = new ParenthesesLogicalExprP3Context(this, localctx);
            this.enterOuterAlt(localctx, 5);
            this.state = 54;
            this.match(NQLParser.LPAREN);
            this.state = 55;
            localctx.expr = this.logicalExprP1(0);
            this.state = 56;
            this.match(NQLParser.RPAREN);
            break;

        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function RelationExprContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = NQLParser.RULE_relationExpr;
    return this;
}

RelationExprContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
RelationExprContext.prototype.constructor = RelationExprContext;



RelationExprContext.prototype.copyFrom = function(ctx) {
    antlr4.ParserRuleContext.prototype.copyFrom.call(this, ctx);
};


function NeRelationExprContext(parser, ctx) {
	RelationExprContext.call(this, parser);
    this.left = null; // IdentifierContext;
    this.right = null; // StringContext;
    RelationExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

NeRelationExprContext.prototype = Object.create(RelationExprContext.prototype);
NeRelationExprContext.prototype.constructor = NeRelationExprContext;

NQLParser.NeRelationExprContext = NeRelationExprContext;

NeRelationExprContext.prototype.NE = function() {
    return this.getToken(NQLParser.NE, 0);
};

NeRelationExprContext.prototype.identifier = function() {
    return this.getTypedRuleContext(IdentifierContext,0);
};

NeRelationExprContext.prototype.string = function() {
    return this.getTypedRuleContext(StringContext,0);
};

function LtRelationExprContext(parser, ctx) {
	RelationExprContext.call(this, parser);
    this.left = null; // IdentifierContext;
    this.right = null; // StringContext;
    RelationExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

LtRelationExprContext.prototype = Object.create(RelationExprContext.prototype);
LtRelationExprContext.prototype.constructor = LtRelationExprContext;

NQLParser.LtRelationExprContext = LtRelationExprContext;

LtRelationExprContext.prototype.LT = function() {
    return this.getToken(NQLParser.LT, 0);
};

LtRelationExprContext.prototype.identifier = function() {
    return this.getTypedRuleContext(IdentifierContext,0);
};

LtRelationExprContext.prototype.string = function() {
    return this.getTypedRuleContext(StringContext,0);
};

function ContainsRelationExprContext(parser, ctx) {
	RelationExprContext.call(this, parser);
    this.left = null; // IdentifierContext;
    this.right = null; // StringContext;
    RelationExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

ContainsRelationExprContext.prototype = Object.create(RelationExprContext.prototype);
ContainsRelationExprContext.prototype.constructor = ContainsRelationExprContext;

NQLParser.ContainsRelationExprContext = ContainsRelationExprContext;

ContainsRelationExprContext.prototype.maybeNot = function() {
    return this.getTypedRuleContext(MaybeNotContext,0);
};

ContainsRelationExprContext.prototype.CONTAINS = function() {
    return this.getToken(NQLParser.CONTAINS, 0);
};

ContainsRelationExprContext.prototype.identifier = function() {
    return this.getTypedRuleContext(IdentifierContext,0);
};

ContainsRelationExprContext.prototype.string = function() {
    return this.getTypedRuleContext(StringContext,0);
};

function GeRelationExprContext(parser, ctx) {
	RelationExprContext.call(this, parser);
    this.left = null; // IdentifierContext;
    this.right = null; // StringContext;
    RelationExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

GeRelationExprContext.prototype = Object.create(RelationExprContext.prototype);
GeRelationExprContext.prototype.constructor = GeRelationExprContext;

NQLParser.GeRelationExprContext = GeRelationExprContext;

GeRelationExprContext.prototype.GE = function() {
    return this.getToken(NQLParser.GE, 0);
};

GeRelationExprContext.prototype.identifier = function() {
    return this.getTypedRuleContext(IdentifierContext,0);
};

GeRelationExprContext.prototype.string = function() {
    return this.getTypedRuleContext(StringContext,0);
};

function LeRelationExprContext(parser, ctx) {
	RelationExprContext.call(this, parser);
    this.left = null; // IdentifierContext;
    this.right = null; // StringContext;
    RelationExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

LeRelationExprContext.prototype = Object.create(RelationExprContext.prototype);
LeRelationExprContext.prototype.constructor = LeRelationExprContext;

NQLParser.LeRelationExprContext = LeRelationExprContext;

LeRelationExprContext.prototype.LE = function() {
    return this.getToken(NQLParser.LE, 0);
};

LeRelationExprContext.prototype.identifier = function() {
    return this.getTypedRuleContext(IdentifierContext,0);
};

LeRelationExprContext.prototype.string = function() {
    return this.getTypedRuleContext(StringContext,0);
};

function GtRelationExprContext(parser, ctx) {
	RelationExprContext.call(this, parser);
    this.left = null; // IdentifierContext;
    this.right = null; // StringContext;
    RelationExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

GtRelationExprContext.prototype = Object.create(RelationExprContext.prototype);
GtRelationExprContext.prototype.constructor = GtRelationExprContext;

NQLParser.GtRelationExprContext = GtRelationExprContext;

GtRelationExprContext.prototype.GT = function() {
    return this.getToken(NQLParser.GT, 0);
};

GtRelationExprContext.prototype.identifier = function() {
    return this.getTypedRuleContext(IdentifierContext,0);
};

GtRelationExprContext.prototype.string = function() {
    return this.getTypedRuleContext(StringContext,0);
};

function EqRelationExprContext(parser, ctx) {
	RelationExprContext.call(this, parser);
    this.left = null; // IdentifierContext;
    this.right = null; // StringContext;
    RelationExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

EqRelationExprContext.prototype = Object.create(RelationExprContext.prototype);
EqRelationExprContext.prototype.constructor = EqRelationExprContext;

NQLParser.EqRelationExprContext = EqRelationExprContext;

EqRelationExprContext.prototype.EQ = function() {
    return this.getToken(NQLParser.EQ, 0);
};

EqRelationExprContext.prototype.identifier = function() {
    return this.getTypedRuleContext(IdentifierContext,0);
};

EqRelationExprContext.prototype.string = function() {
    return this.getTypedRuleContext(StringContext,0);
};


NQLParser.RelationExprContext = RelationExprContext;

NQLParser.prototype.relationExpr = function() {

    var localctx = new RelationExprContext(this, this._ctx, this.state);
    this.enterRule(localctx, 8, NQLParser.RULE_relationExpr);
    try {
        this.state = 89;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,4,this._ctx);
        switch(la_) {
        case 1:
            localctx = new GtRelationExprContext(this, localctx);
            this.enterOuterAlt(localctx, 1);
            this.state = 60;
            localctx.left = this.identifier();
            this.state = 61;
            this.match(NQLParser.GT);
            this.state = 62;
            localctx.right = this.string();
            break;

        case 2:
            localctx = new GeRelationExprContext(this, localctx);
            this.enterOuterAlt(localctx, 2);
            this.state = 64;
            localctx.left = this.identifier();
            this.state = 65;
            this.match(NQLParser.GE);
            this.state = 66;
            localctx.right = this.string();
            break;

        case 3:
            localctx = new LtRelationExprContext(this, localctx);
            this.enterOuterAlt(localctx, 3);
            this.state = 68;
            localctx.left = this.identifier();
            this.state = 69;
            this.match(NQLParser.LT);
            this.state = 70;
            localctx.right = this.string();
            break;

        case 4:
            localctx = new LeRelationExprContext(this, localctx);
            this.enterOuterAlt(localctx, 4);
            this.state = 72;
            localctx.left = this.identifier();
            this.state = 73;
            this.match(NQLParser.LE);
            this.state = 74;
            localctx.right = this.string();
            break;

        case 5:
            localctx = new EqRelationExprContext(this, localctx);
            this.enterOuterAlt(localctx, 5);
            this.state = 76;
            localctx.left = this.identifier();
            this.state = 77;
            this.match(NQLParser.EQ);
            this.state = 78;
            localctx.right = this.string();
            break;

        case 6:
            localctx = new NeRelationExprContext(this, localctx);
            this.enterOuterAlt(localctx, 6);
            this.state = 80;
            localctx.left = this.identifier();
            this.state = 81;
            this.match(NQLParser.NE);
            this.state = 82;
            localctx.right = this.string();
            break;

        case 7:
            localctx = new ContainsRelationExprContext(this, localctx);
            this.enterOuterAlt(localctx, 7);
            this.state = 84;
            localctx.left = this.identifier();
            this.state = 85;
            this.maybeNot();
            this.state = 86;
            this.match(NQLParser.CONTAINS);
            this.state = 87;
            localctx.right = this.string();
            break;

        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function MaybeNotContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = NQLParser.RULE_maybeNot;
    return this;
}

MaybeNotContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
MaybeNotContext.prototype.constructor = MaybeNotContext;



MaybeNotContext.prototype.copyFrom = function(ctx) {
    antlr4.ParserRuleContext.prototype.copyFrom.call(this, ctx);
};


function MaybeNotNoContext(parser, ctx) {
	MaybeNotContext.call(this, parser);
    MaybeNotContext.prototype.copyFrom.call(this, ctx);
    return this;
}

MaybeNotNoContext.prototype = Object.create(MaybeNotContext.prototype);
MaybeNotNoContext.prototype.constructor = MaybeNotNoContext;

NQLParser.MaybeNotNoContext = MaybeNotNoContext;


function MaybeNotYesContext(parser, ctx) {
	MaybeNotContext.call(this, parser);
    MaybeNotContext.prototype.copyFrom.call(this, ctx);
    return this;
}

MaybeNotYesContext.prototype = Object.create(MaybeNotContext.prototype);
MaybeNotYesContext.prototype.constructor = MaybeNotYesContext;

NQLParser.MaybeNotYesContext = MaybeNotYesContext;

MaybeNotYesContext.prototype.NOT = function() {
    return this.getToken(NQLParser.NOT, 0);
};


NQLParser.MaybeNotContext = MaybeNotContext;

NQLParser.prototype.maybeNot = function() {

    var localctx = new MaybeNotContext(this, this._ctx, this.state);
    this.enterRule(localctx, 10, NQLParser.RULE_maybeNot);
    try {
        this.state = 93;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case NQLParser.NOT:
            localctx = new MaybeNotYesContext(this, localctx);
            this.enterOuterAlt(localctx, 1);
            this.state = 91;
            this.match(NQLParser.NOT);
            break;
        case NQLParser.CONTAINS:
        case NQLParser.EXISTS:
            localctx = new MaybeNotNoContext(this, localctx);
            this.enterOuterAlt(localctx, 2);

            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function StringContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = NQLParser.RULE_string;
    return this;
}

StringContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
StringContext.prototype.constructor = StringContext;



StringContext.prototype.copyFrom = function(ctx) {
    antlr4.ParserRuleContext.prototype.copyFrom.call(this, ctx);
};


function QuotedStringContext(parser, ctx) {
	StringContext.call(this, parser);
    StringContext.prototype.copyFrom.call(this, ctx);
    return this;
}

QuotedStringContext.prototype = Object.create(StringContext.prototype);
QuotedStringContext.prototype.constructor = QuotedStringContext;

NQLParser.QuotedStringContext = QuotedStringContext;

QuotedStringContext.prototype.DOUBLE_QUOTED_STRING = function() {
    return this.getToken(NQLParser.DOUBLE_QUOTED_STRING, 0);
};

function SimpleStringContext(parser, ctx) {
	StringContext.call(this, parser);
    StringContext.prototype.copyFrom.call(this, ctx);
    return this;
}

SimpleStringContext.prototype = Object.create(StringContext.prototype);
SimpleStringContext.prototype.constructor = SimpleStringContext;

NQLParser.SimpleStringContext = SimpleStringContext;

SimpleStringContext.prototype.SIMPLE_STRING = function() {
    return this.getToken(NQLParser.SIMPLE_STRING, 0);
};


NQLParser.StringContext = StringContext;

NQLParser.prototype.string = function() {

    var localctx = new StringContext(this, this._ctx, this.state);
    this.enterRule(localctx, 12, NQLParser.RULE_string);
    try {
        this.state = 97;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case NQLParser.SIMPLE_STRING:
            localctx = new SimpleStringContext(this, localctx);
            this.enterOuterAlt(localctx, 1);
            this.state = 95;
            this.match(NQLParser.SIMPLE_STRING);
            break;
        case NQLParser.DOUBLE_QUOTED_STRING:
            localctx = new QuotedStringContext(this, localctx);
            this.enterOuterAlt(localctx, 2);
            this.state = 96;
            this.match(NQLParser.DOUBLE_QUOTED_STRING);
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function IdentifierContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = NQLParser.RULE_identifier;
    return this;
}

IdentifierContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
IdentifierContext.prototype.constructor = IdentifierContext;



IdentifierContext.prototype.copyFrom = function(ctx) {
    antlr4.ParserRuleContext.prototype.copyFrom.call(this, ctx);
};


function AggrIdentifierContext(parser, ctx) {
	IdentifierContext.call(this, parser);
    this.func = null; // Token;
    this.id = null; // StringIdentifierContext;
    this.type = null; // Token;
    IdentifierContext.prototype.copyFrom.call(this, ctx);
    return this;
}

AggrIdentifierContext.prototype = Object.create(IdentifierContext.prototype);
AggrIdentifierContext.prototype.constructor = AggrIdentifierContext;

NQLParser.AggrIdentifierContext = AggrIdentifierContext;

AggrIdentifierContext.prototype.LPAREN = function() {
    return this.getToken(NQLParser.LPAREN, 0);
};

AggrIdentifierContext.prototype.COLON = function() {
    return this.getToken(NQLParser.COLON, 0);
};

AggrIdentifierContext.prototype.RPAREN = function() {
    return this.getToken(NQLParser.RPAREN, 0);
};

AggrIdentifierContext.prototype.SIMPLE_STRING = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(NQLParser.SIMPLE_STRING);
    } else {
        return this.getToken(NQLParser.SIMPLE_STRING, i);
    }
};


AggrIdentifierContext.prototype.stringIdentifier = function() {
    return this.getTypedRuleContext(StringIdentifierContext,0);
};

function BasicIdentifierContext(parser, ctx) {
	IdentifierContext.call(this, parser);
    this.id = null; // StringIdentifierContext;
    this.type = null; // Token;
    IdentifierContext.prototype.copyFrom.call(this, ctx);
    return this;
}

BasicIdentifierContext.prototype = Object.create(IdentifierContext.prototype);
BasicIdentifierContext.prototype.constructor = BasicIdentifierContext;

NQLParser.BasicIdentifierContext = BasicIdentifierContext;

BasicIdentifierContext.prototype.COLON = function() {
    return this.getToken(NQLParser.COLON, 0);
};

BasicIdentifierContext.prototype.stringIdentifier = function() {
    return this.getTypedRuleContext(StringIdentifierContext,0);
};

BasicIdentifierContext.prototype.SIMPLE_STRING = function() {
    return this.getToken(NQLParser.SIMPLE_STRING, 0);
};


NQLParser.IdentifierContext = IdentifierContext;

NQLParser.prototype.identifier = function() {

    var localctx = new IdentifierContext(this, this._ctx, this.state);
    this.enterRule(localctx, 14, NQLParser.RULE_identifier);
    try {
        this.state = 110;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,7,this._ctx);
        switch(la_) {
        case 1:
            localctx = new BasicIdentifierContext(this, localctx);
            this.enterOuterAlt(localctx, 1);
            this.state = 99;
            localctx.id = this.stringIdentifier();
            this.state = 100;
            this.match(NQLParser.COLON);
            this.state = 101;
            localctx.type = this.match(NQLParser.SIMPLE_STRING);
            break;

        case 2:
            localctx = new AggrIdentifierContext(this, localctx);
            this.enterOuterAlt(localctx, 2);
            this.state = 103;
            localctx.func = this.match(NQLParser.SIMPLE_STRING);
            this.state = 104;
            this.match(NQLParser.LPAREN);
            this.state = 105;
            localctx.id = this.stringIdentifier();
            this.state = 106;
            this.match(NQLParser.COLON);
            this.state = 107;
            localctx.type = this.match(NQLParser.SIMPLE_STRING);
            this.state = 108;
            this.match(NQLParser.RPAREN);
            break;

        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function StringIdentifierContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = NQLParser.RULE_stringIdentifier;
    return this;
}

StringIdentifierContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
StringIdentifierContext.prototype.constructor = StringIdentifierContext;



StringIdentifierContext.prototype.copyFrom = function(ctx) {
    antlr4.ParserRuleContext.prototype.copyFrom.call(this, ctx);
};


function SimpleStringIdentifierContext(parser, ctx) {
	StringIdentifierContext.call(this, parser);
    StringIdentifierContext.prototype.copyFrom.call(this, ctx);
    return this;
}

SimpleStringIdentifierContext.prototype = Object.create(StringIdentifierContext.prototype);
SimpleStringIdentifierContext.prototype.constructor = SimpleStringIdentifierContext;

NQLParser.SimpleStringIdentifierContext = SimpleStringIdentifierContext;

SimpleStringIdentifierContext.prototype.SIMPLE_STRING = function() {
    return this.getToken(NQLParser.SIMPLE_STRING, 0);
};

function QuotedStringIdentifierContext(parser, ctx) {
	StringIdentifierContext.call(this, parser);
    StringIdentifierContext.prototype.copyFrom.call(this, ctx);
    return this;
}

QuotedStringIdentifierContext.prototype = Object.create(StringIdentifierContext.prototype);
QuotedStringIdentifierContext.prototype.constructor = QuotedStringIdentifierContext;

NQLParser.QuotedStringIdentifierContext = QuotedStringIdentifierContext;

QuotedStringIdentifierContext.prototype.BACK_QUOTED_STRING = function() {
    return this.getToken(NQLParser.BACK_QUOTED_STRING, 0);
};


NQLParser.StringIdentifierContext = StringIdentifierContext;

NQLParser.prototype.stringIdentifier = function() {

    var localctx = new StringIdentifierContext(this, this._ctx, this.state);
    this.enterRule(localctx, 16, NQLParser.RULE_stringIdentifier);
    try {
        this.state = 114;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case NQLParser.SIMPLE_STRING:
            localctx = new SimpleStringIdentifierContext(this, localctx);
            this.enterOuterAlt(localctx, 1);
            this.state = 112;
            this.match(NQLParser.SIMPLE_STRING);
            break;
        case NQLParser.BACK_QUOTED_STRING:
            localctx = new QuotedStringIdentifierContext(this, localctx);
            this.enterOuterAlt(localctx, 2);
            this.state = 113;
            this.match(NQLParser.BACK_QUOTED_STRING);
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


NQLParser.prototype.sempred = function(localctx, ruleIndex, predIndex) {
	switch(ruleIndex) {
	case 1:
			return this.logicalExprP1_sempred(localctx, predIndex);
	case 2:
			return this.logicalExprP2_sempred(localctx, predIndex);
    default:
        throw "No predicate with index:" + ruleIndex;
   }
};

NQLParser.prototype.logicalExprP1_sempred = function(localctx, predIndex) {
	switch(predIndex) {
		case 0:
			return this.precpred(this._ctx, 2);
		default:
			throw "No predicate with index:" + predIndex;
	}
};

NQLParser.prototype.logicalExprP2_sempred = function(localctx, predIndex) {
	switch(predIndex) {
		case 1:
			return this.precpred(this._ctx, 2);
		default:
			throw "No predicate with index:" + predIndex;
	}
};


NQLParser.ParseContext = ParseContext;
NQLParser.LogicalExprP1Context = LogicalExprP1Context;
NQLParser.LogicalExprP2Context = LogicalExprP2Context;
NQLParser.LogicalExprP3Context = LogicalExprP3Context;
NQLParser.RelationExprContext = RelationExprContext;
NQLParser.MaybeNotContext = MaybeNotContext;
NQLParser.StringContext = StringContext;
NQLParser.IdentifierContext = IdentifierContext;
NQLParser.StringIdentifierContext = StringIdentifierContext;
NQLParser.QueryContext = QueryContext;
NQLParser.EmptyContext = EmptyContext;
NQLParser.SingleLogicalExprP1Context = SingleLogicalExprP1Context;
NQLParser.OrLogicalExprP1Context = OrLogicalExprP1Context;
NQLParser.SingleLogicalExprP2Context = SingleLogicalExprP2Context;
NQLParser.AndLogicalExprP2Context = AndLogicalExprP2Context;
NQLParser.ParenthesesLogicalExprP3Context = ParenthesesLogicalExprP3Context;
NQLParser.SearchTermLogicalExprP3Context = SearchTermLogicalExprP3Context;
NQLParser.RelationLogicalExprP3Context = RelationLogicalExprP3Context;
NQLParser.ExistsLogicalExprP3Context = ExistsLogicalExprP3Context;
NQLParser.NotLogicalExprP3Context = NotLogicalExprP3Context;
NQLParser.NeRelationExprContext = NeRelationExprContext;
NQLParser.LtRelationExprContext = LtRelationExprContext;
NQLParser.ContainsRelationExprContext = ContainsRelationExprContext;
NQLParser.GeRelationExprContext = GeRelationExprContext;
NQLParser.LeRelationExprContext = LeRelationExprContext;
NQLParser.GtRelationExprContext = GtRelationExprContext;
NQLParser.EqRelationExprContext = EqRelationExprContext;
NQLParser.MaybeNotNoContext = MaybeNotNoContext;
NQLParser.MaybeNotYesContext = MaybeNotYesContext;
NQLParser.QuotedStringContext = QuotedStringContext;
NQLParser.SimpleStringContext = SimpleStringContext;
NQLParser.AggrIdentifierContext = AggrIdentifierContext;
NQLParser.BasicIdentifierContext = BasicIdentifierContext;
NQLParser.SimpleStringIdentifierContext = SimpleStringIdentifierContext;
export { NQLParser, ParseContext, LogicalExprP1Context, LogicalExprP2Context, LogicalExprP3Context, RelationExprContext, MaybeNotContext, StringContext, IdentifierContext, StringIdentifierContext, QueryContext, EmptyContext, SingleLogicalExprP1Context, OrLogicalExprP1Context, SingleLogicalExprP2Context, AndLogicalExprP2Context, ParenthesesLogicalExprP3Context, SearchTermLogicalExprP3Context, RelationLogicalExprP3Context, ExistsLogicalExprP3Context, NotLogicalExprP3Context, NeRelationExprContext, LtRelationExprContext, ContainsRelationExprContext, GeRelationExprContext, LeRelationExprContext, GtRelationExprContext, EqRelationExprContext, MaybeNotNoContext, MaybeNotYesContext, QuotedStringContext, SimpleStringContext, AggrIdentifierContext, BasicIdentifierContext, SimpleStringIdentifierContext, QuotedStringIdentifierContext };
NQLParser.QuotedStringIdentifierContext = QuotedStringIdentifierContext;

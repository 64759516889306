import { listAttributeDefinitions } from '@neptune/shared/attributes-domain';
import { Entity, EntityType, fetchEntityChunk } from '@neptune/shared/entity-domain';
import { makeEntityIdentifier } from '@neptune/shared/entity-util';
import { AttributeDefinition, knownAttributes } from 'domain/experiment/attribute';
import { AsyncActionsReturnType, createAsyncActions } from 'state/async-actions';

export enum CurrentBasicEntityActionTypes {
  request = 'CURRENT_BASIC_ENTITY_FETCH',
  success = 'CURRENT_BASIC_ENTITY_FETCH_SUCCESS',
  fail = 'CURRENT_BASIC_ENTITY_FETCH_FAIL',
}

export enum UpdateCurrentBasicEntityActionTypes {
  request = 'UPDATE_CURRENT_BASIC_ENTITY_FETCH',
  success = 'UPDATE_CURRENT_BASIC_ENTITY_FETCH_SUCCESS',
  fail = 'UPDATE_CURRENT_BASIC_ENTITY_FETCH_FAIL',
}

/** @deprecated todo: move out of redux */
export enum CurrentEntityAttributeDefinitionsActionTypes {
  request = 'CURRENT_ENTITY_ATTRIBUTE_DEFINITIONS_FETCH',
  success = 'CURRENT_ENTITY_ATTRIBUTE_DEFINITIONS_FETCH_SUCCESS',
  fail = 'CURRENT_ENTITY_ATTRIBUTE_DEFINITIONS_FETCH_FAIL',
}

/** @deprecated todo: move out of redux */
export enum UpdateCurrentEntityAttributeDefinitionsActionTypes {
  request = 'UPDATE_CURRENT_ENTITY_ATTRIBUTE_DEFINITIONS_FETCH',
  success = 'UPDATE_CURRENT_ENTITY_ATTRIBUTE_DEFINITIONS_FETCH_SUCCESS',
  fail = 'UPDATE_CURRENT_ENTITY_ATTRIBUTE_DEFINITIONS_FETCH_FAIL',
}

type EntityAddress = {
  organizationName: string;
  projectName: string;
  shortId: string;
};

const basicEntityActions = createAsyncActions({
  types: CurrentBasicEntityActionTypes,
  resolver: ({ organizationName, projectName, shortId }: EntityAddress): Promise<Entity> => {
    const id = makeEntityIdentifier(organizationName, projectName, shortId);
    return fetchEntityChunk({ id, fieldsToFetch: knownAttributes, holderType: 'experiment' });
  },
});

const updateBasicEntityActions = createAsyncActions({
  types: UpdateCurrentBasicEntityActionTypes,
  resolver: ({ id, type }: { id: string; type: EntityType }): Promise<Entity> => {
    return fetchEntityChunk({ id, fieldsToFetch: knownAttributes, type });
  },
});

/** @deprecated todo: move out of redux */
const entityAttributeDefinitionsActions = createAsyncActions({
  types: CurrentEntityAttributeDefinitionsActionTypes,
  resolver: async ({
    organizationName,
    projectName,
    shortId,
  }: EntityAddress): Promise<{ requestId: string; result: AttributeDefinition[] }> => {
    const identifier = makeEntityIdentifier(organizationName, projectName, shortId);
    return {
      requestId: identifier,
      result: await listAttributeDefinitions({ identifier }),
    };
  },
});

/** @deprecated todo: move out of redux */
const updateEntityAttributeDefinitionsActions = createAsyncActions({
  types: UpdateCurrentEntityAttributeDefinitionsActionTypes,
  resolver: async (
    identifier: string,
  ): Promise<{ identifier: string; result: AttributeDefinition[] }> => {
    return {
      identifier,
      result: await listAttributeDefinitions({ identifier }),
    };
  },
});

export const { execute: fetchBasicCurrentEntity } = basicEntityActions;
export const { execute: updateBasicCurrentEntity } = updateBasicEntityActions;
/** @deprecated todo: move out of redux */
export const { execute: fetchCurrentEntityAttributeDefinitions } =
  entityAttributeDefinitionsActions;
/** @deprecated todo: move out of redux */
export const { execute: updateCurrentEntityAttributeDefinitions } =
  updateEntityAttributeDefinitionsActions;

export type CurrentEntityActions =
  | AsyncActionsReturnType<typeof basicEntityActions>
  | AsyncActionsReturnType<typeof updateBasicEntityActions>
  | AsyncActionsReturnType<typeof entityAttributeDefinitionsActions>
  | AsyncActionsReturnType<typeof updateEntityAttributeDefinitionsActions>;

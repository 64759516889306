import React from 'react';

import { Icon, IconProps } from '@neptune/shared/venus-ui';
import { GlyphName } from '@neptune/shared/core-glyphs-domain';
import { ProjectPrivacy } from '@neptune/shared/core-organizations-domain';

type ProjectPrivacyIndicatorProps = {
  privacy: ProjectPrivacy;
  size?: IconProps['size'];
  className?: string;
};

const privacyIconMap: Partial<Record<ProjectPrivacy, GlyphName>> = {
  [ProjectPrivacy.private]: 'lock',
  [ProjectPrivacy.workspace]: 'briefcase',
};

const privacyTitleMap: Partial<Record<ProjectPrivacy, string>> = {
  [ProjectPrivacy.private]: 'Private',
  [ProjectPrivacy.workspace]: 'Workspace',
};

export const ProjectPrivacyIndicator: React.FC<ProjectPrivacyIndicatorProps> = ({
  privacy,
  size,
  className,
}) => {
  const glyph = privacyIconMap[privacy];

  if (!glyph) {
    return null;
  }

  return (
    <Icon
      glyph={glyph}
      className={className}
      title={privacyTitleMap[privacy]}
      size={size}
      data-role="privacy-indicator"
    />
  );
};

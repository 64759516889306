import { GlyphName } from '@neptune/shared/core-glyphs-domain';
import { getUnificationV1Enabled } from 'common/featureFlag';
import config from 'config';

export type CompareTab = {
  type: 'compare';
  glyph: GlyphName;
  dashboardId: string;
  routeParams: {
    dash: string;
    tab?: string;
    dashboardId?: string;
  };
  label: string;
  description?: string;
};

const compareTabs: CompareTab[] = [
  {
    type: 'compare',
    glyph: 'metadata',
    label: 'Experiments',
    dashboardId: 'table',
    routeParams: { tab: 'table', dash: 'table' },
  },
  {
    type: 'compare',
    glyph: 'bar-chart',
    label: 'Charts',
    dashboardId: 'charts',
    routeParams: { dash: 'charts' },
  },
  {
    type: 'compare',
    glyph: 'compare',
    label: 'Side-by-side',
    dashboardId: 'leaderboard',
    routeParams: { dash: 'leaderboard' },
  },
  {
    type: 'compare',
    glyph: 'images',
    label: 'Images',
    dashboardId: 'images',
    routeParams: { dash: 'images' },
  },
  {
    type: 'compare',
    glyph: 'parallel-coordinates',
    label: 'Parallel coordinates',
    dashboardId: 'parallel-coordinates-plot',
    routeParams: { dash: 'parallel-coordinates-plot' },
  },
  {
    type: 'compare',
    glyph: 'artifacts',
    label: 'Artifacts',
    dashboardId: 'artifacts',
    routeParams: { dash: 'artifacts' },
  },
  {
    type: 'compare',
    glyph: 'dashboards',
    label: 'Dashboards',
    dashboardId: 'dashboard',
    routeParams: { dash: 'dashboard' },
  },
];

/* NOTE: Registering new tab please update keyboard shortcut in KeyboardShortcutsModal.tsx */
export const compareDefaultTabs = compareTabs.filter(
  ({ dashboardId }) =>
    !(dashboardId === 'parallel-coordinates-plot' && !config.parallelPlotEnabled) &&
    !(dashboardId === 'table' && !getUnificationV1Enabled()),
);


import { makeEnterpriseRoute } from 'router/helpers';

const notebookRoute = {
  name: 'project.notebook',
  path: '/n/:notebookId',
};

const versionRoute = {
  name: 'project.notebook.version',
  path: '/:versionId',
};

export default [
  notebookRoute,
  makeEnterpriseRoute(notebookRoute),
  versionRoute,
  makeEnterpriseRoute(versionRoute),
];


import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getShortIdFromRouteParams } from 'state/selectors-global';
import { LeaderboardContext } from '@neptune/shared/entity-leaderboard-domain';
import { Entity, getEntityShortId } from '@neptune/shared/entity-domain';
import { getCurrentRouteName, navigateTo } from '@neptune/shared/routing-business-logic';
import { getLeaderboardState } from '@neptune/shared/leaderboard-business-logic';
import { AppState } from 'state/types';
import { makeProjectIdentifier } from 'common/project';

export function useSetShortIdFromLeaderboardIfMissing() {
  const dispatch = useDispatch();
  const { id: ID, projectIdentifier } = React.useContext(LeaderboardContext);
  const entries: Entity[] = useSelector(
    (state: AppState) => getLeaderboardState<Entity>(state, ID).entries,
  );
  const defaultEntryCandidate = entries[0];
  const candidateIsValid =
    defaultEntryCandidate &&
    makeProjectIdentifier(
      defaultEntryCandidate.organizationName,
      defaultEntryCandidate.projectName,
    ) === projectIdentifier;
  const defaultEntry = candidateIsValid ? defaultEntryCandidate : undefined;

  const shortId = useSelector(getShortIdFromRouteParams);
  const routeName = useSelector(getCurrentRouteName);

  React.useEffect(() => {
    if (!shortId && defaultEntry) {
      const newShortId = getEntityShortId(defaultEntry);
      dispatch(
        navigateTo(routeName, { shortId: newShortId }, { extendParams: true, replace: true }),
      );
    }
  }, [dispatch, shortId, defaultEntry, routeName]);
}

import { knownAttributes } from 'domain/experiment/attribute';
import { SimpleState } from 'router5/types/types/base';
import {
  getEntityTrashed,
  fetchEntityChunkByShortId,
  Entity,
  getEntityShortId,
  EntityType,
} from '@neptune/shared/entity-domain';
import { toNormalizedRouteName } from '@neptune/shared/enterprise-context-util';

/**
 * Fetches entity for in-project display route.
 * WARN: Fetched entity will contain only well-known attributes.
 * @param requestedRoute
 */
export async function fetchEntityForInProjectDisplayRoute(
  requestedRoute: SimpleState,
): Promise<Entity> {
  const { organizationName, projectName, shortId } = requestedRoute?.params;

  return fetchEntityChunkByShortId(organizationName, projectName, shortId, knownAttributes);
}

type InProjectEntityDisplayType = {
  type: Exclude<EntityType, 'project'>;
  trashed: boolean;
};

export function entityMatchesDisplayType(
  entity: Entity,
  { type, trashed }: InProjectEntityDisplayType,
) {
  return entity.type === type && getEntityTrashed(entity) === trashed;
}

export function typeOfProjectEntityDisplayRoute(
  route: SimpleState,
): InProjectEntityDisplayType | undefined {
  const name = toNormalizedRouteName(route.name);
  const params = route.params;
  const type: string | undefined = params.type;

  if ('shortId' in params) {
    if ((name === 'project.runs' || name === 'project.runs-tab') && params.tab === 'details') {
      return { type: 'run', trashed: false };
    }

    if (name === 'project.model-registry' && (type === 'model' || type === 'modelVersion')) {
      return { type, trashed: false };
    }

    if (
      name === 'project.trash' &&
      (type === 'run' || type === 'model' || type === 'modelVersion')
    ) {
      return { type, trashed: true };
    }
  }

  return undefined;
}

export function getInProjectEntityDisplayRoute(entity: Entity): SimpleState | undefined {
  if (entity.type === 'project') {
    // We want to show the entity in context of the project, so it makes no sense for this entity to be a project.
    return undefined;
  }

  const commonParams = {
    organizationName: entity.organizationName,
    projectName: entity.projectName,
    shortId: getEntityShortId(entity),
  };

  if (getEntityTrashed(entity)) {
    return { name: 'project.trash', params: { ...commonParams, type: entity.type } };
  }

  switch (entity.type) {
    case 'run':
      return { name: 'project.runs', params: { ...commonParams, tab: 'details' } };

    case 'model':
    case 'modelVersion':
      return { name: 'project.model-registry', params: { ...commonParams, type: entity.type } };
  }

  return undefined;
}

import React from 'react';
import { UseAsyncValueReturns } from '@neptune/shared/common-util';

export enum OwnerType {
  User = 'User',
  ServiceAccount = 'ServiceAccount',
}

export type OwnerEntry = {
  type: OwnerType;
  value: string;
  match?: string;
};

export const OwnerSearchContext = React.createContext<UseAsyncValueReturns<OwnerEntry[]>>({
  refresh: () => Promise.reject(),
  loading: false,
  value: undefined,
});

import { toRunsTabName } from '@neptune/project-runs-domain';
import {
  createCurrentRouteParamSelector,
  getCurrentRouteParams,
} from '@neptune/shared/routing-business-logic';
import { useSelector } from 'react-redux';
import { Params } from 'router5/types/types/base';

type DashboardMode = 'single' | 'compare';
const shortIdSelector = createCurrentRouteParamSelector('shortId');

export const useRunsViewMode = (): { dashboardMode: DashboardMode } => {
  const shortId: string = useSelector(shortIdSelector);
  const params = useSelector(getCurrentRouteParams);

  const dashboardMode = determineDashboardMode(params, shortId);

  return { dashboardMode };
};

const determineDashboardMode = (params: Params, shortId: string): DashboardMode => {
  const currentTab = toRunsTabName(params.tab, 'table');
  return (currentTab === 'table' && !!shortId) || currentTab === 'details' ? 'single' : 'compare';
};

import { superReaderRepository } from '@neptune/shared/core-super-reader-domain';
import {
  AvatarSource,
  avatarSourceFromApiToDomain,
  avatarSourceFromDomainToApi,
  UserEntry,
} from '@neptune/shared/core-users-domain';
import {
  OrganizationRole,
  organizationRoleFromApiToDomain,
  organizationRoleFromDomainToApi,
  OrganizationType,
  organizationTypeFromApiToDomain,
  organizationTypeFromDomainToApi,
  ProjectPrivacy,
  projectPrivacyFromApiToDomain,
  projectPrivacyFromDomainToApi,
} from '@neptune/shared/core-organizations-domain';
import {
  backendClient,
  NewProjectDTO,
  ProjectUpdateDTO,
} from '@neptune/shared/core-apis-backend-domain';
import { ProjectWithRoleDTO } from 'generated/backend-client';
import {
  ProjectCodeAccess,
  projectCodeAccessFromApiToDomain,
  projectCodeAccessFromDomainToApi,
} from './project-code-access';
import {
  ProjectRole,
  projectRoleFromApiToDomain,
  projectRoleFromDomainToApi,
} from './project-role';
import { makeProjectIdentifier } from '@neptune/shared/core-project-util';

export interface ProjectWithRole {
  archived: boolean;
  codeAccess: ProjectCodeAccess;
  avatarUrl: string;
  description?: string;
  organizationType: OrganizationType;
  featured: boolean;
  organizationName: string;
  avatarSource: AvatarSource;
  version: number;
  id: string;
  projectKey: string;
  organizationId: string;
  userCount: number;
  visibility: ProjectPrivacy;
  displayClass?: string;
  name: string;
  lastActivity: Date;
  timeOfCreation: Date;
  userRoleInOrganization?: OrganizationRole;
  userRoleInProject: ProjectRole;
  backgroundUrl?: string;

  // computed
  projectIdentifier: string;
  superUser?: boolean;
}

export interface EnhancedProjectWithRole extends ProjectWithRole {
  // These are not from ProjectWithRoleDTO. They are fetched separately
  // and may not always be populated.
  members?: UserEntry[];
}

export function projectWithRoleFromApiToDomain(source: ProjectWithRoleDTO): ProjectWithRole {
  return {
    archived: source.archived,
    codeAccess: projectCodeAccessFromApiToDomain(source.codeAccess),
    avatarUrl: source.avatarUrl,
    description: source.description,
    organizationType: organizationTypeFromApiToDomain(source.organizationType),
    featured: source.featured,
    organizationName: source.organizationName,
    avatarSource: avatarSourceFromApiToDomain(source.avatarSource),
    version: source.version,
    id: source.id,
    projectKey: source.projectKey,
    organizationId: source.organizationId,
    userCount: source.userCount,
    visibility: projectPrivacyFromApiToDomain(source.visibility),
    displayClass: source.displayClass,
    name: source.name,
    lastActivity: source.lastActivity,
    timeOfCreation: source.timeOfCreation,
    userRoleInOrganization:
      source?.userRoleInOrganization &&
      organizationRoleFromApiToDomain(source?.userRoleInOrganization),
    userRoleInProject: projectRoleFromApiToDomain(source.userRoleInProject),
    backgroundUrl: source.backgroundUrl,

    // computed
    projectIdentifier: makeProjectIdentifier(source.organizationName, source.name),
    superUser: superReaderRepository.active,
  };
}

export function projectWithRoleFromDomainToApi(source: ProjectWithRole): ProjectWithRoleDTO {
  return {
    archived: source.archived,
    codeAccess: projectCodeAccessFromDomainToApi(source.codeAccess),
    description: source.description,
    organizationType: organizationTypeFromDomainToApi(source.organizationType),
    featured: source.featured,
    organizationName: source.organizationName,
    avatarUrl: source.avatarUrl,
    avatarSource: avatarSourceFromDomainToApi(source.avatarSource),
    version: source.version,
    id: source.id,
    projectKey: source.projectKey,
    organizationId: source.organizationId,
    userCount: source.userCount,
    visibility: projectPrivacyFromDomainToApi(source.visibility),
    displayClass: source.displayClass,
    name: source.name,
    lastActivity: source.lastActivity,
    timeOfCreation: source.timeOfCreation,
    userRoleInOrganization:
      source?.userRoleInOrganization &&
      organizationRoleFromDomainToApi(source?.userRoleInOrganization),
    userRoleInProject: projectRoleFromDomainToApi(source.userRoleInProject),
    backgroundUrl: source.backgroundUrl,
  };
}

export type NewProject = {
  name: string;
  avatarUrl: string;
  avatarSource: AvatarSource;
  organizationId: string;
  description?: string;
  version?: number;
  projectKey?: string;
  visibility?: ProjectPrivacy;
  displayClass?: string;
};

export function newProjectFromDomainToApi(source: NewProject): NewProjectDTO {
  return {
    description: source.description,
    avatarUrl: source.avatarUrl,
    avatarSource: avatarSourceFromDomainToApi(source.avatarSource),
    version: source.version,
    projectKey: source.projectKey,
    organizationId: source.organizationId,
    visibility: projectPrivacyFromDomainToApi(source.visibility),
    displayClass: source.displayClass,
    name: source.name,
  };
}

export async function createProject(project: NewProject) {
  try {
    const result = await backendClient.createProject({
      projectToCreate: newProjectFromDomainToApi(project),
    });
    return projectWithRoleFromApiToDomain(result);
  } catch (e) {
    if (e instanceof Response) {
      throw await e.json();
    }

    throw e;
  }
}

export async function deleteProjectById({ projectIdentifier }: { projectIdentifier: string }) {
  return await backendClient.deleteProject({
    projectIdentifier,
  });
}

export async function fetchProject({ projectIdentifier }: { projectIdentifier: string }) {
  const result = await backendClient.getProjectDetails({
    projectIdentifier,
  });
  return projectWithRoleFromApiToDomain(result);
}

export async function generateProjectKey({
  organizationId,
  projectName,
}: {
  organizationId: string;
  projectName: string;
}) {
  const result = await backendClient.generateProjectKey({
    organizationId,
    projectName,
  });
  return { proposal: result.proposal };
}

export type ProjectUpdate = {
  name?: string;
  description?: string;
  avatarSource?: AvatarSource;
  codeAccess?: ProjectCodeAccess;
  displayClass?: string;
  archived?: boolean;
  avatarUrl?: string;
  visibility?: ProjectPrivacy;
};

export function projectUpdateFromDomainToApi(source: ProjectUpdate): ProjectUpdateDTO {
  return {
    description: source.description,
    avatarUrl: source.avatarUrl,
    codeAccess: source.codeAccess && projectCodeAccessFromDomainToApi(source.codeAccess),
    avatarSource: source.avatarSource && avatarSourceFromDomainToApi(source.avatarSource),
    visibility: source.visibility && projectPrivacyFromDomainToApi(source.visibility),
    displayClass: source.displayClass,
    name: source.name,
  };
}

export async function updateProjectById({
  projectIdentifier,
  projectUpdate,
}: {
  projectIdentifier: string;
  projectUpdate: ProjectUpdate;
}) {
  const apiProject = projectUpdateFromDomainToApi(projectUpdate);
  const result = await backendClient.updateProject({
    projectIdentifier,
    projectToUpdate: {
      // note: archived field is omitted for purpose. The field should be modified explicitly by archiveProject or unarchiveProject
      avatarSource: apiProject.avatarSource,
      avatarUrl: apiProject.avatarUrl,
      codeAccess: apiProject.codeAccess,
      description: apiProject.description,
      displayClass: apiProject.displayClass,
      name: apiProject.name,
      visibility: apiProject.visibility,
    },
  });
  return projectWithRoleFromApiToDomain(result);
}

export async function archiveProject({ projectIdentifier }: { projectIdentifier: string }) {
  const result = await backendClient.updateProject({
    projectIdentifier,
    projectToUpdate: { archived: true },
  });
  return projectWithRoleFromApiToDomain(result);
}

export async function unarchiveProject({ projectIdentifier }: { projectIdentifier: string }) {
  try {
    const result = await backendClient.updateProject({
      projectIdentifier,
      projectToUpdate: { archived: false },
    });
    return projectWithRoleFromApiToDomain(result);
  } catch (e) {
    if (e instanceof Response) {
      throw await e.json();
    }

    throw e;
  }
}

export async function verifyProjectKey({
  organizationIdentifier,
  projectKey,
}: {
  organizationIdentifier: string;
  projectKey: string;
}) {
  const result = await backendClient.verifyProjectKey({
    organizationIdentifier,
    projectKey,
  });
  return { projectExists: !result.isKeyAvailable };
}

import { AnyAction } from 'redux';
import { NThunkAction } from 'state/types';

import { getColorMapState } from './selectors';
import { getStoredCompareColorMap, storeCompareColors } from './utils';

type AssignColorsToExperimentsParams = {
  id: string;
  projectIdentifier: string;
  colorKeys: string[];
};

export function assignColorsToExperiments({
  id,
  projectIdentifier,
  colorKeys,
}: AssignColorsToExperimentsParams): NThunkAction<void, AnyAction> {
  return (dispatch, getState) => {
    dispatch({
      type: 'COMPARE_ASSIGN_COLORS',
      payload: {
        colorKeys,
      },
    });

    const colorMap = getColorMapState(getState());
    storeCompareColors({
      id,
      colorMap,
      projectIdentifier,
    });
  };
}

type AssignColorToExperimentParams = {
  id: string;
  projectIdentifier: string;
  colorKey: string;
  color: string;
};

export function assignColorToExperiment({
  id,
  projectIdentifier,
  colorKey,
  color,
}: AssignColorToExperimentParams): NThunkAction<void, AnyAction> {
  return (dispatch, getState) => {
    dispatch({
      type: 'COMPARE_ASSIGN_COLOR',
      payload: {
        color,
        colorKey,
      },
    });

    const colorMap = getColorMapState(getState());
    storeCompareColors({
      id,
      colorMap,
      projectIdentifier,
    });
  };
}

type LoadCompareColorsParams = {
  id: string;
  projectIdentifier: string;
};

export function loadCompareColors(params: LoadCompareColorsParams) {
  const newState = getStoredCompareColorMap(params);

  return {
    type: 'COMPARE_LOAD_COLORS',
    payload: {
      newState,
    },
  } as const;
}

type AssignColorsAction = {
  type: 'COMPARE_ASSIGN_COLORS';
  payload: {
    colorKeys: string[];
  };
};

type AssignColorAction = {
  type: 'COMPARE_ASSIGN_COLOR';
  payload: {
    colorKey: string;
    color: string;
  };
};

export type ColorMapActions =
  | ReturnType<typeof loadCompareColors>
  | AssignColorsAction
  | AssignColorAction;

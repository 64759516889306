import {
  FilterObjectFields,
  reconcileArrayWithId,
  reconcileValue,
} from '@neptune/shared/common-util';
import { Dashboard } from './dashboard-model';

export function reconcileDashboard(data: Dashboard, previousData: Dashboard): Dashboard {
  // Typing requires all non-primitive fields to be explicitly defined here.
  const objectProperties: FilterObjectFields<Dashboard> = {
    gridLayouts: reconcileArrayWithId(data.gridLayouts, previousData.gridLayouts),
    runIds: reconcileValue(data.runIds, previousData.runIds),
    widgets: reconcileArrayWithId(data.widgets, previousData.widgets),
    lastUpdatedTime: reconcileValue(data.lastUpdatedTime, previousData.lastUpdatedTime),
    createdTime: reconcileValue(data.createdTime, previousData.createdTime),
    viewParams: reconcileValue(data.viewParams, previousData.viewParams),
    config: reconcileValue(data.config, previousData.config),
    userExperimentNames: reconcileValue(data.userExperimentNames, previousData.userExperimentNames),
  };

  return {
    ...data,
    ...objectProperties,
  };
}

import React from 'react';

export enum LeaderboardId {
  Runs = 'runs',
  Models = 'models',
  ModelVersions = 'modelVersions',
  Compare = 'compare',
  Reports = 'reports',
  Trash = 'trash',
}

export type LeaderboardContextType = Readonly<{
  id: LeaderboardId;
  entityId: string;
  entityType: string;
  types: string[];
  trash: boolean;
  projectIdentifier: string;
}>;

export const LeaderboardContext = React.createContext<LeaderboardContextType>({
  get id(): LeaderboardId {
    throw new Error('LeaderboardContext is uninitialized here.');
  },
  get entityId(): string {
    throw new Error('LeaderboardContext is uninitialized here.');
  },
  get entityType(): string {
    throw new Error('LeaderboardContext is uninitialized here.');
  },
  get types(): string[] {
    throw new Error('LeaderboardContext is uninitialized here.');
  },
  get trash(): boolean {
    throw new Error('LeaderboardContext is uninitialized here.');
  },
  get projectIdentifier(): string {
    throw new Error('LeaderboardContext is uninitialized here.');
  },
});

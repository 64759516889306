import { chunk } from 'lodash';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  enhanceQueryWithExperimentsOnly,
  SearchQueryModelConverter,
} from '@neptune/search-query-domain';
import { Entity, getEntityShortId } from '@neptune/shared/entity-domain';
import {
  leaderboardClient,
  QueryLeaderboardParamsFieldDTO,
  QueryLeaderboardParamsFieldDTOAggregationModeEnum,
  QueryLeaderboardParamsGroupingParamsDTO,
  QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTO,
  QueryLeaderboardParamsPaginationDTO,
  QueryLeaderboardParamsSortingParamsDTO,
  QueryLeaderboardParamsSortingParamsDTODirEnum,
} from '@neptune/shared/core-apis-leaderboard-domain';
import {
  AttributeDefinitionConverter,
  AttributeType,
  KnownAttributes,
} from 'domain/experiment/attribute';
import { AggregationMode } from '../column/column-model';
import { GroupByField } from '../group-by-model';
import { LeaderboardSortingParams } from '../sort-options/sort-options-model';
import { Leaderboard, leaderboardFromApiToDomain } from './leaderboard';

type LeaderboardFieldParams = {
  aggregationMode?: AggregationMode;
  name: string;
  type: AttributeType;
};
export type LeaderboardOpenedGroup = {
  openedGroup: string;
  beforeToken?: string;
  continuationToken?: string;
  pagination?: LeaderboardPagination;
};
export type LeaderboardGrouping = {
  groupBy: GroupByField[];
  openedGroups: LeaderboardOpenedGroup[];
  compareIds?: string[];
};
export type LeaderboardPagination = {
  limit: number;
  offset: number;
};

function convertLeaderboardFieldParamsFromDomainToApi(
  input: LeaderboardFieldParams,
): QueryLeaderboardParamsFieldDTO | null {
  const type = AttributeDefinitionConverter.attributeTypeToApi(input.type);

  if (type == null) {
    return null;
  }

  return {
    aggregationMode:
      input.aggregationMode &&
      convertQueryLeaderboardParamsFieldDTOAggregationModeFromDomainToApi(input.aggregationMode),
    type,
    name: input.name,
  };
}

function isQueryLeaderboardParamsGroupingParamsDTO(
  input: QueryLeaderboardParamsFieldDTO | null,
): input is QueryLeaderboardParamsFieldDTO {
  return input != null;
}

function convertLeaderboardGroupingFromDomainToApi(
  input: LeaderboardGrouping,
): QueryLeaderboardParamsGroupingParamsDTO {
  const groupBy = input.groupBy
    .map(convertLeaderboardFieldParamsFromDomainToApi)
    .filter(isQueryLeaderboardParamsGroupingParamsDTO);

  return {
    groupBy,
    openedGroupsWithPagination: input.openedGroups.map(convertOpenedGroupFromDomainToApi),
  };
}

function convertOpenedGroupFromDomainToApi({
  openedGroup,
  pagination,
  continuationToken,
  beforeToken,
}: LeaderboardOpenedGroup): QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTO {
  return {
    openedGroup,
    pagination: {
      pagination:
        pagination !== undefined ? convertPaginationFromDomainToApi(pagination) : { limit: 10 },
      continuationToken,
      beforeToken, // TODO: doubt why both in request?
    },
  };
}

function convertPaginationFromDomainToApi(
  input: LeaderboardPagination,
): QueryLeaderboardParamsPaginationDTO {
  return {
    limit: input.limit,
    offset: input.offset,
  };
}

function convertSortingFromDomainToApi(
  input: LeaderboardSortingParams,
): QueryLeaderboardParamsSortingParamsDTO | null {
  const sortBy = convertLeaderboardFieldParamsFromDomainToApi(input.sortBy);

  if (sortBy == null) {
    return null;
  }

  return {
    dir:
      input.dir === 'descending'
        ? QueryLeaderboardParamsSortingParamsDTODirEnum.Descending
        : QueryLeaderboardParamsSortingParamsDTODirEnum.Ascending,
    sortBy,
  };
}

function convertQueryLeaderboardParamsFieldDTOAggregationModeFromDomainToApi(
  input: AggregationMode,
): QueryLeaderboardParamsFieldDTOAggregationModeEnum {
  switch (input) {
    case 'last':
      return QueryLeaderboardParamsFieldDTOAggregationModeEnum.Last;
    case 'min':
      return QueryLeaderboardParamsFieldDTOAggregationModeEnum.Min;
    case 'max':
      return QueryLeaderboardParamsFieldDTOAggregationModeEnum.Max;
    case 'average':
      return QueryLeaderboardParamsFieldDTOAggregationModeEnum.Average;
    case 'variance':
      return QueryLeaderboardParamsFieldDTOAggregationModeEnum.Variance;
    case 'auto':
      return QueryLeaderboardParamsFieldDTOAggregationModeEnum.Auto;
  }
}

export type SearchLeaderboardRequest = {
  projectIdentifier: string;
  parent?: string;
  type?: string[];
  query?: string;

  grouping?: LeaderboardGrouping;
  pagination?: LeaderboardPagination;
  sorting?: LeaderboardSortingParams;
  suggestions?: {
    enabled: boolean;
    viewId?: string;
  };

  attributesToFetch?: string[];
  experimentsOnly?: boolean;
};

function convertAttributeFilterFromDomainToApi(attributePaths: string[]) {
  return attributePaths.map((path) => ({ path }));
}

const uniqueFieldsProjectionLimit = 100;

export async function searchLeaderboardPaginated(
  requestParamsList: Omit<SearchLeaderboardRequest, 'attributesToFetch'>[],
): Promise<Leaderboard> {
  if (requestParamsList.length === 1) {
    return singleSearchLeaderboard(requestParamsList[0]);
  }

  const result = await Promise.all(requestParamsList.map(singleSearchLeaderboard));

  return {
    ...result[0],
    entries: ([] as Entity[]).concat(...result.map((r) => r.entries)),
  };
}

export async function searchLeaderboard(
  requestParams: SearchLeaderboardRequest,
  splitRequestProjectionLimit = uniqueFieldsProjectionLimit,
): Promise<Leaderboard> {
  if (
    !requestParams.attributesToFetch ||
    requestParams.attributesToFetch.length <= splitRequestProjectionLimit
  ) {
    // there is no need to split request here
    return singleSearchLeaderboard(requestParams);
  }

  const attributesToFetch = [
    KnownAttributes.Id,
    ...requestParams.attributesToFetch.filter((value) => value !== KnownAttributes.Id),
  ];

  const attributesChunks = chunk(attributesToFetch, splitRequestProjectionLimit);

  const baseResult = await singleSearchLeaderboard({
    ...requestParams,
    attributesToFetch: attributesChunks[0],
  });

  if (baseResult.entries.length === 0) {
    // there is no need to append more fields in empty result
    return baseResult;
  }

  const entityIdToEntityMap = new Map<string, Entity>();
  baseResult.entries.forEach((entity) => entityIdToEntityMap.set(entity.id, entity));

  // we stick to entities to make sure we get more data for the exact same entities
  const query = enrichQueryWithRequiredShortIds(
    requestParams.query,
    baseResult.entries.map(getEntityShortId),
  );

  await Promise.all(
    attributesChunks.slice(1).map(async (attributesToFetch) => {
      const moreResult = await singleSearchLeaderboard({
        ...requestParams,
        attributesToFetch,
        query,
      });

      moreResult.entries.forEach((entity) => {
        // we're updating entities from baseResult
        entityIdToEntityMap.get(entity.id)?.attributes.push(...entity.attributes);
      });
    }),
  );

  return baseResult;
}

function enrichQueryWithRequiredShortIds(inputQuery: string | undefined, shortIds: string[]) {
  return SearchQueryModelConverter.convertSearchQueryToNql({
    operator: 'and',
    criteria: [
      SearchQueryModelConverter.convertNqlToSearchQuery(inputQuery),
      {
        criteria: shortIds.map((value) => ({
          attribute: KnownAttributes.Id,
          operator: '=',
          type: 'string',
          value,
        })),
        operator: 'or',
      },
    ],
  });
}

async function singleSearchLeaderboard({
  projectIdentifier,
  type,
  query,
  grouping,
  sorting: domainSorting,
  pagination,
  suggestions,
  attributesToFetch,
  experimentsOnly,
}: SearchLeaderboardRequest): Promise<Leaderboard> {
  const attributeFilters: { path: string }[] = attributesToFetch
    ? convertAttributeFilterFromDomainToApi(attributesToFetch)
    : [];
  const finalQuery = enhanceQueryWithExperimentsOnly(query, experimentsOnly);
  const sorting = domainSorting && convertSortingFromDomainToApi(domainSorting);

  const result = await leaderboardClient.searchLeaderboardEntries({
    projectIdentifier,
    type,
    params: {
      ...(finalQuery ? { query: { query: finalQuery } } : null),
      ...(grouping ? { grouping: convertLeaderboardGroupingFromDomainToApi(grouping) } : null),
      ...(pagination ? { pagination: convertPaginationFromDomainToApi(pagination) } : null),
      ...(sorting ? { sorting } : null),
      ...(suggestions ? { suggestions } : null),
      ...(attributesToFetch ? { attributeFilters } : null),
      truncateStringTo: 1000,
    },
  });
  return leaderboardFromApiToDomain(result, suggestions);
}

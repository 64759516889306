import { isArchivingEnabled } from '@neptune/shared/core-organizations-business-logic';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWorkspaceStatusAction } from '@neptune/pricing-workspace-status-api';
import LoadingIndicator from 'components/loading-indicator/LoadingIndicator';
import { ProjectView } from '@neptune/project-ui';
import { getDistractionFree } from '@neptune/shared/routing-business-logic';
import { CurrentProjectProvider } from 'components/providers/CurrentProjectProvider';
import { withProjectUsers } from 'common/hoc/withProjectUsers';
import {
  getCurrentProject,
  getCurrentProjectFetchState,
} from '@neptune/current-project-business-logic';
import { useNoAccess, useNotFound } from '@neptune/shared/common-feature';
import { getContextOrganization, getOrganizationNameFromRouteParams } from 'state/selectors-global';
import { useTrialExpiration } from '@neptune/project-business-logic';
import { TrialExpired } from 'components/trial-expired';
import { ProjectSideEffectsContainer } from './ProjectSideEffectsContainer';
import { ArchivedProjectContainer } from './ArchivedProjectContainer';

export const ProjectViewContainer: React.FC<{
  analytics: React.ReactNode;
  header: React.ReactNode;
  scene: React.ReactNode;
}> = withProjectUsers(({ analytics, header, scene }) => {
  const dispatch = useDispatch();

  const project = useSelector(getCurrentProject);
  const { fetchStatus, error } = useSelector(getCurrentProjectFetchState);

  const HttpErrorPage = useNotFound({ error, fetchStatus });
  const { trialExpired } = useTrialExpiration();
  useNoAccess({ error, fetchStatus });
  const distractionFree = useSelector(getDistractionFree);

  const organizationIdentifier = useSelector(getOrganizationNameFromRouteParams);

  React.useEffect(() => {
    dispatch(fetchWorkspaceStatusAction({ organizationIdentifier }));
  }, [dispatch, organizationIdentifier]);

  const workspace = useSelector(getContextOrganization);
  const archivingEnabled = workspace ? isArchivingEnabled(workspace.traits) : false;

  return (
    <CurrentProjectProvider>
      {() => {
        if (trialExpired) {
          return <TrialExpired />;
        }

        if (HttpErrorPage) {
          return <HttpErrorPage />;
        }

        if (!project) {
          return <LoadingIndicator centered isVisible />;
        }

        if (archivingEnabled && project?.archived) {
          return <ArchivedProjectContainer />;
        }

        return (
          <>
            <ProjectView
              header={distractionFree ? null : header}
              scene={scene}
              analytics={analytics}
            />
            <ProjectSideEffectsContainer />
          </>
        );
      }}
    </CurrentProjectProvider>
  );
});

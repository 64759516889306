import { storage } from 'common/storage';
import { tick } from '@neptune/shared/common-util';
import { AppRoute } from '@neptune/shared/routing-domain';
import { rejectActivation } from '@neptune/shared/routing-business-logic';
import {
  entityMatchesDisplayType,
  fetchEntityForInProjectDisplayRoute,
  getInProjectEntityDisplayRoute,
  typeOfProjectEntityDisplayRoute,
} from '@neptune/project-business-logic';
import { projectRunsRoutes } from '@neptune/project-runs-shell';
import {
  isEnterpriseRouteName,
  toEnterpriseRouteName,
} from '@neptune/shared/enterprise-context-util';
import {
  makeEnterpriseRoute,
  convertStateToContextAwareState,
} from '@neptune/shared/enterprise-context-business-logic';
import { modelRegistryRoutes } from '@neptune/model-registry-shell';
import { reportsRoutes } from '@neptune/reports-shell';
import projectDashboardRoutes from 'views/project-dashboard/routes';

const projectRoute: AppRoute = {
  name: 'project',
  path: '/:organizationName/:projectName',
  forwardTo: 'project.redirect-to-persisted-route',
  onActivate: (dispatch, getState, router) => (route, previousRoute) => {
    tick(async () => {
      if (previousRoute != null) {
        // we'd like to run resolving logic only when directly opened URL
        return;
      }

      const isEnterprise = isEnterpriseRouteName(route.name);

      const displayRouteType = typeOfProjectEntityDisplayRoute(route);

      if (!displayRouteType) {
        // This route doesn't display entity, no redirection.
        return;
      }

      const entity = await fetchEntityForInProjectDisplayRoute(route).catch(() => {
        // Entity not found or other fetch error.
      });

      if (!entity || entityMatchesDisplayType(entity, displayRouteType)) {
        // No entity found, or this is a right route for this entity.
        return;
      }

      // There is a mismatch between entity type/trash state and requested route.
      // Redirect to correct route for entity display.

      const baseDisplayRoute = getInProjectEntityDisplayRoute(entity);

      if (!baseDisplayRoute) {
        // No display route for this kind of entity (currenty only 'project', that shoudn't happen here anyway).
        return;
      }

      const displayRoute = {
        name: baseDisplayRoute.name,
        params: {
          // Keep all non-conficting params from requested route. This is useful for params like detailsTab,
          // so that the details view will be open in the same place.
          ...route.params,
          ...baseDisplayRoute.params,
        },
      };

      const awareDisplayRoute = convertStateToContextAwareState(displayRoute, isEnterprise);

      router.navigate(awareDisplayRoute.name, awareDisplayRoute.params, { replace: true });
    });
  },
};

const projectTabToRouteMapping: Record<string, string> = {
  runs: 'project.runs',
  models: 'project.model-registry',
  notebooks: 'project.notebooks',
  metadata: 'project.metadata',
  trash: 'project.trash',
};

const redirectToPersistedRouteRoute: AppRoute = {
  name: 'project.redirect-to-persisted-route',
  path: '/-/-',
  onActivate: (dispatch, getState, router) => (route) => {
    tick(() => {
      const tab = storage.local.getItem('lastProjectTab');
      const target = (tab && projectTabToRouteMapping[tab]) || projectTabToRouteMapping.runs;
      const targetEnterpriseAware = isEnterpriseRouteName(route.name)
        ? toEnterpriseRouteName(target)
        : target;
      router.navigate(targetEnterpriseAware, route.params, { replace: true });
    });
  },
};

/**
 * Route that can be used when we'd like to link to Entity without knowing its type or trash state
 * (for example in neptune-client or in app without full entity object)
 */
const virtualEntityRoute: AppRoute = {
  name: 'project.entity-with-shortid-only',
  path: '/-/entity',
  canActivate: () => async (toState) => {
    const isEnterpriseRoute = isEnterpriseRouteName(toState.name);

    const entity = await fetchEntityForInProjectDisplayRoute(toState).catch(() => undefined);

    const baseDisplayRoute = entity && getInProjectEntityDisplayRoute(entity);

    if (baseDisplayRoute) {
      const displayRoute = {
        name: baseDisplayRoute.name,
        params: {
          // Keep all (non-conficting) requested params. This is useful for keeping params like detailsTab.
          ...toState.params,
          ...baseDisplayRoute.params,
        },
      };
      return rejectActivation(convertStateToContextAwareState(displayRoute, isEnterpriseRoute));
    }

    // Redirect to project runs as a fallback.
    return rejectActivation(
      convertStateToContextAwareState(
        {
          name: 'project.runs-tab',
          params: {
            ...toState.params,
            tab: 'table',
          },
        },
        isEnterpriseRoute,
      ),
    );
  },
};

export const projectRoutes: AppRoute[] = [
  projectRoute,
  {
    ...makeEnterpriseRoute(projectRoute),
    path: '/o/:organizationName/org/:projectName',
  },
  virtualEntityRoute,
  makeEnterpriseRoute(virtualEntityRoute),
  redirectToPersistedRouteRoute,
  makeEnterpriseRoute(redirectToPersistedRouteRoute),
  ...projectRunsRoutes,
  ...projectDashboardRoutes,
  ...modelRegistryRoutes,
  ...reportsRoutes,
];

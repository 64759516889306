import { TextWrapping } from '@neptune/shared/common-domain';
import { AppState } from 'state/types';
// todo this dependency should be changed in the future to depend on currentProject explicitly, not through redux
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { getCurrentProjectIdentifier } from '@neptune/current-project-business-logic';

import { TextWrappingState } from '../storage-utils';

export const getTextWrappingState = (state: any): TextWrappingState => {
  return state.ui.textWrapping;
};

export const getTextWrapping = (state: AppState, id: string): TextWrapping => {
  const projectIdentifier = getCurrentProjectIdentifier(state);

  return getTextWrappingState(state)?.[projectIdentifier]?.[id] || TextWrapping.Clip;
};

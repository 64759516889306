import {
  Attribute,
  AttributeByType,
  AttributeDefinition,
  AttributeType,
  createFloatSeriesAttributeStub,
  DateTimeAttribute,
  ExperimentState,
  getAttribute,
  getAttributeValue,
  getIdFromAttributes,
  getNameFromAttributes,
  KnownAttributes,
  NotebookRefAttribute,
} from 'domain/experiment/attribute';
import type { Entity, RunIdentificationPair } from './entity-model';
import pathLib from 'path-browserify';
import { isInSystemNamespace } from 'domain/common/utils';
import { isEqual, uniqBy } from 'lodash';

export function getEntityAttribute<T extends AttributeType>(
  { attributes }: Pick<Entity, 'attributes'>,
  name: string,
  type: T,
): AttributeByType<T> | undefined {
  return getAttribute(attributes, name, type);
}

export function getEntityAttributes(
  entity: Pick<Entity, 'attributes'>,
  attributeDefinitions: AttributeDefinition[],
): Attribute[] {
  const matches = attributeDefinitions.map(({ name, type }) =>
    getEntityAttribute(entity, name, type),
  );

  return matches.filter((match): match is Attribute => match != null);
}

export function getEntityAttributeByPath(
  { attributes }: Pick<Entity, 'attributes'>,
  path: string,
): Attribute | undefined {
  return attributes.find(
    (attribute) => pathLib.normalize(attribute.attributeName) === pathLib.normalize(path),
  );
}

export function getEntityShortId(entity: Pick<Entity, 'attributes'>): string {
  return getEntityAttribute(entity, KnownAttributes.Id, 'string')?.value || '';
}

export function getEntityState(entity: Pick<Entity, 'attributes'>): ExperimentState {
  return getEntityAttribute(entity, KnownAttributes.State, 'experimentState')?.value || 'idle';
}

export function getEntityStage(entity: Pick<Entity, 'attributes'>): string | undefined {
  return getEntityAttribute(entity, KnownAttributes.Stage, 'string')?.value;
}

export function getEntityModelId(entity: Pick<Entity, 'attributes'>): string | undefined {
  return getEntityAttribute(entity, KnownAttributes.ModelId, 'string')?.value;
}

export function getEntityCreationTime(entity: Pick<Entity, 'attributes'>): Date {
  const attribute = getEntityAttribute(
    entity,
    KnownAttributes.CreationTime,
    'datetime',
  ) as DateTimeAttribute;
  return attribute.value;
}

export function getEntityModificationTime(entity: Pick<Entity, 'attributes'>): Date {
  const attribute = getEntityAttribute(
    entity,
    KnownAttributes.ModificationTime,
    'datetime',
  ) as DateTimeAttribute;
  return attribute.value;
}

export function getEntityTrashed(entity: Pick<Entity, 'attributes'>): boolean {
  return !!getEntityAttribute(entity, KnownAttributes.Trashed, 'bool')?.value;
}

export function getEntityFailed(entity: Pick<Entity, 'attributes'>): boolean {
  return !!getEntityAttribute(entity, KnownAttributes.Failed, 'bool')?.value;
}

export function getEntityName(entity: Pick<Entity, 'attributes'>): string {
  return getEntityAttribute(entity, KnownAttributes.Name, 'string')?.value || '';
}

export function getEntityRunningTime(entity: Pick<Entity, 'attributes'>): number {
  return getEntityAttribute(entity, KnownAttributes.RunningTime, 'float')?.value || 0;
}

export function getEntityOwner(entity: Pick<Entity, 'attributes'>): string {
  return getEntityAttribute(entity, KnownAttributes.Owner, 'string')?.value || '';
}

export function getEntityNotebook(
  entity: Pick<Entity, 'attributes'>,
): NotebookRefAttribute | undefined {
  return getEntityAttribute(entity, KnownAttributes.Notebook, 'notebookRef');
}

export function getEntitySize(entity: Pick<Entity, 'attributes'>): number | undefined {
  return getEntityAttribute(entity, KnownAttributes.Size, 'float')?.value;
}

export function getEntityMonitoringTime(entity: Pick<Entity, 'attributes'>): number | undefined {
  return getEntityAttribute(entity, KnownAttributes.MonitoringTime, 'int')?.value;
}

export function getEntityAttributesByNames(
  experiment: Pick<Entity, 'attributes'>,
  attributeNames: string[],
): Attribute[] {
  const matches = attributeNames.map((name) =>
    experiment.attributes.find((attribute) => attribute.attributeName === name),
  );

  return matches.filter((match): match is Attribute => match != null);
}

export function makeEntityAttributePath(
  entityId: string,
  entityType: string,
  attributeName: string,
) {
  return `${makeEntityIdentifier(entityId, entityType)}/${attributeName}`;
}

export function makeEntityIdentifier(entityId: string, entityType: string) {
  return `${entityType}-${entityId}`;
}

export function isTheSameEntityShortId(first: Entity, second: Entity) {
  return (
    first.organizationName === second.organizationName &&
    first.projectName === second.projectName &&
    getEntityShortId(first) === getEntityShortId(second)
  );
}

export function hasAttributes(entity?: Pick<Entity, 'attributes'>) {
  return entity?.attributes.length;
}

export function hasSystemAttributesOnly(entity: Pick<Entity, 'attributes'>) {
  return entity.attributes.every(({ attributeName }) => isInSystemNamespace(attributeName));
}

export function hasSystemAttributesDefinitionsOnly(attributes: AttributeDefinition[]) {
  return attributes.every(({ name }) => isInSystemNamespace(name));
}

export function extractAttributeValueByDefinition(
  entity: Pick<Entity, 'attributes'>,
  attributeDefinition: AttributeDefinition,
) {
  const { name, type, subproperty } = attributeDefinition;
  return getAttributeValue(getAttribute(entity.attributes, name, type), type, subproperty);
}

export function isEntityEquals(lhs: Entity, rhs: Entity) {
  if (rhs.attributes.length != lhs.attributes.length) {
    return false;
  }

  if (
    rhs.id !== lhs.id ||
    rhs.organizationId !== lhs.organizationId ||
    rhs.organizationName !== lhs.organizationName ||
    rhs.projectId !== lhs.projectId ||
    rhs.projectName !== lhs.projectName ||
    rhs.type !== lhs.type
  ) {
    return false;
  }

  const map = new Map<string, Attribute>();
  lhs.attributes.forEach((attribute) => map.set(attribute.attributeName, attribute));
  return rhs.attributes.every((attribute) => isEqual(attribute, map.get(attribute.attributeName)));
}

export function enhanceEntitiesWithSyntheticFloatSeriesAttributes<
  T extends Pick<Entity, 'attributes'> = Entity,
>(entities: T[], attributeNames: string[]): T[] {
  if (attributeNames.length === 0) {
    return entities;
  }

  const syntheticAttributes = attributeNames.map(createFloatSeriesAttributeStub);
  return entities.map((entity) => {
    return {
      ...entity,
      attributes: uniqBy([...entity.attributes, ...syntheticAttributes], 'attributeName'),
    };
  });
}

export function addMissingFloatSeriesAttributes<T extends Pick<Entity, 'attributes'> = Entity>(
  entities: T[],
  attributes: AttributeDefinition[],
) {
  const seriesAttributeNames = attributes
    .filter(({ type }) => type === 'floatSeries')
    .map((attribute) => attribute.name);

  return enhanceEntitiesWithSyntheticFloatSeriesAttributes(entities, seriesAttributeNames);
}

export function getRunIdentificationPair(entity: {
  attributes: Attribute[];
}): RunIdentificationPair | undefined {
  const runId = getIdFromAttributes(entity.attributes);

  if (runId === undefined) {
    return undefined;
  }

  return {
    runId,
    experimentName: getNameFromAttributes(entity.attributes),
  };
}

export function isRunIdentifierPair(entry: unknown): entry is RunIdentificationPair {
  return (
    entry !== null &&
    typeof entry === 'object' &&
    'runId' in entry &&
    'experimentName' in entry &&
    entry.runId !== undefined
  );
}

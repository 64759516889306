import React from 'react';
import { Layout, Text, bemBlock, Link } from '@neptune/shared/venus-ui';

import { SyntaxHighlighter } from '@neptune/shared/common-ui';
import { neptuneUrls } from '@neptune/shared/common-domain';
import './IngestUsageIntegrationExample.less';

const block = bemBlock('ingest-usage-integration-example');

type IntegrationContentProps = {
  className?: string;
  organizationName?: string;
  projectName?: string;
};

export const IngestUsageIntegrationExample: React.FC<IntegrationContentProps> = ({
  className,
  organizationName,
  projectName,
}) => {
  const projectNameCode = `${organizationName}/${projectName}`;

  return (
    <Layout.Column className={block({ extra: className })} withPadding="md" spacedChildren="md">
      <Text>To create a new run you will need:</Text>
      <Layout.Column spacedChildren="sm">
        <Text fontWeight="semibold">Project name</Text>
        <SyntaxHighlighter
          className={block('script')}
          language="python"
          data-heap-redact-text
          codeToCopy={projectNameCode}
          codeToHighlight={projectNameCode}
          wrapLines={false}
          theme="github"
          textId="project-identifier"
        />
      </Layout.Column>

      <Layout.Column spacedChildren="sm">
        <Text fontWeight="bold">
          Visit{' '}
          <Link href={neptuneUrls.onpremInternalDoc} fontWeight="bold" external>
            go/neptune-onboarding
          </Link>{' '}
          to get started.
        </Text>
      </Layout.Column>
    </Layout.Column>
  );
};

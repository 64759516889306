import React from 'react';

import { useAsyncValue, UseAsyncValueReturns } from '@neptune/shared/common-util';
import { createErrorDescriptor, ErrorDescriptor } from 'common/error-handler';
import { fetchMonitoringTimePackageList } from '@neptune/pricing-commons-domain';

type ThenArg<T> = T extends PromiseLike<infer U> ? U : T;

type FetchInput = Parameters<typeof fetchMonitoringTimePackageList>[0];
type FetchOutput = ThenArg<ReturnType<typeof fetchMonitoringTimePackageList>>;

type UseMonitoringPackagesHistoryParams = FetchInput;
type UseMonitoringPackagesHistoryValue = UseAsyncValueReturns<FetchOutput, ErrorDescriptor>;

export function useMonitoringPackagesHistory({
  organizationIdentifier,
  limit,
  offset,
  dateMin,
  dateMax,
}: UseMonitoringPackagesHistoryParams): UseMonitoringPackagesHistoryValue {
  const resolver = React.useCallback(
    () =>
      fetchMonitoringTimePackageList({
        organizationIdentifier,
        limit,
        offset,
        dateMin,
        dateMax,
      }),

    [organizationIdentifier, limit, offset, dateMin, dateMax],
  );

  return useAsyncValue({
    resolver,
    errorFormatter: createErrorDescriptor,
  });
}

import React from 'react';
import { useSelector } from 'react-redux';

import { getCurrentRouteName } from '@neptune/shared/routing-business-logic';
import { ProjectTabDropdown } from '@neptune/project-ui';
import {
  getOrganizationNameFromRouteParams,
  getProjectNameFromRouteParams,
} from 'state/selectors-global';
import { calculateSelectedTab } from '@neptune/project-business-logic';

export const ProjectTabDropdownContainer: React.FC = () => {
  const routeName = useSelector(getCurrentRouteName);
  const organizationName = useSelector(getOrganizationNameFromRouteParams);
  const projectName = useSelector(getProjectNameFromRouteParams);
  return (
    <ProjectTabDropdown
      selectedTab={calculateSelectedTab(routeName)}
      organizationName={organizationName}
      projectName={projectName}
    />
  );
};

// Libs
import { useSelector } from 'react-redux';
import { ReactElement } from 'react';

// App
import { getCurrentRouteName, getCurrentRouteParams } from 'state/selectors-global';

type RouteParams = any; // todo until whole state is not strongly typed there is no other way to type it

interface RouteProviderProps {
  children: (routeName: string, routeParams: RouteParams) => ReactElement;
}

const RouteProvider = ({ children }: RouteProviderProps) => {
  const routeName = useSelector(getCurrentRouteName);
  const routeParams = useSelector(getCurrentRouteParams);

  return children(routeName, routeParams);
};

export default RouteProvider;

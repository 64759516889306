import { useTagColors } from '@neptune/tags-business-logic';
import React from 'react';
import { Entity, EntityChanges, getEntityAttribute } from '@neptune/shared/entity-domain';
import { applyTrashedEntityPermission } from '@neptune/shared/entity-business-logic';
import { useProjectTagsDataSource } from '@neptune/shared/tags-business-logic';
import { KnownAttributes } from 'domain/experiment/attribute';
import { usePermissionEnhancedEntry } from '@neptune/shared/project-business-logic';
import { LayoutComponent } from '@neptune/entity-common-domain';

type EntityPropertiesContainerProps = {
  projectIdentifier: string;
  entity: Entity;
  LayoutComponent: LayoutComponent;
  onEntityChange: (entityUpdates: Partial<EntityChanges>) => Promise<void>;
};

export const EntityPropertiesContainer: React.FC<EntityPropertiesContainerProps> = ({
  projectIdentifier,
  entity,
  LayoutComponent,
  onEntityChange,
}) => {
  const entityWithRawPermissions = usePermissionEnhancedEntry(entity);
  const entityWithPermissions = React.useMemo(
    () =>
      entityWithRawPermissions ? applyTrashedEntityPermission(entityWithRawPermissions) : undefined,
    [entityWithRawPermissions],
  );

  const tagAttribute = getEntityAttribute(entity, KnownAttributes.Tags, 'stringSet');
  const projectTagsDataSource = useProjectTagsDataSource({
    types: ['run', 'model', 'modelVersion'],
    trash: false,
    projectIdentifier,
    disabledTags: tagAttribute?.values,
  });

  const groupTagAttribute = getEntityAttribute(entity, KnownAttributes.GroupTags, 'stringSet');
  const projectGroupTagsDataSource = useProjectTagsDataSource({
    types: ['run', 'model', 'modelVersion'],
    trash: false,
    projectIdentifier,
    disabledTags: groupTagAttribute?.values,
  });

  const { getColorForTag } = useTagColors();

  return entityWithPermissions ? (
    <LayoutComponent
      colorsGetter={getColorForTag}
      entity={entityWithPermissions}
      onEntityChange={onEntityChange}
      groupTagsDataSource={projectGroupTagsDataSource}
      tagsDataSource={projectTagsDataSource}
    />
  ) : null;
};

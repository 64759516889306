import {
  chain,
  isString,
  isArray,
  isUndefined,
  isNull,
} from 'lodash';
import { startsWithSegment } from 'router5-helpers';

import {
  toNormalizedRouteName,
} from './helpers';

/**
 * @deprecated use @neptune/shared/routing-business-logic
 */
export function parseRouteParams(routeParams = {}) {

  return (
    chain(routeParams)
      .mapValues(param => isArray(param) ? convertArrayToArrayString(param) : param)
      .omitBy(isUndefined)
      .omitBy(isNull)
      .value()
  );
}

/**
 * @deprecated use @neptune/shared/routing-business-logic serializers
 */
export function convertArrayToArrayString(arr) {
  if (!isArray(arr) || arr.length === 0) {
    return null;
  }
  return JSON.stringify(arr);
}

/**
 * @deprecated use @neptune/shared/routing-business-logic deserializers
 */
export function convertArrayStringToArray(string) {
  if (!isString(string)) {
    return string;
  }
  return JSON.parse(string);
}

/**
 * @deprecated use @neptune/shared/routing-business-logic deserializers
 */
export function getNormalizedMultipleURLParam(param = []) {
  const convertedParam = convertArrayStringToArray(param);
  let result = convertedParam;

  if (isString(convertedParam)) {
    result = convertedParam.length > 0 ? [convertedParam] : [];
  }

  return result.filter(param => isString(param) ? param.length > 0 : true);
}

export function isRunRoute(route) {
  const routeName = toNormalizedRouteName(route.name);
  const shortId = route.params?.shortId;
  return shortId && (startsWithSegment(routeName, 'project.runs-tab') || startsWithSegment(routeName, 'project.trash'));
}

import { useSelector } from 'react-redux';

import { getCurrentRouteName } from '@neptune/shared/routing-business-logic';

import { calculateSelectedTab } from './calculate-project-tab';

const projectSectionNameBySelectedTab = {
  runs: undefined,
  models: 'Models',
  metadata: 'Project metadata',
  trash: 'Trash',
  notebooks: 'Notebooks',
  reports: 'Reports',
};

export const useProjectSectionName = () => {
  const currentRounteName = useSelector(getCurrentRouteName);

  const selectedTab = calculateSelectedTab(currentRounteName);

  const sectionName = selectedTab ? projectSectionNameBySelectedTab[selectedTab] : undefined;

  return sectionName;
};

// Init error reporting {{{
import { initMonitoring } from 'monitoring';
initMonitoring();
// Init error reporting }}}

// Libs
// todo check if this is actually needed on Windows

import 'normalize.css';
import 'react-virtualized/styles.css';
import './styles/app.less';
import 'whatwg-fetch';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import React from 'react';
import { render } from 'react-dom';

import 'neptune-core-ui';
import '@neptune/shared/venus-ui';

// App
import { logger } from '@neptune/shared/core-logger-util';
import {
  authClient,
  getKeycloakInitOptions,
  isAuthServerReachable,
} from '@neptune/shared/core-auth-domain';
import { leaderboardClient } from '@neptune/shared/core-apis-leaderboard-domain';
import channelsDatasource from 'modules/channelsDatasource';

import { AppInitErrorPage } from '@neptune/shared/common-ui';
import { checkLicenseError } from 'common/error-handler';
import { neptune } from 'analytics/neptune';
import { renderSplashScreen } from 'initialization/helpers';
import { handleFeatureFlags } from 'common/featureFlag';
import { localStorageMigrate } from './local-storage';
import { localStorageTopKey } from 'common/localStorage';
import { LicenseErrorPageContainer } from '@neptune/shared/common-feature';
import { isBrowserAllowed } from '@neptune/shared/common-util';
import { BrowserNotAllowedInfo } from '@neptune/application-feature';

if (!isBrowserAllowed()) {
  render(<BrowserNotAllowedInfo />, document.getElementById('app'));
  throw new Error('Browser not allowed.');
}

handleFeatureFlags();
printBackendInstance();
renderSplashScreen();

authClient.onAuthRefreshError = async () => {
  // eslint-disable-next-line no-console
  console.warn('Cannot refresh access token.');

  const authServerAlive = await isAuthServerReachable();

  if (authServerAlive) {
    // if auth server is alive it most likely means we can't refresh token anymore and we should force user to login
    // eslint-disable-next-line no-console
    console.warn('authServerAlive = true, lets login');
    authClient.login();
  }
};

authClient.onAuthLogout = () => {
  // eslint-disable-next-line no-console
  console.warn('Session expired. Lets logout.');
  neptune.logout();
  authClient.login();
};

const migrateLocalstorageToCurrentVersion = async () => {
  await localStorageMigrate(localStorageTopKey);
};

migrateLocalstorageToCurrentVersion()
  .then(() => authClient.init(getKeycloakInitOptions()))
  .then(initialize)
  .then((/* authenticated */) => {
    channelsDatasource.injectApi(leaderboardClient);
  })
  .catch(showError);

async function initialize(authenticated) {
  const desktopInit = await import('initialization/desktop');
  await desktopInit.initializeDesktopApp(authenticated);
}

function showError(error) {
  if (checkLicenseError(error)) {
    render(<LicenseErrorPageContainer />, document.getElementById('app'));
  } else {
    render(<AppInitErrorPage />, document.getElementById('app'));
  }

  // eslint-disable-next-line no-console
  console.error('error while processing logic in index.js', error);
  logger.logException(error, {
    module: 'index.js',
  });
}

function printBackendInstance() {
  const neptuneInstance = process.env.NEPTUNE_INSTANCE;

  if (neptuneInstance) {
    // eslint-disable-next-line no-console
    console.log(`%cNeptune instance: ${neptuneInstance}`, 'color: #ff6600; font-size: 14px');
  }
}

import React from 'react';
import { noop } from 'lodash';

const DEFAULT_VALUE = 'FULL';

export type RunsLineageOptionsContextValue = ['NONE' | 'FULL', (value: 'NONE' | 'FULL') => void];

export const RunsLineageOptionsContext = React.createContext<RunsLineageOptionsContextValue>([
  DEFAULT_VALUE,
  noop,
]);

export const useRunsLineageOptions = (): RunsLineageOptionsContextValue => {
  const [value, setValue] = React.useContext(RunsLineageOptionsContext);

  return [value, setValue];
};

// App
import { KnownAttributes } from 'domain/experiment/attribute';
import {
  isNonEmptyStringSetCriterion,
  isSearchQuery,
  NonEmptyStringSetCriterion,
  SearchCriterion,
  SearchQuery,
  SearchQueryModelConverter,
  StringSetCriterion,
} from '@neptune/search-query-domain';
import { LeaderboardListGroupKey } from '@neptune/shared/leaderboard-domain';

export function createGroupQuery(
  input: string | undefined,
  groupKeys: LeaderboardListGroupKey[],
): string {
  const groupQuery = convertGroupKeysToSearchQuery(groupKeys);
  const inputQuery = input && SearchQueryModelConverter.convertNqlToSearchQuery(input);

  if (!inputQuery) {
    return SearchQueryModelConverter.convertSearchQueryToNql(groupQuery);
  }

  return SearchQueryModelConverter.convertSearchQueryToNql({
    criteria: [inputQuery, groupQuery],
    operator: 'and',
  });
}

export function createSelectionQuery(shortIds: string[]) {
  return shortIds.map((shortId) => `(${KnownAttributes.Id}:string = "${shortId}")`).join(' OR ');
}

export function getQueryUpdatedWithTag(
  { criteria, operator }: SearchQuery,
  attributeName: string,
  tag: string,
): SearchQuery {
  const existingTagCriteria: NonEmptyStringSetCriterion[] = [];
  let handleRemoval = false;

  criteria.forEach((criterion) => {
    if (
      !isSearchQuery(criterion) &&
      isNonEmptyStringSetCriterion(criterion) &&
      criterion.attribute === attributeName &&
      (criterion.operator === 'oneOf' || criterion.operator === 'allOf')
    ) {
      existingTagCriteria.push(criterion);

      if (criterion.value.includes(tag)) {
        handleRemoval = true;
      }
    }
  });

  // update existing tag criteria
  if (existingTagCriteria.length) {
    const newCriteria = [...criteria];

    if (handleRemoval) {
      // in case of removal we want to remove the tag from all the criteria
      for (const existingTagCriterion of existingTagCriteria) {
        const updatedCriterion = {
          ...existingTagCriterion,
          value: existingTagCriterion.value.filter((value) => value !== tag),
        };

        if (updatedCriterion.value.length) {
          newCriteria.splice(newCriteria.indexOf(existingTagCriterion), 1, updatedCriterion);
        } else {
          newCriteria.splice(newCriteria.indexOf(existingTagCriterion), 1);
        }
      }
      // add the tag to the first suitable criterion
    } else {
      const existingTagCriterion = existingTagCriteria[0];
      const updatedCriterion = {
        ...existingTagCriterion,
        value: [...existingTagCriterion.value, tag],
      };

      newCriteria.splice(newCriteria.indexOf(existingTagCriterion), 1, updatedCriterion);
    }

    return {
      criteria: newCriteria,
      operator,
    };
  }

  // create new tag criterion
  const tagCriterion: StringSetCriterion = {
    attribute: attributeName,
    type: 'stringSet',
    operator: 'oneOf',
    value: [tag],
  };

  return {
    criteria: [...criteria, tagCriterion],
    operator,
  };
}

function convertGroupKeyToSearchCriterion({
  field,
  value,
}: LeaderboardListGroupKey): SearchCriterion | undefined {
  const { name: attribute, aggregation: subproperty, type } = field;

  switch (type) {
    case 'artifact':
      return {
        attribute,
        operator: '=',
        type: 'artifact',
        value,
      };

    case 'bool':
      return {
        attribute,
        operator: '=',
        type: 'bool',
        value,
      };

    case 'experimentState':
      return {
        attribute,
        operator: '=',
        type: 'experimentState',
        value,
      };

    case 'float':
      return value != null
        ? {
            attribute,
            operator: '=',
            type: 'float',
            value,
          }
        : {
            attribute,
            operator: '!exists',
            type: 'float',
          };

    case 'floatSeries':
      return value != null
        ? {
            attribute,
            operator: '=',
            type: 'floatSeries',
            value,
            subproperty,
          }
        : {
            attribute,
            operator: '!exists',
            type: 'floatSeries',
          };

    case 'gitRef':
      return {
        attribute,
        operator: '=',
        type: 'gitRef',
        value,
      };

    case 'int':
      return value != null
        ? {
            attribute,
            operator: '=',
            type: 'int',
            value,
          }
        : {
            attribute,
            operator: '!exists',
            type: 'int',
          };

    case 'notebookRef':
      return {
        attribute,
        operator: '=',
        type: 'notebookRef',
        value,
      };

    case 'string':
      if (attribute === KnownAttributes.Owner) {
        return {
          attribute,
          operator: 'oneOf',
          type: 'owner',
          value: [value],
        };
      }

      return value != null
        ? {
            attribute,
            operator: '=',
            type: 'string',
            value,
          }
        : {
            attribute,
            operator: '!exists',
            type: 'string',
          };

    case 'stringSeries':
      return {
        attribute,
        operator: '=',
        type: 'stringSeries',
        value,
        subproperty,
      };
    case 'stringSet':
      if (value?.length == undefined || value?.length === 0) {
        return {
          attribute,
          operator: '!exists',
          type: 'stringSet',
        };
      }

      return {
        attribute,
        operator: 'allOf',
        type: 'stringSet',
        value,
      };
  }
}

function convertGroupKeysToSearchQuery(groupKeys: LeaderboardListGroupKey[]): SearchQuery {
  return {
    criteria: groupKeys
      .map(convertGroupKeyToSearchCriterion)
      .filter((item): item is SearchCriterion => !!item),
    operator: 'and',
  };
}

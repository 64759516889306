// Libs
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uniq } from 'lodash';

// App
import { useLbCount } from 'common/hooks/useLbCount';
import { getCurrentProject } from '@neptune/current-project-business-logic';
import { useEntityLeaderboardChangedNotifications } from '@neptune/shared/entity-leaderboard-business-logic';
import { notifyLeaderboardsChanged } from '@neptune/shared/leaderboard-business-logic';
import { EntityLeaderboardNotification } from '@neptune/shared/entity-leaderboard-domain';
import { fetchOrganizationLimits } from 'state/organization-limits/actions';
import { getOrganizationNameFromRouteParams } from 'state/selectors-global';
import { updateLastViewedProject } from '@neptune/shared/project-domain';

const entityTypesToLeaderboardIds: Record<string, string[] | undefined> = {
  model: ['models', 'trash'],
  run: ['runs', 'trash', 'reports'],
  modelVersion: ['modelVersions', 'trash'],
};

const entityTypesToListenTo = Object.keys(entityTypesToLeaderboardIds);

export const ProjectSideEffectsContainer: React.FC = () => {
  const dispatch = useDispatch();

  const project = useSelector(getCurrentProject);
  const organizationName = useSelector(getOrganizationNameFromRouteParams);
  const handleNotifyLeaderboardChanged = React.useCallback(
    (notifications: EntityLeaderboardNotification[]) => {
      const changedLeaderboardIds = uniq(
        notifications
          .flatMap(({ messageBody }) => messageBody.experimentTypes)
          .flatMap((entityType) => entityTypesToLeaderboardIds[entityType])
          .filter((candidate): candidate is string => candidate != null),
      );

      dispatch(notifyLeaderboardsChanged(changedLeaderboardIds));
    },
    [dispatch],
  );
  useEntityLeaderboardChangedNotifications({
    projectId: project?.id,
    entityTypes: entityTypesToListenTo,
    onNotify: handleNotifyLeaderboardChanged,
  });
  useLbCount();

  React.useEffect(() => {
    dispatch(fetchOrganizationLimits({ organizationIdentifier: organizationName }));
  }, [dispatch, organizationName]);

  React.useEffect(() => {
    if (!project) {
      return;
    }

    updateLastViewedProject(project.id);
  }, [project]);

  return null;
};

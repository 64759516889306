import { Widget, WidgetLayout, WidgetType } from 'domain/widget';
import { mapValues } from 'lodash';

export type WidgetMinimumDimensions = Required<Pick<WidgetLayout, 'minW' | 'minH'>>;
export type WidgetMinimumDimensionsTable = Partial<Record<WidgetType, WidgetMinimumDimensions>>;
export type WidgetDimensions = Required<Pick<WidgetLayout, 'w' | 'h'>>;
export type WidgetDefaultDimensionsTable = Partial<Record<WidgetType, WidgetDimensions>>;

export const defaultWidgetMinDimensions: WidgetMinimumDimensionsTable = {
  chart: {
    minW: 1,
    minH: 2,
  },
  file: {
    minW: 1,
    minH: 2,
  },
  fileSet: {
    minW: 2,
    minH: 4,
  },
  gallery: {
    minW: 2,
    minH: 3,
  },
  image: {
    minW: 1,
    minH: 2,
  },
  imageComparison: {
    minW: 2,
    minH: 3,
  },
  interactiveTable: {
    minW: 2,
    minH: 3,
  },
  notebook: {
    minW: 2,
    minH: 2,
  },
  scatterPlot: {
    minW: 1,
    minH: 2,
  },
  table: {
    minW: 2,
    minH: 2,
  },
  valueList: {
    minW: 1,
    minH: 2,
  },
};

const minDimensionsFallback = { minW: 1, minH: 1 };

export const defaultWidgetDimensions: WidgetDefaultDimensionsTable = {
  ...mapValues(defaultWidgetMinDimensions, (v) => v && { w: v.minW, h: v.minH }),
  chart: {
    w: 2,
    h: 3,
  },
  scatterPlot: {
    w: 2,
    h: 3,
  },
  imageComparison: {
    w: 2,
    h: 3,
  },
};

const defaultDimensionsFallback = { w: 1, h: 1 };

export const defaultWidgetHeights: Partial<Record<WidgetType, number>> = mapValues(
  defaultWidgetDimensions,
  'h',
);

export function getWidgetMinimumDimensions(
  widget?: Widget,
  widgetMinDimensions = defaultWidgetMinDimensions,
): WidgetMinimumDimensions {
  return (widget && widgetMinDimensions[widget.type]) || minDimensionsFallback;
}

export function getWidgetDefaultDimensions(
  widget?: Widget,
  defaultDimensions = defaultWidgetDimensions,
): WidgetDimensions {
  return (widget && defaultDimensions[widget.type]) || defaultDimensionsFallback;
}

export function getDefaultWidgetHeight(
  widget?: Pick<Widget, 'type'>,
  // Allow overriding for tests.
  defaultHeights: Partial<Record<WidgetType, number>> = defaultWidgetHeights,
): number {
  return (widget && defaultHeights[widget.type]) ?? 1;
}

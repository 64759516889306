import {
  WidgetAttributeDTO,
  WidgetAttributeDTOTypeEnum,
  WidgetDTO,
} from '@neptune/shared/core-apis-leaderboard-domain';
import { AttributeDefinition, AttributeType } from 'domain/experiment/attribute';
import {
  convertWidgetOptionsFromApiToDomain,
  convertWidgetOptionsFromDomainToApi,
} from './core/domain/widget-registry';
import { Layout } from 'react-grid-layout';
import { Widget, widgetTypeFromApiToDomain, widgetTypeFromDomainToApi } from './core/widget';
import { WidgetLayout } from 'domain/widget/dashboard-widget-layout';

export abstract class WidgetLayoutConverter {
  static fromDomainToGridLayout(
    { x, y, w, h, minW, minH, id }: WidgetLayout,
    { isStatic }: { isStatic: boolean },
  ): Layout {
    return {
      i: id,
      x,
      y,
      w,
      h,
      minW,
      minH,
      static: isStatic,
    };
  }

  static fromGridLayoutToDomain({ i, x, y, w, h, minW, minH }: Layout): WidgetLayout {
    return {
      id: i,
      x,
      y,
      w,
      h,
      minW,
      minH,
    };
  }
}

export abstract class WidgetModelConverter {
  static widgetFromApiToDomain(widget: WidgetDTO): Widget {
    const { experimentShortIds, ...restParams } = widget;
    return {
      ...restParams,
      entityShortIds: experimentShortIds,
      type: widgetTypeFromApiToDomain(widget.type),
      options: convertWidgetOptionsFromApiToDomain(widget.type, widget.options),
    };
  }

  static widgetFromDomainToApi(widget: Widget): WidgetDTO | undefined {
    const type = widgetTypeFromDomainToApi(widget.type);

    if (!type) {
      return undefined;
    }

    const { entityShortIds, ...restParams } = widget;

    const options = convertWidgetOptionsFromDomainToApi(widget.type, widget.options);

    return {
      ...restParams,
      experimentShortIds: entityShortIds,
      attributes: widget.attributes?.map(WidgetModelConverter.widgetAttributeFromDomainToApi),
      options,
      type,
    };
  }

  static widgetAttributeFromDomainToApi(
    attributeDefinition: AttributeDefinition,
  ): WidgetAttributeDTO {
    return {
      ...attributeDefinition,
      type: WidgetModelConverter.widgetAttributeTypeFromDomainToApi(attributeDefinition.type),
    };
  }

  static widgetAttributeTypeFromDomainToApi(
    attributeType: AttributeType,
  ): WidgetAttributeDTOTypeEnum {
    switch (attributeType) {
      case 'bool':
        return WidgetAttributeDTOTypeEnum.Bool;

      case 'datetime':
        return WidgetAttributeDTOTypeEnum.Datetime;

      case 'experimentState':
        return WidgetAttributeDTOTypeEnum.ExperimentState;

      case 'file':
        return WidgetAttributeDTOTypeEnum.File;

      case 'fileSet':
        return WidgetAttributeDTOTypeEnum.FileSet;

      case 'float':
        return WidgetAttributeDTOTypeEnum.Float;

      case 'floatSeries':
        return WidgetAttributeDTOTypeEnum.FloatSeries;

      case 'gitRef':
        return WidgetAttributeDTOTypeEnum.GitRef;

      case 'imageSeries':
        return WidgetAttributeDTOTypeEnum.ImageSeries;

      case 'int':
        return WidgetAttributeDTOTypeEnum.Int;

      case 'notebookRef':
        return WidgetAttributeDTOTypeEnum.NotebookRef;

      case 'string':
        return WidgetAttributeDTOTypeEnum.String;

      case 'stringSeries':
        return WidgetAttributeDTOTypeEnum.StringSeries;

      case 'stringSet':
        return WidgetAttributeDTOTypeEnum.StringSet;
    }

    return WidgetAttributeDTOTypeEnum.NotSupported;
  }
}

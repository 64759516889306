import {
  leaderboardClient,
  ListLeaderboardViewsRequest,
  DeleteLeaderboardViewRequest,
  LeaderboardViewSetDefaultDTO,
} from '@neptune/shared/core-apis-leaderboard-domain';
import { createAsyncActions } from 'state/async-actions';
import { navigateTo } from 'router/actions';
import { AppState, NThunkDispatch } from 'state/types';
import { AnyAction } from 'redux';
import { getCurrentRoute } from 'state/selectors-global';
import { setColumns } from '@neptune/shared/columns-business-logic';
import {
  LeaderboardViewConverter,
  LeaderboardView,
  LB_VIEW_CREATE_MODAL,
} from '@neptune/shared/lb-views-domain';
import { getCurrentProjectIdentifier } from '@neptune/current-project-business-logic';
import { getViewsPhase1Enabled } from 'common/featureFlag';
import { SortOptions, GroupOptions } from '@neptune/shared/leaderboard-domain';

// Module
import { isStandardView, standardView } from './standard-view';
import { areColumnsDirty, getActiveView, getDefaultView } from './selectors';
import { openConfirmationModal } from 'state/ui/global/confirmation-modal/actions';
import { ColumnsRootKey } from '@neptune/shared/columns-domain';

export enum LeaderboardViewsFetchActionTypes {
  request = 'LEADERBOARD_VIEWS_FETCH_REQUEST',
  success = 'LEADERBOARD_VIEWS_FETCH_SUCCESS',
  fail = 'LEADERBOARD_VIEWS_FETCH_FAIL',
}

type FetchLbViewsSuccessPayload = { views: LeaderboardView[]; projectIdentifier: string };
const fetchActions = createAsyncActions({
  types: LeaderboardViewsFetchActionTypes,
  async resolver({
    projectIdentifier,
  }: ListLeaderboardViewsRequest): Promise<FetchLbViewsSuccessPayload> {
    const { views } = await leaderboardClient.listLeaderboardViews({ projectIdentifier });

    return {
      projectIdentifier,
      views: views.map(LeaderboardViewConverter.fromApiToDomain),
    };
  },
});

export enum LeaderboardViewsCreateActionTypes {
  request = 'LEADERBOARD_VIEWS_CREATE_REQUEST',
  success = 'LEADERBOARD_VIEWS_CREATE_SUCCESS',
  fail = 'LEADERBOARD_VIEWS_CREATE_FAIL',
}

const createActions = createAsyncActions({
  types: LeaderboardViewsCreateActionTypes,
  async resolver({
    projectIdentifier,
    createView: createViewRaw,
  }: {
    projectIdentifier: string;
    createView: LeaderboardView;
  }) {
    const createView = LeaderboardViewConverter.fromDomainToApi(createViewRaw);
    const lbViewFromApi = await leaderboardClient.createLeaderboardView({
      projectIdentifier,
      createView,
    });
    return LeaderboardViewConverter.fromApiToDomain(lbViewFromApi);
  },
  onResolve(payload: LeaderboardView, dispatch) {
    if (getViewsPhase1Enabled()) {
      dispatch(discardCurrentChanges());
      dispatch(activateView(payload.id));
    } else {
      dispatch(activateViewAndDiscardChanges(payload.id));
    }
  },
});

export enum LeaderboardViewsUpdateActionTypes {
  request = 'LEADERBOARD_VIEWS_UPDATE_REQUEST',
  success = 'LEADERBOARD_VIEWS_UPDATE_SUCCESS',
  fail = 'LEADERBOARD_VIEWS_UPDATE_FAIL',
}

type UpdateLeaderboardViewRequest = {
  projectIdentifier: string;
  viewId: string;
  updateView: LeaderboardView;
};

const updateActions = createAsyncActions({
  types: LeaderboardViewsUpdateActionTypes,
  async resolver({
    projectIdentifier,
    viewId,
    updateView: updateViewRaw,
  }: UpdateLeaderboardViewRequest) {
    const updateView = LeaderboardViewConverter.fromDomainToApi(updateViewRaw);
    const lbViewFromApi = await leaderboardClient.updateLeaderboardView({
      projectIdentifier,
      viewId,
      updateView,
    });
    return LeaderboardViewConverter.fromApiToDomain(lbViewFromApi);
  },
});

export enum LeaderboardViewsDeleteActionTypes {
  request = 'LEADERBOARD_VIEWS_DELETE_REQUEST',
  success = 'LEADERBOARD_VIEWS_DELETE_SUCCESS',
  fail = 'LEADERBOARD_VIEWS_DELETE_FAIL',
}

const deleteActions = createAsyncActions({
  types: LeaderboardViewsDeleteActionTypes,
  async resolver(request: DeleteLeaderboardViewRequest) {
    await leaderboardClient.deleteLeaderboardView(request);
    return request;
  },
});

export function discardCurrentChanges() {
  return (dispatch: NThunkDispatch<AnyAction>, getState: () => AppState) => {
    const view = getActiveView(getState());

    if (view) {
      dispatch(activateViewAndDiscardChanges(view.id));
    }
  };
}

const noQueryRouteParams: {
  query: undefined;
  suggestionsEnabled: undefined;
  lbViewUnpacked: undefined;
} & {
  [key in keyof Required<GroupOptions>]: undefined;
} & {
  [key in keyof Required<SortOptions>]: undefined;
} = {
  query: undefined,
  suggestionsEnabled: undefined,
  lbViewUnpacked: undefined,
  sortBy: undefined,
  sortFieldType: undefined,
  sortFieldAggregationMode: undefined,
  sortDirection: undefined,
  groupBy: undefined,
  groupByFieldType: undefined,
  groupByFieldAggregationMode: undefined,
  openedGroups: undefined,
};

export function activateView(viewId: string, discardQueryParams = true) {
  return (dispatch: NThunkDispatch<AnyAction>, getState: () => AppState) => {
    const route = getCurrentRoute(getState());

    dispatch(
      navigateTo(route.name, {
        ...route.params,
        ...(discardQueryParams ? noQueryRouteParams : undefined),
        viewId,
      }),
    );
  };
}

export function activateViewAndDiscardChanges(viewId: string) {
  return (dispatch: NThunkDispatch<AnyAction>, getState: () => AppState) => {
    dispatch(activateView(viewId));

    const columnsDirty = areColumnsDirty(getState());

    if (columnsDirty) {
      const lbView = getActiveView(getState());
      const projectIdentifier = getCurrentProjectIdentifier(getState());

      if (lbView) {
        dispatch(
          setColumns({
            columns: lbView.columnList,
            override: true,
            columnsSetId: projectIdentifier,
            columnsRootKey: ColumnsRootKey.Runs,
          }),
        );
      }
    }
  };
}

export function redirectToDefaultView() {
  return (dispatch: NThunkDispatch<AnyAction>, getState: () => AppState) => {
    const defaultView = getDefaultView(getState());
    const viewIdToRedirect = (defaultView && defaultView.id) || standardView.id;

    dispatch(
      getViewsPhase1Enabled()
        ? activateView(viewIdToRedirect)
        : activateViewAndDiscardChanges(viewIdToRedirect),
    );
  };
}

export enum LeaderboardViewsSetDefaultActionTypes {
  request = 'LEADERBOARD_VIEWS_SET_DEFAULT_REQUEST',
  success = 'LEADERBOARD_VIEWS_SET_DEFAULT_SUCCESS',
  fail = 'LEADERBOARD_VIEWS_SET_DEFAULT_FAIL',
}

const setDefaultActions = createAsyncActions({
  types: LeaderboardViewsSetDefaultActionTypes,
  async resolver(request: Required<LeaderboardViewSetDefaultDTO>) {
    const viewId = isStandardView(request.viewId) ? undefined : request.viewId;

    await leaderboardClient.setDefaultView({ setDefaultOperation: { ...request, viewId } });
    return request;
  },
});

export function requestNewLbView() {
  return (dispatch: NThunkDispatch<AnyAction>) => {
    dispatch(openConfirmationModal(LB_VIEW_CREATE_MODAL));
  };
}

export const { execute: fetchLeaderboardViews } = fetchActions;
export const { execute: createLeaderboardView } = createActions;
export const { execute: updateLeaderboardView } = updateActions;
export const { execute: deleteLeaderboardView } = deleteActions;
export const { execute: setDefaultView } = setDefaultActions;

export type LeaderboardViewsActions = ReturnType<
  | typeof fetchActions.request
  | typeof fetchActions.success
  | typeof fetchActions.fail
  | typeof createActions.request
  | typeof createActions.success
  | typeof createActions.fail
  | typeof updateActions.request
  | typeof updateActions.success
  | typeof updateActions.fail
  | typeof deleteActions.request
  | typeof deleteActions.success
  | typeof deleteActions.fail
  | typeof setDefaultActions.request
  | typeof setDefaultActions.success
  | typeof setDefaultActions.fail
>;

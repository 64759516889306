import {
  AttributeFilterDTO,
  AttributePropertyFilterDTO,
  AttributeTypeDTO,
  leaderboardClient,
} from '@neptune/shared/core-apis-leaderboard-domain';
import {
  AttributeDefinition,
  AttributeDefinitionConverter,
  AttributeType,
} from 'domain/experiment/attribute';
import { isArray } from 'lodash';

export type QueryAttributeDefinitionsFilter = Partial<
  Record<AttributeType, { propertyName: string; propertyValues: string[] } | {}>
>;

export type QueryAttributeDefinitionsRequest = {
  filterAttributeTypes?: AttributeType[] | QueryAttributeDefinitionsFilter;
  limit?: number;
  nextPageToken?: string;
  projectIdentifier: string;
  search?: string;
  shortIds?: string[];
};

export type QueryAttributeDefinitionsResult = {
  attributeDefinitions: AttributeDefinition[];
  nextPageToken?: string;
};

export async function queryAttributeDefinitions({
  filterAttributeTypes,
  limit,
  nextPageToken,
  projectIdentifier,
  search,
  shortIds: experimentIdsFilter,
}: QueryAttributeDefinitionsRequest): Promise<QueryAttributeDefinitionsResult> {
  const attributeFilter = isArray(filterAttributeTypes)
    ? convertAttributeTypeArrayToApiAttributeFilter(filterAttributeTypes)
    : convertAttributeFilterFromDomainToApi(filterAttributeTypes);

  if (attributeFilter?.length === 0 && lengthOfFilterAttributeTypes(filterAttributeTypes) !== 0) {
    // If this happened, it means that we have desynchronized FE and BE applications.
    throw new Error(
      'Requested attribute type filter, but all requested types are not supported, which may cause filtering to be disabled',
    );
  }

  const nextPage =
    limit === undefined && nextPageToken === undefined ? undefined : { limit, nextPageToken };

  const result = await leaderboardClient.queryAttributeDefinitionsWithinProject({
    projectIdentifier,
    query: {
      attributeFilter,
      attributeNameRegex: search === '' ? undefined : search,
      experimentIdsFilter,
      nextPage,
    },
  });

  return {
    nextPageToken: result.nextPage.nextPageToken,
    attributeDefinitions: result.entries.map(
      AttributeDefinitionConverter.attributeDefinitionFromApiToDomain,
    ),
  };
}

type QueryAttributeDefinitionsOnlyWithDiffsRequest = Required<
  Pick<QueryAttributeDefinitionsRequest, 'shortIds'>
> &
  Omit<QueryAttributeDefinitionsRequest, 'shortIds'>;

export async function queryAttributeDefinitionsOnlyWithDiffs({
  filterAttributeTypes,
  limit,
  nextPageToken,
  projectIdentifier,
  search,
  shortIds: experimentIdsFilter,
}: QueryAttributeDefinitionsOnlyWithDiffsRequest): Promise<QueryAttributeDefinitionsResult> {
  const attributeFilter = isArray(filterAttributeTypes)
    ? convertAttributeTypeArrayToApiAttributeFilter(filterAttributeTypes)
    : convertAttributeFilterFromDomainToApi(filterAttributeTypes);

  try {
    assertThereIsNoMismatchBetweenApiAndApp(attributeFilter, filterAttributeTypes);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return {
      nextPageToken: undefined,
      attributeDefinitions: [],
    };
  }

  const nextPage =
    limit === undefined && nextPageToken === undefined ? undefined : { limit, nextPageToken };

  const result = await leaderboardClient.queryAttributeDefinitionsOnlyWithDiffs({
    projectIdentifier,
    query: {
      attributeFilter,
      attributeNameRegex: search === '' ? undefined : search,
      experimentIdsFilter,
      nextPage,
    },
  });

  return {
    nextPageToken: result.nextPage.nextPageToken,
    attributeDefinitions: result.entries.map(
      AttributeDefinitionConverter.attributeDefinitionFromApiToDomain,
    ),
  };
}

function convertAttributeFilterFromDomainToApi(
  input: QueryAttributeDefinitionsFilter | undefined,
): AttributeFilterDTO[] | undefined {
  if (!input) {
    return;
  }

  return Object.entries(input)
    .map(([domainAttributeType, params]): AttributeFilterDTO | undefined => {
      const attributeType = AttributeDefinitionConverter.attributeTypeToApi(domainAttributeType);

      if (!attributeType) {
        return;
      }

      const propertyFilter: AttributePropertyFilterDTO | undefined =
        params && 'propertyName' in params
          ? { propertyName: params.propertyName, propertyValues: [...params.propertyValues] }
          : undefined;

      return { attributeType, propertyFilter };
    })
    .filter((input: AttributeFilterDTO | undefined): input is AttributeFilterDTO => !!input);
}

function convertAttributeTypeArrayToApiAttributeFilter(
  input: AttributeType[],
): AttributeFilterDTO[] {
  return input
    .map(AttributeDefinitionConverter.attributeTypeToApi)
    .filter((attributeType): attributeType is AttributeTypeDTO => !!attributeType)
    .map((attributeType) => ({ attributeType }));
}

function lengthOfFilterAttributeTypes(
  input: AttributeType[] | QueryAttributeDefinitionsFilter | undefined,
): number {
  if (!input) {
    return 0;
  }

  if (isArray(input)) {
    return input.length;
  }

  return Object.keys(input).length;
}

function assertThereIsNoMismatchBetweenApiAndApp(
  attributeFilter?: AttributeFilterDTO[],
  filterAttributeTypes?: AttributeType[] | QueryAttributeDefinitionsFilter,
) {
  if (attributeFilter?.length === 0 && lengthOfFilterAttributeTypes(filterAttributeTypes) !== 0) {
    // If this happened, it means that we have desynchronized FE and BE applications.
    throw new Error(
      'Requested attribute type filter, but all requested types are not supported, which may cause filtering to be disabled',
    );
  }
}

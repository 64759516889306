// App
import { makeEntityIdentifier } from '@neptune/shared/entity-util';

// Module
import { Entity } from './entity-model';
import { fetchEntityChunk } from './fetch-entity-chunk';

export async function fetchEntityChunkByShortId(
  organizationName: string,
  projectName: string,
  shortId: string,
  fieldsToFetch: string[],
): Promise<Entity> {
  return fetchEntityChunk({
    id: makeEntityIdentifier(organizationName, projectName, shortId),
    holderType: 'experiment',
    fieldsToFetch,
  });
}

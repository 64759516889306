import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Entity,
  EntityDetailsModalData,
  EntityTypeToDetailsModalName,
  getEntityShortId,
  getEntityTrashed,
} from '@neptune/shared/entity-domain';
import { getCurrentProjectIdentifier } from '@neptune/current-project-business-logic';
import { TrashEntityModal } from '@neptune/shared/entity-ui';
import { DetailsDropdownMenu, DetailsDropdownMenuProps } from '@neptune/entity-common-ui';
import { useEntityControlActions } from '@neptune/entity-common-business-logic';
import { useTrashEntity } from '@neptune/shared/entity-business-logic';
import {
  getOrganizationNameFromRouteParams,
  getProjectNameFromRouteParams,
} from 'state/selectors-global';
import { WithPermissions } from '@neptune/shared/project-domain';
import { useGenericModal } from '@neptune/shared/common-business-logic';
import { assignColorToExperiment } from 'state/ui/compare/color-map/actions';
import { getColorMapState } from 'state/ui/compare/color-map/selectors';
import { HiddenColorInput } from '@neptune/shared/common-ui';
import { UnderEditContext } from '@neptune/shared/venus-domain';
import {
  getIdFromAttributes,
  getRunIdentificationKeyFromAttributes,
} from 'domain/experiment/attribute';

export type DetailsDropdownMenuContainerProps = {
  colorsStorageId: string;
  autoFollowFeatureEnabled?: boolean;
  entityWithPermissions: WithPermissions<Entity>;
} & Pick<DetailsDropdownMenuProps, 'constraints'>;

export const DetailsDropdownMenuContainer: React.FC<DetailsDropdownMenuContainerProps> = ({
  colorsStorageId,
  autoFollowFeatureEnabled,
  entityWithPermissions,
  constraints,
}) => {
  const organizationName = useSelector(getOrganizationNameFromRouteParams);
  const projectName = useSelector(getProjectNameFromRouteParams);
  const trashed = getEntityTrashed(entityWithPermissions);
  const { openWithData } = useGenericModal<EntityDetailsModalData>(
    EntityTypeToDetailsModalName[entityWithPermissions.type],
  );
  const dispatch = useDispatch();
  const colorMap = useSelector(getColorMapState);
  const projectIdentifier = useSelector(getCurrentProjectIdentifier);

  const shortId = getEntityShortId(entityWithPermissions);
  const runIdentificationKey = autoFollowFeatureEnabled
    ? getRunIdentificationKeyFromAttributes(entityWithPermissions.attributes)
    : getIdFromAttributes(entityWithPermissions.attributes);

  const { startEdit, stopEdit } = React.useContext(UnderEditContext);

  const handleColorChange = React.useCallback(
    (color: string) => {
      if (!runIdentificationKey) {
        return;
      }

      dispatch(
        assignColorToExperiment({
          colorKey: runIdentificationKey,
          id: colorsStorageId,
          projectIdentifier,
          color,
        }),
      );
    },
    [dispatch, colorsStorageId, projectIdentifier, runIdentificationKey],
  );

  const handleShowDetails = React.useCallback(() => {
    openWithData({ entityId: entityWithPermissions.id });
  }, [entityWithPermissions.id, openWithData]);

  const {
    cancelTrash,
    executeTrash,
    isOpen: isTrashModalOpen,
    requestTrash,
  } = useTrashEntity({
    organizationName,
    projectName,
    entity: entityWithPermissions,
  });

  const { onAbort, onRestore, onStop } = useEntityControlActions(entityWithPermissions);

  const canManageTags = entityWithPermissions.canModify && !trashed;
  const canRemove = entityWithPermissions.canDelete && !trashed;

  // TODO: remove once extended for other object types
  if (entityWithPermissions.type !== 'run') {
    return null;
  }

  return (
    <>
      <DetailsDropdownMenu
        colorInput={
          <HiddenColorInput
            defaultValue={runIdentificationKey ? colorMap[runIdentificationKey] : undefined}
            onChange={handleColorChange}
          />
        }
        entityType={entityWithPermissions.type}
        onShowDetails={handleShowDetails}
        onRestore={onRestore}
        onAbort={onAbort}
        onStop={onStop}
        onRemove={canRemove ? requestTrash : undefined}
        onManageTags={canManageTags ? handleShowDetails : undefined}
        onExpand={startEdit}
        onCollapse={stopEdit}
        constraints={constraints}
      />
      <TrashEntityModal
        isOpen={isTrashModalOpen}
        cancel={cancelTrash}
        execute={executeTrash}
        type={entityWithPermissions.type}
        shortId={shortId}
      />
    </>
  );
};

import React from 'react';

import { isEmpty, noop } from 'lodash';

import { extractGroupTagValue } from 'components/advanced-table/renderers/colors';
import { isNotUndefined } from 'common/tsHelpers';
import { useGroupBy, useGroups } from '@neptune/shared/leaderboard-business-logic';
import { KnownAttributes } from 'domain/experiment/attribute';
import { useMemoizedValue } from '@neptune/shared/common-util';

type GroupTagSettingsContextProps = {
  isRunsMatchTagsEnabled: boolean;
  enabledRunsMatchTags: Set<string>;
  toggleRunsMatchTag: (groupTag: string) => void;
  toggleAllRunsMatchTags: () => void;
  partiallyEnabledRunsMatchTags: boolean;
  allRunsMatchTagsEnabled: boolean;
};

const GroupTagSettingsContext = React.createContext<GroupTagSettingsContextProps>({
  isRunsMatchTagsEnabled: false,
  enabledRunsMatchTags: new Set(),
  toggleAllRunsMatchTags: noop,
  toggleRunsMatchTag: noop,
  partiallyEnabledRunsMatchTags: false,
  allRunsMatchTagsEnabled: false,
});

export const GroupTagSettingsProvider: React.FC = ({ children }) => {
  const { groupValues } = useGroupByGroupTags();

  const [enabledRunsMatchTags, setEnabledRunsMatchTags] = React.useState<Set<string>>(
    new Set<string>(),
  );

  const allRunsMatchTagsEnabled = React.useMemo(
    () => !isEmpty(groupValues) && groupValues.every((group) => enabledRunsMatchTags.has(group)),
    [enabledRunsMatchTags, groupValues],
  );

  const partiallyEnabledRunsMatchTags = React.useMemo(
    () => !allRunsMatchTagsEnabled && groupValues.some((group) => enabledRunsMatchTags.has(group)),
    [allRunsMatchTagsEnabled, groupValues, enabledRunsMatchTags],
  );

  const toggleAllRunsMatchTags = React.useCallback(() => {
    if (allRunsMatchTagsEnabled) {
      setEnabledRunsMatchTags(new Set());
    } else {
      setEnabledRunsMatchTags(new Set(groupValues));
    }
  }, [allRunsMatchTagsEnabled, groupValues]);

  const toggleRunsMatchTag = React.useCallback((groupTag: string) => {
    setEnabledRunsMatchTags((prev) => {
      const newValue = new Set(prev);

      if (newValue.has(groupTag)) {
        newValue.delete(groupTag);
      } else {
        newValue.add(groupTag);
      }

      return newValue;
    });
  }, []);

  const context = React.useMemo(
    () => ({
      enabledRunsMatchTags,
      toggleRunsMatchTag,
      toggleAllRunsMatchTags,
      allRunsMatchTagsEnabled,
      partiallyEnabledRunsMatchTags,
      isRunsMatchTagsEnabled: true,
    }),
    [
      enabledRunsMatchTags,
      toggleRunsMatchTag,
      toggleAllRunsMatchTags,
      allRunsMatchTagsEnabled,
      partiallyEnabledRunsMatchTags,
    ],
  );

  return (
    <GroupTagSettingsContext.Provider value={context}>{children}</GroupTagSettingsContext.Provider>
  );
};

export const useGroupTagSettings = () => {
  const {
    enabledRunsMatchTags,
    toggleRunsMatchTag,
    isRunsMatchTagsEnabled,
    toggleAllRunsMatchTags,
    allRunsMatchTagsEnabled,
    partiallyEnabledRunsMatchTags,
  } = React.useContext(GroupTagSettingsContext);

  return {
    isRunsMatchTagsEnabled,
    enabledRunsMatchTags,
    toggleRunsMatchTag,
    toggleAllRunsMatchTags,
    allRunsMatchTagsEnabled,
    partiallyEnabledRunsMatchTags,
  };
};

const STABLE_REF_EMPTY_ARRAY: [] = [];

function useGroupByGroupTags() {
  const { groups } = useGroups();
  const { options } = useGroupBy();

  const isGroupByGroupTags = React.useMemo<boolean>(() => {
    return !!options?.groupBy?.some((group) => group === KnownAttributes.GroupTags);
  }, [options]);

  const groupValues = useMemoizedValue(
    React.useMemo(
      () =>
        groups?.map(extractGroupTagValue).filter(isNotUndefined).sort() ?? STABLE_REF_EMPTY_ARRAY,
      [groups],
    ),
  );

  return {
    isGroupByGroupTags,
    groupValues,
  };
}

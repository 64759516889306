import type { AxisScale, XAxisMode } from '@neptune/charts-domain';
import { AttributeDefinition } from 'domain/experiment/attribute';
import { WidgetDefinition } from '../core/domain/widget-definition';
import { Widget, WidgetOptions, WidgetType } from '../core/widget';

export type ChartWidget = Widget & {
  type: 'chart';
  options: ChartWidgetOptions;
};
export type ChartWidgetOptions = {
  xAxisMetric?: AttributeDefinition;
  xAxisMode: XAxisMode;
  xAxisScale: AxisScale;
  yAxisScale: AxisScale;
  averaging?: boolean;
  yCustomExpression?: string;
};

export function isChartWidget(widget: Widget): widget is ChartWidget {
  return widget.type === 'chart';
}

export function chartWidgetOptionsFromDomainToApi({
  yAxisScale,
  ...rest
}: ChartWidgetOptions): object | undefined {
  return { yAxisMode: yAxisScale, ...rest };
}

export function chartWidgetOptionsFromApiToDomain(
  options?: Record<string, any>,
): ChartWidgetOptions {
  return {
    xAxisMetric: options?.xAxisMetric,
    xAxisMode: options?.xAxisMode,
    xAxisScale: options?.xAxisScale,
    yAxisScale: options?.yAxisMode,
    averaging: options?.averaging,
    yCustomExpression: options?.yCustomExpression,
  };
}

export const chartWidgetName = 'Chart';

export function isChartWidgetOptions(
  options: WidgetOptions,
  widgetType: WidgetType,
): options is ChartWidgetOptions {
  const optionKeys = Object.keys(options ?? {});
  const hasChartOptions = ['xAxisMode', 'xAxisScale', 'yAxisScale', 'yCustomExpression'].some(
    (option) => optionKeys.includes(option),
  );

  return widgetType === 'chart' && hasChartOptions;
}

export const chartWidgetDefinition: WidgetDefinition = {
  type: 'chart',
  name: chartWidgetName,
  fromApiToDomain: chartWidgetOptionsFromApiToDomain,
  fromDomainToApi: chartWidgetOptionsFromDomainToApi,
};

import {
  decompressFromEncodedURIComponent,
  compressToEncodedURIComponent,
  // @ts-ignore: the code is from 2015
} from 'lz-string/libs/lz-string';
import { ShortIdCompress } from 'common/short-id-compress';
import { memoize } from 'lodash';

const KEYS_DELIMITER = '--';
const EXPERIMENT_NAMES_DELIMITER = '|';

// TODO compressing names and ids on FE side is TMP thing,
// use key:value store service to save compare state NEP-79
export function persistCompareState(runIds: string[], experimentNames: string[]) {
  let compare;

  if (runIds.length) {
    compare = ShortIdCompress.stringify(runIds);
  }

  if (experimentNames.length) {
    compare = `${compare ?? ''}${KEYS_DELIMITER}${compressToEncodedURIComponent(experimentNames.join(EXPERIMENT_NAMES_DELIMITER))}`;
  }

  return compare;
}

export const extractCachedCompareState = memoize(
  (compressed: string, projectKey: string): [string[], string[]] => {
    const [runIds, experimentNames] = compressed.split(KEYS_DELIMITER);

    return [
      experimentNames
        ? decompressFromEncodedURIComponent(experimentNames).split(EXPERIMENT_NAMES_DELIMITER)
        : [],
      ShortIdCompress.parse(runIds, projectKey),
    ];
  },
  (...args) => args.join('#'),
);

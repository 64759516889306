import React from 'react';

import { bemBlock, Dropdown, Text } from '@neptune/shared/venus-ui';
import { NormalizedLink } from '@neptune/shared/routing-ui';
import { getReports } from 'common/featureFlag';

type ProjectTabDropdownProps = {
  projectName: string;
  selectedTab?: string;
  organizationName: string;
};

const block = bemBlock('project-name-dropdown');

const selectTabMap: Record<string, string> = {
  runs: 'Runs',
  models: 'Models',
  metadata: 'Project metadata',
  notebooks: 'Notebooks',
  reports: 'Reports',
  trash: 'Trash',
};

export const ProjectTabDropdown: React.FC<ProjectTabDropdownProps> = ({
  selectedTab,
  organizationName,
  projectName,
}) => {
  return (
    <Dropdown
      toggle={
        <Dropdown.Toggle
          variant="secondary-text"
          label={selectedTab ? <Text>{selectTabMap[selectedTab]}</Text> : null}
          data-role="project-subviews-dropdown"
        />
      }
    >
      {({ collapse }) => (
        <Dropdown.Menu
          className={block('menu')}
          onClick={collapse}
          data-role="project-subviews-dropdown-menu"
        >
          <Dropdown.Item
            component={NormalizedLink}
            icon="test-tube"
            label="Runs"
            selected={selectedTab === 'runs'}
            routeName="project.runs"
            routeParams={{ organizationName, projectName }}
            data-value="runs"
          />
          <Dropdown.Item
            component={NormalizedLink}
            icon="ai-model"
            label="Models"
            selected={selectedTab === 'models'}
            routeName="project.model-registry"
            routeParams={{ organizationName, projectName }}
            data-value="models"
          />
          <Dropdown.Item
            component={NormalizedLink}
            icon="project"
            label="Project metadata"
            selected={selectedTab === 'metadata'}
            routeName="project.metadata"
            routeParams={{ organizationName, projectName }}
            data-value="metadata"
          />
          {getReports() && (
            <Dropdown.Item
              component={NormalizedLink}
              icon="report"
              label="Reports"
              selected={selectedTab === 'reports'}
              routeName="project.reports"
              routeParams={{ organizationName, projectName }}
              data-value="reports"
            />
          )}
          <Dropdown.Item
            component={NormalizedLink}
            icon="notebook"
            label="Notebooks"
            selected={selectedTab === 'notebooks'}
            routeName="project.notebooks"
            routeParams={{ organizationName, projectName }}
            data-value="notebooks"
          />
          <Dropdown.Item
            component={NormalizedLink}
            icon="trash"
            label="Trash"
            selected={selectedTab === 'trash'}
            routeName="project.trash"
            routeParams={{ organizationName, projectName }}
            data-value="trash"
          />
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

import { Reorderable } from './types';
import {
  BaseColumn,
  ColumnIdentifier,
  ColumnType,
  FloatColumn,
  FloatSeriesColumn,
  LeaderboardColumn,
  LeaderboardColumnCreationParams,
} from './column-model';
import { getBuiltinColumnSettings } from './built-in-columns-settings';

export function createColumnByColumnIdentifier(
  columnIdentifier: ColumnIdentifier,
  pinned = false,
): LeaderboardColumn {
  const builtinColumnSettings = getBuiltinColumnSettings(columnIdentifier);

  if (builtinColumnSettings != null) {
    return leaderboardColumnFactory(builtinColumnSettings);
  }

  return leaderboardColumnFactory({
    ...columnIdentifier,
    pinned,
  });
}

export function leaderboardColumnFactory(
  params: LeaderboardColumnCreationParams,
): LeaderboardColumn {
  switch (params.columnType) {
    case 'float':
      return floatColumnFactory(params);

    case 'floatSeries':
      return floatSeriesColumnFactory(params);

    default:
      return baseLeaderboardColumnFactory(params);
  }
}

function floatColumnFactory(params: LeaderboardColumnCreationParams): FloatColumn {
  const baseColumn = baseLeaderboardColumnFactory(params);

  return {
    ...baseColumn,
    columnType: 'float',
    displayMode: params.displayMode || 'decimal',
  };
}

function floatSeriesColumnFactory(params: LeaderboardColumnCreationParams): FloatSeriesColumn {
  const baseColumn = baseLeaderboardColumnFactory(params);

  return {
    ...baseColumn,
    columnType: 'floatSeries',
    displayMode: params.displayMode || 'decimal',
    aggregationMode: params.aggregationMode || 'last',
  };
}

function baseLeaderboardColumnFactory({
  id,
  columnType,
  attributeName,
  displayName,
  color,
  ...rest
}: LeaderboardColumnCreationParams): BaseColumn {
  const { pinned = false, width = 100 } = rest;

  const builtInColumnSettings = getBuiltinColumnSettings({ id, columnType });

  const groupable = calculateGroupable(columnType, builtInColumnSettings?.groupable);
  const hideable = calculateHideable(columnType, builtInColumnSettings?.hideable);
  const resizable = calculateResizable(builtInColumnSettings?.resizable);
  const sortable = calculateSortable(columnType, builtInColumnSettings?.sortable);
  const reorderable = calculateReorderable(columnType, builtInColumnSettings?.reorderable);
  const exportable = calculateExportable(columnType, builtInColumnSettings?.exportable);

  return {
    id,
    columnType,
    attributeName,
    color,
    displayName,
    exportable,
    groupable,
    hideable,
    pinned,
    reorderable,
    resizable,
    sortable,
    width,
  };
}

const groupableColumnTypes: ColumnType[] = [
  'artifact',
  'bool',
  'experimentState',
  'float',
  'floatSeries',
  'gitRef',
  'int',
  'notebookRef',
  'string',
  'stringSeries',
  'stringSet',
];

function calculateGroupable(columnType: ColumnType, initial?: boolean): boolean {
  if (initial != null) {
    return initial;
  }

  return groupableColumnTypes.includes(columnType);
}

function calculateHideable(columnType: ColumnType, initial?: boolean): boolean {
  return initial != null ? initial : true;
}

function calculateSortable(columnType: ColumnType, initial?: boolean): boolean {
  return initial != null
    ? initial
    : [
        'artifact',
        'bool',
        'datetime',
        'experimentState',
        'float',
        'floatSeries',
        'int',
        'string',
        'stringSeries',
      ].includes(columnType);
}

function calculateReorderable(columnType: ColumnType, initial?: Reorderable): Reorderable {
  return initial != null ? initial : true;
}

function calculateResizable(initial?: boolean): boolean {
  return initial != null ? initial : true;
}

function calculateExportable(columnType: ColumnType, initial?: boolean): boolean {
  return initial != null ? initial : columnType !== 'notSupported';
}

import React from 'react';
import { Layout, LayoutRowProps, bemBlock } from '@neptune/shared/venus-ui';

import { Tag } from './Tag';

import './TagsList.less';

const block = bemBlock('tags-list');

type TagsListProps = {
  tagsList: string[];
  colorsGetter: (tag: string) => { color: string; backgroundColor: string };
  isEditable: boolean;
  onTagAdd: (tag: string) => void;
  onTagRemove: (tag: string) => void;
  withColor?: boolean;
} & LayoutRowProps<object>;

export const TagsList: React.FC<TagsListProps> = ({
  tagsList,
  colorsGetter,
  isEditable,
  onTagAdd,
  onTagRemove,
  withColor,
  ...restProps
}) => {
  return (
    <Layout.Row span="auto" className={block()} {...restProps}>
      {tagsList.map((tag) => (
        <Tag
          key={tag}
          tag={tag}
          className={block('item')}
          colors={withColor ? colorsGetter(tag) : undefined}
          onRemove={isEditable ? onTagRemove : undefined}
        />
      ))}
    </Layout.Row>
  );
};

import { createRouter, Route } from 'router5';
import { Router } from 'router5/types/types/router';
import browserPlugin from 'router5-plugin-browser';
import listenersPlugin from 'router5-plugin-listeners';

import routeContextRedirectMiddleware from './redux-middlewares/routeContextRedirectMW';
import routeContextPersistMiddleware from './redux-middlewares/routeContextPersistMW';
import { RouterConfig } from './types';

export { routeContextRedirectMiddleware, routeContextPersistMiddleware };

export function getRouter(routes: Route[], config: RouterConfig): Router {
  const { useHash, middlewares, ...createRouterParams } = config;

  const router = createRouter(routes, createRouterParams);

  router.usePlugin(
    browserPlugin({
      useHash,
    }),
  );
  router.usePlugin(listenersPlugin());
  middlewares?.forEach((middleware) => router.useMiddleware(middleware));
  router.setDependency('routes', routes);

  return router;
}

import config from 'config';
import {
  defaultVisibleColumnsSettings,
  STANDARD_SORTING_OPTIONS,
} from '@neptune/shared/leaderboard-domain';
import { LeaderboardView } from '@neptune/shared/lb-views-domain';

export const standardView: LeaderboardView = {
  id: 'standard-view',
  name: 'All runs',
  columnList: defaultVisibleColumnsSettings,
  defaultView: false,
  sortOptions: STANDARD_SORTING_OPTIONS,
  suggestionsEnabled: config.suggestionsEnabled,
  experimentsOnly: true,
  runsLineage: 'FULL',
};

export function isStandardView(lbViewId: string) {
  return lbViewId === standardView.id;
}

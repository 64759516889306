import { WidgetDTOTypeEnum } from '@neptune/shared/core-apis-leaderboard-domain';
import { invertMapTyped } from 'common/tsHelpers';
import { AttributeDefinition } from 'domain/experiment/attribute';

type WidgetId = string;
export type Widget = {
  attributes?: AttributeDefinition[];
  id: WidgetId;
  name?: string;
  namespaces?: string[];
  type: WidgetType;
  options?: WidgetOptions;
  entityShortIds?: string[];
};

export type AttributeBasedWidget = Omit<Widget, 'attributes' | 'namespaces'> &
  Required<Pick<Widget, 'attributes'>>;
export type NamespaceBasedWidget = Omit<Widget, 'attributes' | 'namespaces'> &
  Required<Pick<Widget, 'namespaces'>>;

export type WidgetType =
  | 'artifact'
  | 'artifactDiff'
  | 'chart'
  | 'valueList'
  | 'file'
  | 'fileSet'
  | 'gallery'
  | 'image'
  | 'imageComparison'
  | 'interactiveTable'
  | 'notebook'
  | 'scatterPlot'
  | 'singleValue'
  | 'table'
  | 'notSupported';
export type WidgetOptions = object | undefined;
const widgetTypeMapping: Partial<Record<WidgetType, WidgetDTOTypeEnum>> = {
  chart: WidgetDTOTypeEnum.Chart,
  file: WidgetDTOTypeEnum.File,
  fileSet: WidgetDTOTypeEnum.FileSet,
  gallery: WidgetDTOTypeEnum.Gallery,
  image: WidgetDTOTypeEnum.Image,
  imageComparison: WidgetDTOTypeEnum.ImageComparison,
  interactiveTable: WidgetDTOTypeEnum.InteractiveTable,
  notebook: WidgetDTOTypeEnum.Notebook,
  valueList: WidgetDTOTypeEnum.ValueList,
  scatterPlot: WidgetDTOTypeEnum.ScatterPlot,
  singleValue: WidgetDTOTypeEnum.SingleValue,
  table: WidgetDTOTypeEnum.Table,
};
const invertedWidgetTypeMapping = invertMapTyped(widgetTypeMapping);

export function widgetTypeFromApiToDomain(widgetType: WidgetDTOTypeEnum): WidgetType {
  return invertedWidgetTypeMapping[widgetType] || 'notSupported';
}

export function widgetTypeFromDomainToApi(widgetType: WidgetType): WidgetDTOTypeEnum | undefined {
  return widgetTypeMapping[widgetType];
}

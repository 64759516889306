// Libs
import type { GlyphName } from '@neptune/shared/core-glyphs-domain';

// App
import {
  DashboardDTO,
  DashboardLayoutsDTO,
  ListDashboardsDashboardTypeEnum,
  NewDashboardDTO,
  NewDashboardDTOTypeEnum,
  NewVersionDashboardDTO,
  NewVersionDashboardDTOTypeEnum,
  UpdateDashboardDTO,
  UpdateDashboardDTOTypeEnum,
  WidgetDTO,
} from '@neptune/shared/core-apis-leaderboard-domain';
import {
  Dashboard,
  WidgetLayouts,
  DashboardType,
  NewDashboard,
  NewVersionDashboard,
} from 'domain/dashboard/dashboard-model';
import { dashboardIcons } from 'domain/dashboard/dashboard-icons';
import { Widget, WidgetModelConverter } from 'domain/widget';
import { TableViewParamsConverter } from '@neptune/shared/leaderboard-domain';

const newDashboardTypeMapping: Record<DashboardType, NewDashboardDTOTypeEnum> = {
  compare: NewDashboardDTOTypeEnum.Compare,
  experiment: NewDashboardDTOTypeEnum.Experiment,
  report: NewDashboardDTOTypeEnum.Report,
};

const updateDashboardTypeMapping: Record<DashboardType, UpdateDashboardDTOTypeEnum> = {
  compare: UpdateDashboardDTOTypeEnum.Compare,
  experiment: UpdateDashboardDTOTypeEnum.Experiment,
  report: UpdateDashboardDTOTypeEnum.Report,
};

const newVersionTypeMapping: Record<DashboardType, NewVersionDashboardDTOTypeEnum> = {
  compare: NewVersionDashboardDTOTypeEnum.Compare,
  experiment: NewVersionDashboardDTOTypeEnum.Experiment,
  report: NewVersionDashboardDTOTypeEnum.Report,
};

const listDashboardTypeMapping: Record<DashboardType, ListDashboardsDashboardTypeEnum> = {
  compare: ListDashboardsDashboardTypeEnum.Compare,
  experiment: ListDashboardsDashboardTypeEnum.Experiment,
  report: ListDashboardsDashboardTypeEnum.Report,
};

export abstract class DashboardModelConverter {
  static dashboardFromApiToDomain(dashboard: DashboardDTO): Dashboard {
    return {
      ...dashboard,
      gridLayouts: DashboardModelConverter.dashboardLayoutsFromApiToDomain(dashboard.layouts),
      versionBranchId: dashboard.versionBranch,
      icon: DashboardModelConverter.dashboardIconFromApiToDomain(dashboard.icon),
      widgets: dashboard.widgets.map(WidgetModelConverter.widgetFromApiToDomain),
      viewParams: dashboard.viewParams
        ? TableViewParamsConverter.fromApiToDomain(dashboard.viewParams)
        : undefined,
    };
  }

  static dashboardIconFromApiToDomain(icon: string | undefined): GlyphName | undefined {
    if (!dashboardIcons.includes(icon as GlyphName)) {
      return undefined;
    }

    return icon as GlyphName;
  }

  static dashboardLayoutsFromApiToDomain(layouts: DashboardLayoutsDTO): WidgetLayouts {
    return layouts.md.map(({ isStatic, ...rest }) => rest);
  }

  static dashboardLayoutsFromDomainToApi(layouts: WidgetLayouts): DashboardLayoutsDTO {
    return {
      xs: [], // required for legacy reasons
      md: layouts,
      lg: [], // required for legacy reasons
      sm: [], // required for legacy reasons
      xl: [], // required for legacy reasons
    };
  }

  static dashboardWidgetsFromDomainToApi(widgets: Widget[]): WidgetDTO[] {
    return widgets
      .map(WidgetModelConverter.widgetFromDomainToApi)
      .filter((widget): widget is WidgetDTO => widget != null);
  }

  static newDashboardFromDomainToApi(dashboard: NewDashboard): NewDashboardDTO {
    return {
      ...dashboard,
      layouts: DashboardModelConverter.dashboardLayoutsFromDomainToApi(dashboard.gridLayouts),
      type: DashboardModelConverter.newDashboardTypeFromDomainToApi(dashboard.type),
      widgets: DashboardModelConverter.dashboardWidgetsFromDomainToApi(dashboard.widgets),
      viewParams: dashboard.viewParams
        ? TableViewParamsConverter.fromDomainToApi(dashboard.viewParams)
        : undefined,
    };
  }

  static newDashboardTypeFromDomainToApi(type: DashboardType): NewDashboardDTOTypeEnum {
    return newDashboardTypeMapping[type];
  }

  static updateDashboardTypeFromDomainToApi(type: DashboardType): UpdateDashboardDTOTypeEnum {
    return updateDashboardTypeMapping[type];
  }

  static updateDashboardFromApiToDomain(dashboard: Dashboard): UpdateDashboardDTO {
    return {
      ...dashboard,
      layouts: DashboardModelConverter.dashboardLayoutsFromDomainToApi(dashboard.gridLayouts),
      type: DashboardModelConverter.updateDashboardTypeFromDomainToApi(dashboard.type),
      widgets: DashboardModelConverter.dashboardWidgetsFromDomainToApi(dashboard.widgets),
      viewParams: dashboard.viewParams
        ? TableViewParamsConverter.fromDomainToApi(dashboard.viewParams)
        : undefined,
    };
  }

  static newVersionDashboardFromDomainToApi(
    dashboard: NewVersionDashboard,
  ): NewVersionDashboardDTO {
    return {
      ...dashboard,
      layouts: DashboardModelConverter.dashboardLayoutsFromDomainToApi(dashboard.gridLayouts),
      versionBranch: dashboard.versionBranchId,
      type: DashboardModelConverter.newVersionTypeFromDomainToApi(dashboard.type),
      widgets: DashboardModelConverter.dashboardWidgetsFromDomainToApi(dashboard.widgets),
      viewParams: dashboard.viewParams
        ? TableViewParamsConverter.fromDomainToApi(dashboard.viewParams)
        : undefined,
    };
  }
  static newVersionTypeFromDomainToApi(type: DashboardType): NewVersionDashboardDTOTypeEnum {
    return newVersionTypeMapping[type];
  }

  static listDashboardTypeFromDomainToApi(types: DashboardType[]): ListDashboardsDashboardTypeEnum {
    // casting is a workaround for Enum[] generated as Enum in our TS client (bug in swagger generation)
    return types.map(
      (type) => listDashboardTypeMapping[type],
    ) as any as ListDashboardsDashboardTypeEnum;
  }
}
